/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';

@Component({
    selector: 'app-card-provider-terms-conditions',
    templateUrl: './card-provider-terms-conditions.component.html',
    styleUrl: './card-provider-terms-conditions.component.scss'
})
export class CardProviderTermsConditionsComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}

