<div class="app-my-referral-rewards container-fluid" [ngClass]="httpService.theme">
    <div class="app-my-referral-rewards-container">
        <div *ngIf="stats?.all?.amount_usd > 0" class="claim-button">
            <cf-button label="Claim Rewards" type="solid" (clicked)="claimRewards()"></cf-button>
        </div>

        <div class="row stats 123" *ngIf="!loading_stats">
            <div>
                <cf-card *ngIf="stats?.all?.amount_usd >= 0" [stat]="'$' + (stats?.all?.amount_usd | number:'0.0-0')"
                    [subtitle]="
                        httpService.getUsdFormatting(
                            stats?.all?.count
                        ) + ' rewards'
                    " heading="All rewards" type="compact"></cf-card>
            </div>
            <div>
                <cf-card *ngIf="stats?.days_1?.amount_usd >= 0"
                    [stat]="'$' + (stats?.days_1?.amount_usd | number:'0.0-0')" [subtitle]="
                        httpService.getUsdFormatting(
                            stats?.days_1?.count
                        ) + ' rewards'
                    " heading="Last 24 hours" type="compact"></cf-card>
            </div>
            <div>
                <cf-card *ngIf="stats?.days_7?.amount_usd >= 0"
                    [stat]="'$' + (stats?.days_7?.amount_usd | number:'0.0-0')" [subtitle]="
                        httpService.getUsdFormatting(
                            stats?.days_7?.count
                        ) + ' rewards'
                    " heading="Last 7 days" type="compact"></cf-card>
            </div>
            <div>
                <cf-card *ngIf="stats?.days_30?.amount_usd >= 0"
                    [stat]="'$' + (stats?.days_30?.amount_usd | number:'0.0-0')" [subtitle]="
                        httpService.getUsdFormatting(
                            stats?.days_30?.count
                        ) + ' rewards'
                    " heading="Last 30 days" type="compact"></cf-card>
            </div>
        </div>

        <div *ngIf="!loading && httpService.user.user_id > 0" class="my-referral-rewards">
            <mat-expansion-panel (afterExpand)="afterExpand(item.reward_id)"
                [expanded]="opened_panel === item.reward_id"
                *ngFor="let item of rewards; let index = index; first as isFirst">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="panel-title">
                            {{ item.created | date: 'mediumDate' }}
                        </span>
                        <span>
                            {{
                            item.amount
                            | number
                            : httpService.getPrecision(
                            item.currency
                            )
                            }}
                            {{ item.currency | uppercase }}
                            <span class="equivalent-amount">
                                ~
                                {{
                                item.amount_usd
                                | number: httpService.getPrecision('usd')
                                }}
                                USD
                            </span>
                        </span>
                        <span>
                            {{ item.pool_name }}
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table>
                    <tbody>
                        <tr>
                            <td class="txn-heading">Date</td>
                            <td>
                                {{ item.created | date: 'medium' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Amount</td>
                            <td>
                                {{ item.amount | number: httpService.getPrecision(item.currency) }}
                                {{ item.currency | uppercase }}
                                <span class="equivalent-amount">
                                    ~ {{ item.amount_usd | number: httpService.getPrecision('usd') }} USD
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Child Wallet Address</td>
                            <td>
                                {{ item.child_wallet_address }}
                            </td>
                        </tr>
                        <tr *ngIf="item.pool_name">
                            <td class="txn-heading">Pool</td>
                            <td>
                                {{ item.pool_name }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Reward Id</td>
                            <td>
                                {{ item.reward_id }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Node Id</td>
                            <td>
                                {{ item.node_id }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
                (changed)="pageChanged($event)"></cf-paginator>
        </div>
        <div class="no-data" *ngIf="total_count === 0 && !loading && httpService.user.user_id > 0">
            No records found
        </div>
        <div class="no-data" *ngIf="total_count === 0 && !loading && httpService.user.user_id === 0">
            <cf-button label="Connect Wallet" type="outline" (clicked)="connectWallet()"></cf-button>
        </div>
        <div class="loader" *ngIf="loading">
            <cf-loader></cf-loader>
        </div>

        <!-- <div class="modal-backdrop" *ngIf="showModal" (click)="closeModal()"></div>
        <div class="modal-container" *ngIf="showModal">
            <div class="referral-container"> -->
        <!-- <app-referral-reward-withdrawal></app-referral-reward-withdrawal> -->
        <!-- </div>
        </div>
    </div> -->
    </div>