/* eslint-disable array-bracket-spacing */
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { HttpService } from '../../../services/http.service';
import { AmbassadorService } from '../../ambassador.service';
import ILineConfig from '../../charts/line-chart/ILineConfig';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
    loading_stats = true;
    stats: any = {};

    levelOptions: Array<IDropdown> = [
        {
            value: 'all',
            label: 'All',
            optionEnabled: true
        },
        {
            label: 'Oracle 1',
            value: '1',
            optionEnabled: true
        },
        {
            label: 'Oracle 2',
            value: '2',
            optionEnabled: true
        },
        {
            label: 'Oracle 3',
            value: '3',
            optionEnabled: true
        },
        {
            label: 'Oracle 4',
            value: '4',
            optionEnabled: true
        },
        {
            label: 'Oracle 5',
            value: '5',
            optionEnabled: true
        },
        {
            label: 'Oracle 6',
            value: '6',
            optionEnabled: true
        },
        {
            label: 'Oracle 7',
            value: '7',
            optionEnabled: true
        },
        {
            label: 'Oracle 8',
            value: '8',
            optionEnabled: true
        },
        {
            label: 'Oracle 9',
            value: '9',
            optionEnabled: true
        }
    ];

    current_level: any = 'all';
    current_level_name: any;
    loading_charts = true;
    chartLineConfig: Array<ILineConfig> = [];
    current_chart_type = 'cumulative';
    current_chart_duration = '3d';

    constructor(
        public httpService: HttpService,
        private ambassadorService: AmbassadorService,
        private cfAlertService: CfAlertService) {
    }

    ngOnInit(): void {
        this.getOnboardingStats();
        this.getOnboardingChart();
    }

    getOnboardingStats() {
        this.loading_stats = true;

        this.ambassadorService.getOnboardingStats(this.current_level).subscribe((res) => {
            this.stats = res.data;
            this.loading_stats = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading_stats = false;

            this.cfAlertService.showError(err);
        });
    }

    getOnboardingChart() {
        this.loading_charts = true;

        this.ambassadorService.getOnboardingChart(this.current_level, this.current_chart_duration, this.current_chart_type).subscribe((res) => {
            const chart_data = res.data;

            this.chartLineConfig = [{
                data: chart_data,
                lineColor: '#cf0',

                name: 'Onboarding'
            }];

            this.loading_charts = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading_charts = false;

            this.cfAlertService.showError(err);
        });
    }

    changeOnboardingChartType(type: string) {
        if (this.current_chart_type === type)
            return;

        this.current_chart_type = type;

        this.getOnboardingChart();
    }

    changeOnboardingChartDuration(duration: string) {
        if (this.current_chart_duration === duration)
            return;

        this.current_chart_duration = duration;

        this.getOnboardingChart();
    }

    getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getChartRandomData() {
        const data = [];
        const start = new Date().getTime();
        const MS_IN_DAY = 24 * 60 * 60 * 1000;

        for (let i = 0; i < 30; i++) {
            data.push({
                y: this.getRandomInt(0, 100),
                x: start - (i * MS_IN_DAY)
            });
        }

        return data;
    }

    levelChange(option: IDropdown) {
        console.log('levelChange', option);

        this.current_level = option.value;
        this.current_level_name = option.label;

        this.getOnboardingStats();
        this.getOnboardingChart();
    }
}
