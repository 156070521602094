<div class="app-header-popup-menu" [ngClass]="httpService.theme">
    <div class="diamonds-holder">
        {{ httpService.user.email }}
    </div>

    <div class="divider"></div>

    <!-- <div class="diamonds-holder">
        <span>Credit Score</span>
        <span style="color: white; font-weight: bold;">
            {{ credit_score?.score | number:'0.0-0' }}
        </span>
    </div> -->

    <div class="divider"></div>

    <div class="menu-item">
        <img
            src="https://assets.credible.finance/Logout.png"
            class="menu-item-image"
            alt="Loogout"
        />

        <div class="menu-item-name" (click)="logout()">Logout</div>
    </div>
</div>
