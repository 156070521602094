import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { getWindow } from 'ssr-window';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

@Component({
    selector: 'app-network',
    templateUrl: './network.component.html',
    styleUrl: './network.component.scss'
})
export class NetworkComponent implements OnInit {
    loading: boolean = true;

    networks: Array<any> = [];
    total_count: number = 0;

    page: number = 0;
    limit: number = 16;
    paramData = 0;

    window: Window;
    
    currentOption = 'members';

    options: Array<IDropdown> = [{
        label: 'Members',
        value: 'members',
        optionEnabled: true
    },
    {
        label: 'Network Fee',
        value: 'networking-rewards',
        optionEnabled: true
    },
    {
        label: 'Rewards Claim History',
        value: 'rewards-claim-history',
        optionEnabled: true
    },
    {
        label: 'Onboarding',
        value: 'onboarding',
        optionEnabled: true
    },
    // {
    //     label: 'Liquidity',
    //     value: 'liquidity',
    //     optionEnabled: true
    // },
    // {
    //     label: 'Leaderboard',
    //     value: 'leaderboard',
    //     optionEnabled: true
    // },
    {
        label: 'Moons',
        value: 'moons',
        optionEnabled: true
    }];

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        console.log('lend.component.ts ngOnInit()');

        // this.getMembers();

        this.subscribeQueryParamsObservables();
    }

    subscribeQueryParamsObservables() {
        this.route.queryParams.subscribe((params) => {
            if (params['data']) {
                const tab = params['data'];

                if (this.options.find(t => t.value === tab))
                    this.currentOption = tab;
            }
        });
    }

    getMembers() {
        const data = {
            page: this.page,
            limit: this.limit
        };

        this.httpService.getMembers(data).subscribe(
            (res) => {
                this.networks = res.data;
                this.total_count = res.total_count;

                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    changeTab(event: any) {
        this.currentOption = event.value;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                tab: this.currentOption
            },
            queryParamsHandling: 'merge' // remove to replace all query params by provided
        });
    }
}
