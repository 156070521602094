import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    genesisServerUrl, serverUrl 
} from './app.config';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class StakingService {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService
    ) {}

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    getNewStakingPredefinedData() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getNewStakingPredefinedData`,
            httpOptions
        );
    }

    getStakingDetails() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getStakingDetails`,
            httpOptions
        );
    }

    getStakings(data: any) {
        const page = data.page;
        const limit = data.limit;
        const plan_id = data.plan_id;
        const status = data.status;
        const closed = data.closed;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getStakings?page=${page}&limit=${limit}&plan_id=${plan_id}&status=${status}&closed=${closed}`,
            httpOptions
        );
    }
    getDelegateStats(data: any) {
        const page = data.page;
        const limit = data.limit;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getDelegateStats?page=${page}&limit=${limit}`,
            httpOptions
        );
    }
    getStakingRewardBalance(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getStakingRewardBalance?currency=${data.currency}`,
            httpOptions
        );
    }

    getUnstakings(data: any) {
        const page = data.page;
        const limit = data.limit;
        const plan_id = data.plan_id;
        const status = data.status;
        const closed = data.closed;
        const staking_id = data.staking_id;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getUnstakings?page=${page}&limit=${limit}&plan_id=${plan_id}&status=${status}&closed=${closed}&staking_id=${staking_id}`,
            httpOptions
        );
    }

    createStaking(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${serverUrl}createStaking`,
            body,
            httpOptions
        );
    }

    createUnstaking(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}createUnstaking`,
            body,
            httpOptions
        );
    }

    updateStaking(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}updateStaking`,
            body,
            httpOptions
        );
    }

    updateUnstaking(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}updateUnstaking`,
            body,
            httpOptions
        );
    }
}
