import {
    Component, EventEmitter, Inject, 
    OnInit,
    Output
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';
import {
    MAT_DIALOG_DATA, MatDialogRef 
} from '@angular/material/dialog';
import { nullChecker } from '../../helpers/nullChecker';
import { OnboardingService } from '../onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-invite-code-modal',
    templateUrl: './invite-code-modal.component.html',
    styleUrl: './invite-code-modal.component.scss'
})
export class InviteCodeModalComponent implements OnInit {
    loading: boolean = false;
    btn_loading: boolean = false;
  
    showModal = true;
    invite_code: string = '';
    @Output() completed = new EventEmitter<any>();

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private walletService: WalletService,
        private router: Router,
        private dialogRef: MatDialogRef<InviteCodeModalComponent>,
        private onboardingService: OnboardingService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.showModal = data.showModal;
    }

    ngOnInit(): void {
        console.log('new-unlocking.component.ts ngOnInit()');
    }

    showLoader() {
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    addInviteCode() {
        this.updateReferral();
    }

    closeModal() {
        this.dialogRef.close({
            status: 'reject'
        });
    }

    onChangeCode(event: any) {
        console.log('event', event);

        this.invite_code = event;
        console.log('invite_code', this.invite_code);
    }

    updateReferral() {
        this.btn_loading = true;
    
        if (nullChecker(this.invite_code)) {
            this.cfAlertService.showMessage('Invite code cannot be empty', true);
    
            this.btn_loading = false;
    
            return;
        }
    
        if (this.invite_code.length < 4 || this.invite_code.length > 8) {
            this.cfAlertService.showMessage('Invite code must be between 4 - 8 characters', true);
            this.loading = false;

            return;
        }
    
        const body = {
            invite_code: this.invite_code.trim()
        };
    
        this.onboardingService.updateReferral(body).subscribe((res: any) => {
            this.btn_loading = false;
    
            this.cfAlertService.showMessage(res.message);
            this.completed.emit();
            this.dialogRef.close({
                status: 'accept'
            });
            this.httpService.getCurrentUser(() => {
                
            });
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.btn_loading = false;
        });
    }
}
