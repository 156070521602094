import {
    AfterViewInit,
    Component, EventEmitter, Inject, Input, OnInit, Output,
    PLATFORM_ID
} from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { OnboardingService } from '../../onboarding.service';
import { getWindow } from 'ssr-window';
import { LocalStorageService } from '../../../services/localstorage.service';
import { isPlatformBrowser } from '@angular/common';
import { nullChecker } from '../../../helpers/nullChecker';
import { RecaptchaService } from '../../recaptcha.service';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrl: './email-verification.component.scss'
})

export class EmailVerificationComponent implements OnInit, AfterViewInit {
    platformId: object = {};
    isBrowser: boolean = false;

    @Output() completed = new EventEmitter<any>();
    @Input() invite_code: string = '';
    isDisabled: boolean = true;

    register_loading: boolean = false;
    verify_otp_loading: boolean = false;

    window = getWindow();

    email: string = '';
    email_otp: string = '';
    show_email_otp: boolean = false;
    termsAndConditions: boolean = false;
    resend_otp_loading: boolean = false;
    use_manual_recaptcha: boolean = false;

    constructor(
        public httpService: HttpService,
        private recaptchaService: RecaptchaService,
        private onboardingService: OnboardingService,
        private cfAlertService: CfAlertService,
        private localStorageService: LocalStorageService,
        @Inject(PLATFORM_ID) platformId: object
    ) {
        this.platformId = platformId;

        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        if (this.isBrowser)
            this.setUpGoogleOneTap();
    }

    onChangeEmail(event: any) {
        this.email = event;
    }

    changeVerificationCode(email_otp: string) {
        this.email_otp = email_otp;
    }

    async registerWithEmail() {
        if (!this.termsAndConditions) {
            this.cfAlertService.showMessage('Please agree to the terms and conditions', true);

            return;
        }

        this.register_loading = true;

        const recaptcha = await this.recaptchaService.getRecaptcha('registerWithEmail', this.use_manual_recaptcha);

        const body = {
            ...recaptcha,
            email: this.email,
            invite_code: this.invite_code
        };

        this.onboardingService.registerWithEmail(body).subscribe((res: any) => {
            this.register_loading = false;

            this.cfAlertService.showSuccess(res.message);

            this.show_email_otp = true;
        }, (err: HttpErrorResponse) => {
            if (err.error.message === 'Invalid recaptcha') {
                this.use_manual_recaptcha = true;
                this.registerWithEmail();

                return;
            }
            
            this.cfAlertService.showError(err);
            this.register_loading = false;
        });
    }

    verifyRegisterEmailOtp() {
        if (!this.termsAndConditions) {
            this.cfAlertService.showMessage('Please agree to the terms and conditions', true);

            return;
        }

        if (this.email_otp === '') {
            this.cfAlertService.showMessage('Verification code is empty', true);

            return;
        }

        if (isNaN(+this.email_otp)) {
            this.cfAlertService.showMessage('Invalid verification code', true);

            return;
        }

        this.verify_otp_loading = true;

        const body = {
            email: this.email,
            otp: +this.email_otp
        };

        this.onboardingService.verifyRegisterEmailOtp(body).subscribe((res: any) => {
            this.localStorageService.setItem('token', res.headers.get('token') || '');

            this.verify_otp_loading = false;

            console.log('verifyRegisterEmailOtp res.body.data', res.body.data);
            this.completed.emit(res.body.data);
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.verify_otp_loading = false;
        });
    }

    resendVerificationCode() {
        this.resend_otp_loading = true;
        const body = {
            email: this.email,
            type: 'registration'
        };

        this.onboardingService.resendEmailOtp(body).subscribe((res: any) => {
            this.resend_otp_loading = false;

            console.log('resend email otp', res.data);
            this.cfAlertService.showSuccess(res.message);
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.resend_otp_loading = false;
        });
    }

    agreeTerms() {
        this.termsAndConditions = !this.termsAndConditions;

        console.log('termsAndConditions => ', this.termsAndConditions);
    }

    setUpGoogleOneTap() {
        // @ts-ignore
        google.accounts.id.initialize({
            client_id: '760935076833-a5uc40lsv9tiefk1d1p6otv18lijrafn.apps.googleusercontent.com',
            callback: this.handleCredentialResponse.bind(this),
            auto_select: false,
            cancel_on_tap_outside: true
        });

        // @ts-ignore
        google.accounts.id.renderButton(
            // @ts-ignore
            document.getElementById('google-button'),
            {
                theme: 'outline',
                size: 'large'
            }
        );

        // @ts-ignore
        google.accounts.id.prompt((notification: PromptMomentNotification) => { });
    }

    async handleCredentialResponse(response: any) {
        await this.registerWithGoogle(response.credential, response.select_by);
    }

    async registerWithGoogle(credential: string, select_by: string) {
        this.register_loading = true;

        const recaptcha = await this.recaptchaService.getRecaptcha('registerWithGoogle', this.use_manual_recaptcha);

        const body: any = {
            ...recaptcha,
            credential: credential,
            select_by: select_by
        };

        if (!nullChecker(this.invite_code))
            body.invite_code = this.invite_code;

        this.onboardingService.registerWithGoogle(body).subscribe((res: any) => {
            this.cfAlertService.showSuccess('Registration successful');

            console.log('registerWithGoogle res.body.data', res.body.data);

            this.localStorageService.setItem('token', res.headers.get('token'));

            this.httpService.getCurrentUser(() => {
                console.log('registerWithGoogle getCurrentUser');

                this.register_loading = false;

                this.completed.emit(res.body.data);
            });
        }, (err: HttpErrorResponse) => {
            if (err.error.message === 'Invalid recaptcha') {
                this.use_manual_recaptcha = true;
                this.registerWithGoogle(credential, select_by);

                return;
            }

            this.cfAlertService.showError(err);
            this.register_loading = false;
        });
    }
}
