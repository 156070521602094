import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, HostListener, Input, OnInit
} from '@angular/core';

import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';

import { getWindow } from 'ssr-window';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-node-unlock-schedule',
    templateUrl: './node-unlock-schedule.component.html',
    styleUrl: './node-unlock-schedule.component.scss'
})

export class NodeUnlockScheduleComponent implements OnInit {
    loading: boolean = false;
    items: Array<any> = [];
    total_count: number = 0;
    page: number = 0;
    limit: number = 10;

    @Input() node_id: string | null = null;

    unlock_schedule: any;
    window: Window;
    
    width: any;

    constructor(public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private genesisService: GenesisService
    ) {
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.getNodeUnlockSchedule();

        this.width = this.window.innerWidth;
    }

    pageChanged(event: any) {
        this.page = event;
        this.getNodeUnlockSchedule();
    }

    getNodeUnlockSchedule() {
        this.loading = true;

        this.genesisService.getNodeUnlockSchedule(this.node_id).subscribe(
            (res: any) => {
                this.unlock_schedule = res.data;

                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);
                this.cfAlertService.showError(err);
            }
        );
    }
}