import {
    AfterViewInit,
    Component, Inject, OnInit,
    PLATFORM_ID
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { LocalStorageService } from '../../services/localstorage.service';
import { OnboardingService } from '../onboarding.service';
import { getWindow } from 'ssr-window';
import { HttpErrorResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-login-with-email',
    templateUrl: './login-with-email.component.html',
    styleUrl: './login-with-email.component.scss'
})
export class LoginWithEmailComponent implements OnInit, AfterViewInit {
    platformId: object = {};
    isBrowser: boolean = false;

    returnUrl: string = '';
    window: Window = getWindow();
    email: string = '';
    otp: string = '';
    loading: boolean = false;

    email_step: boolean = true;
    otp_step: boolean = false;
    resend_otp_loading: boolean = false;
    google_redirect_url: string = `${this.window.location.origin}/social/google/callback`;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private route: ActivatedRoute,
        private onboardingService: OnboardingService,
        private router: Router,
        private localStorageService: LocalStorageService,
                @Inject(PLATFORM_ID) platformId: object
    ) {
        this.platformId = platformId;

        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.subscribeQueryParams();
    }

    ngAfterViewInit(): void {
        if (this.isBrowser)
            this.setUpGoogleOneTap();
    }

    subscribeQueryParams() {
        this.route.queryParams.subscribe(params => {
            if (params['tab'])
                this.returnUrl = params['returnUrl'];

            else
                this.httpService.setMetaData('Login with email', 'Login with email');
        });
    }

    changeEmail(email: string) {
        this.email = email;
    }

    changeOtp(otp: string) {
        this.otp = otp;
    }

    loginWithEmail() {
        this.loading = true;

        const body = {
            email: this.email
        };

        this.onboardingService.loginWithEmail(body).subscribe((res: any) => {
            this.loading = false;

            this.cfAlertService.showSuccess(res.message);

            this.email_step = false;
            this.otp_step = true;
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.loading = false;
        });
    }

    async verifyLoginEmailOtp() {
        this.loading = true;

        const body = {
            email: this.email,
            otp: +this.otp
        };

        this.onboardingService.verifyLoginEmailOtp(body).subscribe((res: any) => {
            this.cfAlertService.showSuccess('Login successful');

            this.localStorageService.setItem('token', res.headers.get('token') || '');

            this.httpService.getCurrentUser(() => {
                this.loading = false;

                this.router.navigate([ this.returnUrl || '/airdrop' ]);
            });
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.loading = false;
        });
    }
    resendVerificationCode() {
        this.resend_otp_loading = true;
        const body = {
            type: 'login',
            email: this.email
        };

        this.onboardingService.resendEmailOtp(body).subscribe((res: any) => {
            this.resend_otp_loading = false;

            console.log('resend email otp', res.data);
            this.cfAlertService.showSuccess(res.message);
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.resend_otp_loading = false;
        });
    }

    setUpGoogleOneTap() {
        // @ts-ignore
        google.accounts.id.initialize({
            client_id: '760935076833-a5uc40lsv9tiefk1d1p6otv18lijrafn.apps.googleusercontent.com',
            callback: this.handleCredentialResponse.bind(this),
            auto_select: false,
            cancel_on_tap_outside: true
        });

        // @ts-ignore
        google.accounts.id.renderButton(
            // @ts-ignore
            document.getElementById('google-button'),
            {
                theme: 'outline',
                size: 'large',
                width: '100%' 
            }
        );
        // @ts-ignore
        google.accounts.id.prompt((notification: PromptMomentNotification) => {});
    }

    async handleCredentialResponse(response: any) {
        // Here will be your response from Google.
        console.log(response);

        await this.loginWithGoogle(response.credential, response.select_by);
    }

    async loginWithGoogle(credential: string, select_by: string) {
        this.loading = true;

        const body = {
            credential: credential,
            select_by: select_by
        };

        this.onboardingService.loginWithGoogle(body).subscribe((res: any) => {
            this.cfAlertService.showSuccess('Login successful');

            this.localStorageService.setItem('token', res.headers.get('token') || '');

            this.httpService.getCurrentUser(() => {
                this.loading = false;

                this.router.navigate([ this.returnUrl || '/airdrop' ]);
            });
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.loading = false;
        });
    }
}
