import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Input, OnInit 
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import IUser from '../../interfaces/IUser';
import User from '../../models/User';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import ICredUnstaking from '../../interfaces/ICredUnstaking';
import { StakingService } from '../staking.service';
import getRedirectionTxUrl from '../../helpers/getRedirectionTxUrl';

@Component({
    selector: 'app-my-unstakings',
    templateUrl: './my-unstakings.component.html',
    styleUrls: [ './my-unstakings.component.scss' ]
})
export class MyUnstakingsComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    currentTime: number = new Date().getTime();
    unstakings: Array<ICredUnstaking> = [];
    getRedirectionTxUrl: any = getRedirectionTxUrl;
    @Input() data: any;

    total_count: number = 0;
    page: number = 0;
    limit: number = 10;
    opened_panel: string = '';

    statusOptions: Array<IDropdown> = [
        {
            label: 'All',
            value: '',
            optionEnabled: true
        },
        {
            label: 'Pending',
            value: 'pending',
            optionEnabled: true
        },
        {
            label: 'Processing',
            value: 'processing',
            optionEnabled: true
        },
        {
            label: 'Completed',
            value: 'completed',
            optionEnabled: true
        },
        {
            label: 'Failed',
            value: 'failed',
            optionEnabled: true
        }
    ];
    selected_status: string = '';

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private stakingService: StakingService
    ) {}

    ngOnInit(): void {
        this.getCredunstakings();

        this.httpService.setMetaData('My unstakings', 'My unstakings');
    }

    afterExpand(lending_id: string) {
        this.opened_panel = lending_id;
    }

    getCredunstakings() {
        this.loading = true;

        const body: any = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            status: this.selected_status
        };

        if (this.data && this.data.staking_id) 
            body['staking_id'] = this.data.staking_id;

        this.stakingService.getUnstakings(body).subscribe(
            (res: any) => {
                this.unstakings = res.data;
                this.total_count = res.total_count;

                if (this.unstakings.length > 0)
                    this.opened_panel = this.unstakings[0].unstaking_id;

                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    pageChanged(event: any) {
        this.page = event;
        this.getCredunstakings();
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changeStatus(event: any) {
        this.selected_status = event.value;
        this.page = 0;
        this.getCredunstakings();
    }

    getStatusClasses(id: string, status: string) {
        const classes: any = {};

        if (this.opened_panel === id) classes['current-opened'] = true;

        if (status === 'pending') classes['status-pending'] = true;
        else if (status === 'processing') classes['status-processing'] = true;
        else if (status === 'completed') classes['status-success'] = true;
        else if (status === 'failed') classes['status-failed'] = true;

        return classes;
    }
}
