import { Injectable } from '@angular/core';
import { getWindow } from 'ssr-window';
import { userServerUrl} from '../app/app.config';
import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import IUser from '../interfaces/IUser';

@Injectable({
    providedIn: 'root'
})
export class KycService {
    window = getWindow();
    
    constructor(private http: HttpClient) { }

    generateKycUrl(user: IUser) {
        const body = {
            merchant_customer_id: user.user_id,
            merchant_redirect_url: `${this.window.location.origin}/account`
        };

        const httpOptions = {
            headers: new HttpHeaders({
                token: localStorage.getItem('token') as string
            })
        };

        return this.http.post<any>(`${userServerUrl}generateKycUrl`, body, httpOptions);
    }
}
