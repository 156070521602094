import {
    Component, OnInit, Output, EventEmitter
} from '@angular/core';
import {CfAlertService} from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpService } from '../../../services/http.service';
import { nullChecker } from '../../../helpers/nullChecker';
import { OnboardingService } from '../../onboarding.service';

@Component({
    selector: 'app-invite-code',
    templateUrl: './invite-code.component.html',
    styleUrl: './invite-code.component.scss'
})
export class InviteCodeComponent implements OnInit {
    @Output() completed = new EventEmitter<any>();

    window: Window;
    
    loading: boolean = false;
    invite_code: string = '';

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private onboardingService: OnboardingService
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {

    }

    onChangeCode(event: any) {
        console.log('event', event);

        this.invite_code = event;
        console.log('invite_code', this.invite_code);
    }

    updateReferral() {
        this.loading = true;

        console.log('this.invite_code ', this.invite_code);

        if (nullChecker(this.invite_code)) {
            this.cfAlertService.showMessage('Invite code cannot be empty', true);

            this.loading = false;

            return;
        }

        if (this.invite_code.length < 4 || this.invite_code.length > 8) {
            this.cfAlertService.showMessage('Invite code must be between 4 - 8 characters', true);
            this.loading = false;

            return;
        }

        const body = {
            invite_code: this.invite_code.trim()
        };

        this.onboardingService.updateReferral(body).subscribe((res: any) => {
            this.loading = false;

            this.cfAlertService.showMessage(res.message);
            this.completed.emit();
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.loading = false;
        });
    }

    skipReferral() {
        this.completed.emit();
    }
}