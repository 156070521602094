<div class="app-sale-agreement" [ngClass]="httpService.theme">
    <div class="row center-aligned">
        <div class="col-md-12 text-center">
            <span mat-dialog-title class="dialog-heading">Credible Finance Token (vCRED) Private Sale
                Agreement</span>
        </div>
    </div>
    <mat-dialog-content>
        <cf-card [theme]="httpService.theme" type="empty" class="mb-4">
            <div class="agreement-content">
                <div class="row">
                    <div class="col-md-12">
                        <span cdkFocusInitial>
                            This Private Token Sale Agreement (the "Agreement") is entered into on this <span
                                style="color: #cf0;">{{ date.toDateString()
                                }}</span>,
                            ("Effective Date"), by and between:</span>

                        <br />
                        <br />

                        <ul>
                            <li>
                                <span style="color: #cf0;">Credible Finance</span>, a decentralized autonomous
                                organization ("DAO") established under the laws of the <span
                                    style="color: #cf0;">British Virgin Islands ("BVI")</span>, and
                            </li>
                            <li>
                                <span style="color: #cf0;">Buyer</span>, an individual or entity purchasing <span
                                    style="color: #cf0;">$vCRED</span> tokens, who has completed all necessary KYC
                                procedures and self-declared themselves to be an accredited investor under applicable
                                securities laws (the "Buyer").
                            </li>
                        </ul>

                        <p>
                            <span style="color: #cf0;">WHEREAS</span>, Credible Foundation is offering the sale of
                            <span style="color: #cf0;">$vCRED</span> tokens to eligible participants,
                            which will be replaced by <span style="color: #cf0;">$CRED</span> tokens upon the <span
                                style="color: #cf0;">Token
                                Generation Event</span> ("TGE");
                        </p>

                        <p>
                            <span style="color: #cf0;">WHEREAS</span>, the Buyer wishes to purchase <span
                                style="color: #cf0;">$vCRED</span> tokens in
                            accordance with the terms set forth
                            in this Agreement, and fully acknowledges the risks inherent in cryptocurrency investments;
                        </p>

                        <p><span style="color: #cf0;">NOW, THEREFORE</span>, in consideration of the mutual promises and
                            covenants set forth in this
                            Agreement, the parties agree as follows:</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <ol>
                            <li class="mt-4">
                                <span style="color: #cf0;">PURCHASE AND SALE OF TOKENS</span>
                                <ol>
                                    <li>
                                        <span style="color: #cf0;">Sale of Tokens</span>

                                        <p>
                                            Credible Foundation agrees to sell, and the Buyer agrees to purchase <span
                                                style="color: #cf0;">$vCRED</span>
                                            tokens (the "Tokens") under the following terms:
                                        </p>
                                        <ul>
                                            <li><span style="color: #cf0;">Purchase Price:</span> The price per $vCRED
                                                token is <span style="color: #cf0;">{{ data.cred_rate_payment_currency |
                                                    number: '0.0-6' }}
                                                    {{ data.payment_currency | uppercase }}</span>.</li>
                                            <li><span style="color: #cf0;">Total Purchase:</span> The total number of
                                                $vCRED tokens to be purchased is
                                                <span style="color: #cf0;">{{ data.receivable_amount | number: '0.0-6'
                                                    }}
                                                    vCRED</span>.
                                            </li>
                                            <li><span style="color: #cf0;">Payment Method:</span> The Buyer will pay the
                                                Total Purchase Price in <span style="color: #cf0;">{{
                                                    data.payment_currency | uppercase }}</span>.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span style="color: #cf0;">Token Delivery</span>

                                        <p>
                                            Upon receipt of the full <span style="color: #cf0;">Total Purchase
                                                Price</span>, Credible Foundation will
                                            deliver <span style="color: #cf0;">$vCRED</span> tokens to the Buyer's
                                            designated cryptocurrency wallet
                                            address. These <span style="color: #cf0;">$vCRED</span> tokens will serve as
                                            placeholder tokens until the
                                            <span style="color: #cf0;">Token Generation Event (TGE)</span>, at which
                                            point they will be replaced with
                                            $CRED tokens.
                                        </p>
                                    </li>
                                </ol>
                            </li>

                            <li class="mt-4">
                                <span style="color: #cf0;">VESTING AND RELEASE</span>

                                <ol>
                                    <li>
                                        <span style="color: #cf0;">Vesting Period</span>

                                        <p>The <span style="color: #cf0;">$vCRED</span> tokens purchased by the Buyer
                                            are subject to a <span style="color: #cf0;">18-month linear
                                                vesting schedule</span>, beginning on the date of the <span
                                                style="color: #cf0;">Token Generation Event (TGE)</span>.
                                            The vesting will be implemented as follows:</p>
                                    </li>

                                    <li>
                                        <span style="color: #cf0;">Token Release Schedule</span>
                                        <p>The Tokens will be released according to the following <span
                                                style="color: #cf0;">monthly cumulative
                                                percentages</span> of the total tokens purchased:</p>

                                        <table>
                                            <thead>
                                                <th>Date</th>
                                                <th>Days</th>
                                                <th>Daily</th>
                                                <th>Monthly</th>
                                                <th>Cumulative</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of unlock_schedule">
                                                    <td>{{ item.time | date:'MMMM yyyy' }}</td>
                                                    <td>
                                                        {{ item.total_days }} days
                                                    </td>
                                                    <td>
                                                        {{ item.cred_receivable_per_day | number:'0.0-6'}} vCRED
                                                    </td>
                                                    <td>
                                                        {{ item.monthly_amount | number:'0.0-6'}} vCRED
                                                    </td>
                                                    <td>
                                                        {{ item.cumulative_amount | number:'0.0-6'}} vCRED
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p>The total number of tokens (<span style="color: #cf0;">{{
                                                data.receivable_amount | number: '0.0-6'
                                                }}
                                                vCRED</span>) will
                                            be divided equally across the <span style="color: #cf0;">18
                                                months</span>.</p>
                                    </li>

                                    <li>
                                        <span style="color: #cf0;">Transfer Restrictions</span>

                                        <ul>
                                            <li><span style="color: #cf0;">$vCRED</span> tokens are non-transferable
                                                until the <span style="color: #cf0;">Token Generation Event
                                                    (TGE)</span>.</li>
                                            <li>After the TGE, the <span style="color: #cf0;">$vCRED</span> tokens will
                                                be replaced by $CRED tokens, which
                                                will also be subject to the same linear vesting schedule.</li>
                                            <li>During the vesting period, the Buyer may stake $CRED tokens, but cannot
                                                sell, transfer, or otherwise dispose of the tokens until they are vested
                                                according to the schedule.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </li>

                            <li class="mt-4">
                                <span style="color: #cf0;">RISK DISCLOSURE AND ACKNOWLEDGEMENTS</span>

                                <ol>
                                    <li>
                                        <span style="color: #cf0;">Cryptocurrency Investment Risks</span>

                                        <p> The Buyer acknowledges and understands that the purchase of <span
                                                style="color: #cf0;">$vCRED</span> tokens
                                            and subsequent $CRED tokens involves significant risks, including, but not
                                            limited to:</p>

                                        <ul>
                                            <li><span style="color: #cf0;">Market Volatility</span>: The value of $CRED
                                                tokens may fluctuate
                                                substantially, and there is no guarantee of future value or liquidity.
                                            </li>
                                            <li><span style="color: #cf0;">Regulatory Risk</span>: Changes in government
                                                regulation may affect the ability
                                                to use or transfer $CRED tokens.</li>
                                            <li><span style="color: #cf0;">Technology Risk</span>: The Buyer
                                                acknowledges that blockchain technologies and
                                                smart contracts are experimental and may contain bugs or vulnerabilities
                                                that could affect the value of $CRED tokens.</li>
                                            <li><span style="color: #cf0;">Liquidity Risk</span>: The $CRED token may
                                                not be easily traded or liquidated,
                                                and there may be no active market for the token during or after the
                                                vesting period.</li>
                                        </ul>
                                    </li>

                                    <li><span style="color: #cf0;">No Guarantee of Returns</span>
                                        <p>The Buyer understands and agrees that the <span
                                                style="color: #cf0;">$CRED</span> token is a high-risk,
                                            speculative investment. Credible Foundation does not guarantee any return on
                                            investment, and the value of the $CRED token may decrease or become
                                            worthless.
                                        </p>
                                    </li>

                                    <li>
                                        <span style="color: #cf0;">Do Your Own Research (DYOR)</span>

                                        <p>The Buyer confirms that they have conducted their own research ("DYOR")
                                            before purchasing <span style="color: #cf0;">$vCRED</span> tokens and fully
                                            understand the risks associated
                                            with investing in cryptocurrency. The Buyer acknowledges that Credible
                                            Foundation has not provided any financial, tax, or investment advice, and
                                            that the decision to purchase the tokens is solely their own.
                                        </p>
                                    </li>

                                    <li><span style="color: #cf0;">No Solicitation or Poaching</span>
                                        <p>
                                            The Buyer affirms that they have not been solicited or induced by Credible
                                            Foundation or any other individual or entity to purchase the <span
                                                style="color: #cf0;">$vCRED</span> tokens,
                                            and that the decision to participate in the Token Sale was made
                                            independently.
                                        </p>
                                    </li>
                                </ol>
                            </li>

                            <li class="mt-4">
                                <span style="color: #cf0;">KYC AND ACCREDITED STATUS</span>

                                <ol>
                                    <li><span style="color: #cf0;">KYC Verification</span>

                                        <p>The Buyer confirms that they have completed the Know-Your-Customer (KYC)
                                            verification process required by Credible Foundation to participate in the
                                            Token Sale. The KYC process involves providing necessary documentation and
                                            information for identity verification.</p>
                                    </li>

                                    <li><span style="color: #cf0;">Accredited Investor Status</span>

                                        <p>
                                            The Buyer self-declares that they are an accredited investor as defined
                                            under applicable securities laws. The Buyer understands that Credible
                                            Foundation reserves the right to verify their accredited status at any time,
                                            and that failure to provide satisfactory evidence of such status may result
                                            in the termination of this Agreement.
                                        </p>
                                    </li>
                                </ol>
                            </li>

                            <li class="mt-4">
                                <span style="color: #cf0;">REFUND POLICY</span>

                                <ol>
                                    <li><span style="color: #cf0;">Non-Refundable Purchase</span>
                                        <p>
                                            The Buyer acknowledges that all purchases of <span
                                                style="color: #cf0;">$vCRED</span> tokens are final and
                                            non-refundable. The Buyer agrees that they will not be entitled to a refund
                                            under any circumstances, including, but not limited to, regulatory changes,
                                            market fluctuations, or dissatisfaction with the investment outcome.
                                        </p>
                                    </li>

                                    <li><span style="color: #cf0;">Nominee in Case of Death</span>

                                        <p>
                                            In the event of the Buyer’s death during the Vesting Period, the Buyer may
                                            designate a nominee to receive the remaining vested tokens. This nomination
                                            must be in writing and submitted to Credible Foundation, subject to
                                            applicable laws and regulations.
                                        </p>
                                    </li>
                                </ol>
                            </li>

                            <li class="mt-4">
                                <span style="color: #cf0;">LIMITATION OF LIABILITY</span>

                                <ol>
                                    <li><span style="color: #cf0;">No Liability for Losses</span>

                                        <p>Credible Foundation shall not be held liable for any losses, damages, or
                                            claims arising from the Buyer’s participation in the Token Sale, including,
                                            without limitation, losses resulting from:</p>

                                        <ul>
                                            <li>Market fluctuations or volatility in the value of $CRED.</li>
                                            <li>Regulatory changes or legal issues affecting the purchase or use of
                                                $CRED tokens.</li>
                                            <li>Technology failures, bugs, or vulnerabilities in the blockchain or smart
                                                contracts.</li>
                                        </ul>
                                    </li>

                                    <li><span style="color: #cf0;">No Guarantee of Value</span>

                                        <p>The Buyer understands that $CRED tokens are highly speculative, and Credible
                                            Foundation makes no representations or guarantees regarding the future value
                                            or liquidity of the tokens.</p>
                                    </li>
                                </ol>
                            </li>

                            <li class="mt-4">
                                <span style="color: #cf0;">JURISDICTION AND DISPUTE RESOLUTION</span>

                                <ol>
                                    <li><span style="color: #cf0;">Governing Law</span>

                                        <p>This Agreement shall be governed by and construed in accordance with the laws
                                            of the British Virgin Islands (BVI), without regard to its conflict of law
                                            principles.</p>
                                    </li>

                                    <li><span style="color: #cf0;">Dispute Resolution</span>

                                        <p>Any disputes arising from this Agreement shall be resolved by binding
                                            arbitration in accordance with the rules of the arbitration court, with the
                                            seat of arbitration in the British Virgin Islands. Both parties agree that
                                            arbitration will be the exclusive method of resolving disputes related to
                                            this Agreement.</p>
                                    </li>
                                </ol>
                            </li>

                            <li class="mt-4">
                                <span style="color: #cf0;">TERMINATION CLAUSE</span>

                                <ol>
                                    <li><span style="color: #cf0;">Termination by Credible Foundation</span>

                                        <p>Credible Foundation may terminate this Agreement and cancel the Token Sale at
                                            any time if:</p>

                                        <ol>
                                            <li>The Buyer fails to complete the KYC process or misrepresents their
                                                status as an accredited investor.
                                            </li>

                                            <li>Credible Foundation is required by law or regulation to terminate the
                                                sale.
                                            </li>

                                            <li>Credible Foundation determines, at its sole discretion, that the Buyer’s
                                                participation violates any applicable law or regulation.
                                            </li>
                                        </ol>
                                    </li>

                                    <li><span style="color: #cf0;">Termination by Buyer</span>

                                        <p>The Buyer may not terminate or cancel this Agreement, and all purchases are
                                            final and non-refundable under any circumstances.</p>
                                    </li>
                                </ol>
                            </li>

                            <li class="mt-4">
                                <span style="color: #cf0;">GENERAL PROVISIONS</span>

                                <ol>
                                    <li><span style="color: #cf0;">Entire Agreement</span>

                                        <p>This Agreement constitutes the entire understanding between the parties
                                            concerning the sale of <span style="color: #cf0;">$vCRED</span> tokens and
                                            supersedes all prior agreements and
                                            representations.</p>
                                    </li>

                                    <li><span style="color: #cf0;">Amendments</span>

                                        <p>Credible Foundation reserves the right to amend this Agreement at any time,
                                            with notice provided to the Buyer. Continued participation in the Token Sale
                                            constitutes acceptance of the updated terms.</p>
                                    </li>

                                    <li><span style="color: #cf0;">Severability</span>

                                        <p>If any provision of this Agreement is deemed invalid, illegal, or
                                            unenforceable, the remainder of the Agreement shall remain in effect.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>

                        <div class="signatures">
                            <cf-card [theme]="httpService.theme" type="empty">
                                Credible Finance (Company):

                                <p>
                                    By: SDB
                                    <br />
                                    Name: <span style="color: #cf0;">Shrikant Bhalerao</span>
                                    <br />
                                    Designation: <span style="color: #cf0;">CEO</span>
                                    <br />
                                    Time: <span style="color: #cf0;">{{ date.toUTCString() }}</span>
                                </p>
                            </cf-card>
                            <cf-card [theme]="httpService.theme" type="empty">
                                Buyer:

                                <p>
                                    Name: <span style="color: #cf0;">{{ httpService.user.first_name }} {{
                                        httpService.user.last_name }}:</span>
                                    <br />
                                    IP Address: <span style="color: #cf0;">{{ ip_location.query }}</span>
                                    <br />
                                    Location: <span style="color: #cf0;">{{ ip_location_string }}</span>
                                    <br />
                                    Time: <span style="color: #cf0;">{{ date.toUTCString() }}</span>
                                </p>
                            </cf-card>
                        </div>

                        <br />

                        <p>This Agreement is effective as of the Effective Date.</p>
                    </div>
                </div>
            </div>
        </cf-card>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="container">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6 d-flex justify-content-evenly">
                    <cf-button [theme]="httpService.theme" label="I Accept" type="solid"
                        (clicked)="acceptSaleAgreement()"></cf-button>
                    <cf-button [theme]="httpService.theme" label="Reject" type="outline"
                        (clicked)="rejectSaleAgreement()"></cf-button>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </mat-dialog-actions>
</div>