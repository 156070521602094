/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';

@Component({
    selector: 'app-affiliate-program-policy',
    templateUrl: './affiliate-program-policy.component.html',
    styleUrl: './affiliate-program-policy.component.scss'
})
export class AffiliateProgramPolicyComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}

