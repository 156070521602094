<div class="app-transactions container-fluid" [ngClass]="httpService.theme">

    <div class="controls">
        <div class="row">
            <div class="col-md-4 col-sm-12 col-12"></div>
            <div class="col-md-4 col-sm-12 col-12"></div>
            <div class="col-md-4 col-sm-12 col-12">
                <cf-dropdown [theme]="httpService.theme" [options]="options" [selected]="currentOption"
                    (change)="changeTab($event)" label="Transaction type" [showLogo]="false"></cf-dropdown>
            </div>
        </div>
    </div>

    <div class="tabs">
        <app-my-investments *ngIf="currentOption === 'my-supply'"></app-my-investments>
        <app-my-earnings *ngIf="currentOption === 'my-earnings'"></app-my-earnings>
        <app-my-withdrawals *ngIf="currentOption === 'my-withdrawals'"></app-my-withdrawals>
        <app-my-drawdowns *ngIf="currentOption === 'my-drawdowns'"></app-my-drawdowns>
        <app-my-repayments *ngIf="currentOption === 'my-repayments'"></app-my-repayments>
        <app-my-payfi-drawdowns *ngIf="currentOption === 'my-cash-loan-drawdowns'"></app-my-payfi-drawdowns>
        <app-my-payfi-repayments *ngIf="currentOption === 'my-cash-loan-repayments'"></app-my-payfi-repayments>
        <app-my-nodes-transactions *ngIf="currentOption === 'my-nodes'"></app-my-nodes-transactions>
        <app-my-unstakings *ngIf="currentOption === 'my-unstakings'"></app-my-unstakings>
        <app-staking-rewards-claim-history
            *ngIf="currentOption === 'my-staking-reward-claiming'"></app-staking-rewards-claim-history>
        <app-rewards-claim-history *ngIf="currentOption==='my-referral-reward-claiming'"></app-rewards-claim-history>
        <app-my-moon-claim-history *ngIf="currentOption==='my-moon-claim-history'"></app-my-moon-claim-history>
    </div>
</div>