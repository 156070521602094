import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, EventEmitter, Inject, Input, NgZone, OnChanges, OnInit,
    Output,
    PLATFORM_ID,
    SimpleChanges
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { Transaction } from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';
import {
    DecimalPipe, isPlatformBrowser
} from '@angular/common';
import { StakingService } from '../staking.service';
import ICredStaking from '../../interfaces/ICredStaking';
import { MetamaskService } from '../metamask.service';
import {
    MatDialog, MatDialogRef
} from '@angular/material/dialog';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import { ChangeBlockchainDialogComponent } from '../change-blockchain-dialog/change-blockchain-dialog.component';
import { ChangeChainDialogComponent } from '../change-chain-dialog/change-chain-dialog.component';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';
import getSolanaConnection from '../../helpers/getSolanaConnection';

@Component({
    selector: 'app-new-unstaking',
    templateUrl: './new-unstaking.component.html',
    styleUrls: ['./new-unstaking.component.scss']
})
export class NewUnstakingComponent implements OnInit, OnChanges {
    currencyDropdownOptions: Array<IDropdown> = [
        {
            label: 'vCRED',
            value: 'vcred',
            logo: this.httpService.getCurrencyUrl('vcred')
        }
    ];

    amount: number = 0;
    unstaked: number = 0;
    currency: string = 'vcred';

    warning_screen: boolean = true;

    @Input() data: any;

    stakingDropdownOptions: Array<IDropdown> = [];
    stakings: Array<ICredStaking> = [];

    staking_id: string = '';
    unstaking_id: string = '';
    maxUnstakingAmount: number = 0;

    loading: boolean = false;
    btn_loading: boolean = false;

    current_staking_index: number = 0;
    show_no_stakings: boolean = false;

    confirm_btn_label: string = 'Confirm';

    showConfirmButton: boolean = false;
    showAllowanceButton: boolean = false;
    differentNetwork: boolean = false;
    differentBlockchain: boolean = false;
    isBrowser: boolean = false;

    daily_unlock: number = 0;
    unlocked_cred: number = 0;

    balance: number = 0;
    sol_balance: number = 0;
    bonus_cred: number = 0;

    @Output() unstakeCompleted = new EventEmitter<string>();

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private stakingService: StakingService,
        private solflareService: SolflareService,
        public walletService: WalletService,
        private router: Router,
        private decimalPipe: DecimalPipe,
        private connectWalletDialogRef: MatDialogRef<ConnectWalletComponent>,
        private ngZone: NgZone,
        private metamaskService: MetamaskService,
        public dialog: MatDialog,
        @Inject(PLATFORM_ID) platformId: object,
        public blockchainDialogRef: MatDialogRef<ChangeBlockchainDialogComponent>,
        public networkDialogRef: MatDialogRef<ChangeChainDialogComponent>

    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        // if (this.data?.staking_id) {
        //     this.amount = this.data?.cred_unlocked;
        // }
        this.getStakings();
        this.bonus_cred = this.data.loyalty_percentage / 100 * this.data.cred_receivable;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['data'] && changes['data'].currentValue) {
            this.data = changes['data'].currentValue;

            if (this.walletService.isWalletConnected()) {
                if (this.data.blockchain === 'solana') {
                    this.showConfirmButton = true;
                    this.showAllowanceButton = false;
                }

                else {
                    this.showConfirmButton = false;
                    this.showAllowanceButton = true;
                }

                this.getGasFeeBalance(this.data.blockchain, this.data.chain);
            }
        }
    }

    getStakings() {
        this.showLoader();

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            status: 'completed',
            closed: false
        };

        this.stakingService.getStakings(body).subscribe(
            (res) => {
                this.stakings = res.data;

                if (this.stakings.length > 0) {
                    // this.stakings.forEach((element: any) => {
                    //     this.stakingDropdownOptions.push({
                    //         label: `${this.decimalPipe.transform(
                    //             element.amount,
                    //             '0.0-6'
                    //         )} ${element.currency.toUpperCase()} (${new Date(
                    //             element.created
                    //         ).toDateString()})`,
                    //         value: element.staking_id,
                    //         optionEnabled: true,
                    //     });
                    // });

                    if (this.data?.staking_id) {
                        const filteredElement = this.stakings.find(
                            (element: any) =>
                                element.staking_id === this.data.staking_id
                        );

                        if (filteredElement) {
                            // this.stakingDropdownOptions.push({
                            //     label: `${this.decimalPipe.transform(
                            //         filteredElement.amount,
                            //         '0.0-6'
                            //     )} ${filteredElement.currency.toUpperCase()} (${new Date(
                            //         filteredElement.created
                            //     ).toDateString()})`,
                            //     value: filteredElement.staking_id,
                            //     optionEnabled: true
                            // });
                            this.staking_id = filteredElement.staking_id;
                            this.unstaked = filteredElement.unstaked;

                            this.daily_unlock = parseFloat(
                                (
                                    this.data.cred_receivable / this.data.staking_info.staking_duration
                                ).toFixed(6)
                            );

                            const currentTime = new Date().getTime();
                            const delta_days = Math.floor((currentTime - this.data.created) / (1000 * 60 * 60 * 24));

                            this.unlocked_cred = delta_days * this.daily_unlock;

                            this.amount = this.unlocked_cred - this.data?.staking_info?.unstaked;
                            this.maxUnstakingAmount = this.amount;
                        }
                    }
                    else {
                        this.stakings.forEach((element: any) => {
                            this.stakingDropdownOptions.push({
                                label: `${this.decimalPipe.transform(
                                    element.amount,
                                    '0.0-6'
                                )} ${element.currency.toUpperCase()} (${new Date(
                                    element.created
                                ).toDateString()})`,
                                value: element.staking_id,
                                optionEnabled: true
                            });
                        });

                        this.staking_id = this.stakings[0].staking_id;
                        this.unstaked = this.stakings[0].unstaked;
                    }

                    // const available = this.calculateAvailable();
                    // this.amountChanged(available);
                }
                else this.show_no_stakings = true;

                this.hideLoader();
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            }
        );
    }

    showLoader() {
        this.show_no_stakings = false;
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    amountChanged(amount: number) {
        if (!amount) amount = 0;

        this.amount = parseFloat(amount.toString());

        this.confirm_btn_label = 'Unstake ' + this.decimalPipe.transform(this.amount, '0.0-6') + ' ' + this.currency.toUpperCase();
    }

    changeStaking(event: any) {
        this.staking_id = event.value;

        for (let i = 0; i < this.stakings.length; i++) {
            if (this.stakings[i].staking_id === this.staking_id) {
                this.current_staking_index = i;
                break;
            }
        }

        this.unstaked = this.stakings[this.current_staking_index].unstaked;

        const available = this.calculateAvailable();

        this.amountChanged(available);
    }

    scrollToSection(sectionId: string): void {
        const element = document.getElementById(sectionId);

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }

    unstakeCred() {
        if (this.amount <= 0 || isNaN(this.amount)) {
            this.cfAlertService.showMessage('Invalid Amount', true);

            this.btn_loading = false;

            return;
        }

        if (this.sol_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient SOL balance', true);

            return;
        }

        const body = {
            staking_id: this.staking_id,
            amount: this.amount,
            wallet_address: this.walletService.wallet_address
        };

        this.btn_loading = true;

        // const maxUnstakingAmount = this.calculateAvailable();

        if (this.amount > this.maxUnstakingAmount) {
            this.cfAlertService.showMessage(
                'Unstaking amount cannot be greater than maximum unlock amount',
                true
            );

            this.btn_loading = false;

            return;
        }

        this.stakingService.createUnstaking(body).subscribe(
            (res: any) => {
                this.btn_loading = false;
                this.unstaking_id = res.data.unstaking_id;
                const transaction = Transaction.from(
                    Buffer.from(res.data.transaction, 'base64')
                );

                this.sendTransaction(transaction);
            },
            (err: HttpErrorResponse) => {
                console.error('unstakeCred error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    updateCredUnstakingSignature(signature: string, error_code: number) {
        const body = {
            unstaking_id: this.unstaking_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.stakingService.updateUnstaking(body).subscribe(
            (res: any) => {
                this.btn_loading = false;

                this.cfAlertService.showMessage(
                    this.amount + ' vCRED unstaked'
                );

                this.getStakings();
                this.scrollToSection('scroll');
                this.unstakeCompleted.emit('my-unstakings');
            },
            (err: HttpErrorResponse) => {
                console.error('updateCredUnstaking error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(
                transaction
            );

            console.log('signTransaction', response);

            return response;
        }

        else if (this.walletService.current_provider === 'solflare') {
            const response = await this.solflareService.signTransaction(transaction);

            return response;
        }

        return undefined;
    }

    async sendTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature) return;

            transaction = res;

            const connection = getSolanaConnection();

            console.log('transaction', transaction);

            txnSignature = await connection.sendRawTransaction(
                transaction.serialize({
                    requireAllSignatures: true,
                    verifySignatures: true
                })
            );

            console.log('sendTransaction : txn signature => ', txnSignature);
        }
        catch (err: any) {
            console.error('sendTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split(
                'custom program error:'
            );

            if (split_items.length > 0) {
                error_code = parseInt(
                    split_items[split_items.length - 1].trim(),
                    16
                );
            }

            console.log('sendTransaction : error_code => ', error_code);
        }
        finally {
            this.updateCredUnstakingSignature(txnSignature, error_code);
        }
    }

    calculateAvailable() {
        const duration = this.stakings[this.current_staking_index].staking_duration;

        console.log('duration', duration);

        const available = this.stakings[this.current_staking_index].staked;

        console.log('available', available);

        if (duration === 0) return available;

        const current_time = new Date().getTime();

        console.log('current_time', current_time);

        const maurity_time = this.stakings[this.current_staking_index].maturity_time;

        console.log('maurity_time', maurity_time);

        const end_time = Math.min(maurity_time, current_time);

        console.log('end_time', end_time);

        const start_time = this.stakings[this.current_staking_index].last_unstaked_time;

        console.log('start_time', start_time);

        const difference_in_seconds = (end_time - start_time) / 1000;

        console.log('difference_in_seconds', difference_in_seconds);

        const difference_in_days = difference_in_seconds / 24 / 60 / 60;

        console.log('difference_in_days', difference_in_days);

        const difference_in_days_int = Math.floor(difference_in_days);

        console.log('difference_in_days_int', difference_in_days_int);

        const unstaking_per_day = available / duration;

        console.log('unstaking_per_day', unstaking_per_day);

        const unstaking = unstaking_per_day * difference_in_days_int;

        console.log('unstaking', unstaking);

        return unstaking;
    }

    connectWallet() {
        this.connectWalletDialogRef = this.dialog.open(ConnectWalletComponent, {
            width: '550px',
            height: 'auto',
            data: {}
        });

        this.connectWalletDialogRef.afterClosed().subscribe((status: string) => {
            this.ngZone.run(async () => {
                console.log('connectWallet afterClosed', status);

                if (this.walletService.isWalletConnected()) {
                    if (this.data.blockchain !== this.walletService.blockchain) {
                        console.log(this.data.blockchain, this.walletService.blockchain);
                        this.openChangeBlockchainDialog();
                    }

                    else
                        await this.openChangeChainDialog();

                    // if (this.node.blockchain === 'solana') {
                    //     this.showConfirmButton = true;
                    //     this.showAllowanceButton = false;
                    // }

                    // else {
                    //     this.showConfirmButton = false;
                    //     this.showAllowanceButton = true;
                    // }

                    console.log('showConfirmButton', this.showConfirmButton);
                    console.log('showAllowanceButton', this.showAllowanceButton);
                    console.log('differentBlockchain', this.differentBlockchain);
                    console.log('differentNetwork', this.differentNetwork);
                    console.log('walletService.current_provider', this.walletService.current_provider);

                    // const mint_address = this.currentMintAddressMap[this.payment_currency];

                    // this.getCurrencyBalance(this.data.blockchain, this.data.chain, this.data.payment_currency_mint_address);
                    this.getGasFeeBalance(this.data.blockchain, this.data.chain);
                }

                else
                    console.log('wallet not connected');
            });
        });
    }

    openChangeBlockchainDialog() {
        console.log('openChangeBlockchainDialog');

        this.differentBlockchain = true;

        this.blockchainDialogRef = this.dialog.open(ChangeBlockchainDialogComponent, {
            width: '500px',
            data: {
                expected_blockchain: 'this.node.blockchain',
                current_blockchain: this.walletService.blockchain
            }
        });

        this.blockchainDialogRef.afterClosed().subscribe(result => {
            if (result)
                console.log('result', result);
        });
    }

    async openChangeChainDialog() {
        console.log('openChangeChainDialog', this.walletService.blockchain);

        if (this.walletService.blockchain === 'solana') {
            if (this.data.chain === 'solana')
                return true;
        }

        const chainId = await this.metamaskService.getChainId();

        this.cfAlertService.showMessage(`Chain id: ${chainId}`, false);

        console.log('chainId', chainId);

        const chainIdDecimal = parseInt(chainId, 16);

        console.log('chainIdDecimal', chainIdDecimal);

        const currentChainInfo = ETHEREUM_CHAINS[chainIdDecimal];

        console.log('currentChainInfo', currentChainInfo);

        console.log(this.data.chain_id, currentChainInfo.chainId);

        if (this.data.chain_id === currentChainInfo.chainId)
            return true;

        this.differentNetwork = true;

        this.networkDialogRef = this.dialog.open(ChangeChainDialogComponent, {
            width: '500px',
            data: {
                expected_chain: this.data.chain_id,
                current_chain: currentChainInfo.chainId
            }
        });

        this.networkDialogRef.afterClosed().subscribe(result => {
            if (result)
                console.log('result', result);
        });

        return false;
    }

    getCurrencyBalance(blockchain: string, chain: string, mint_address: string) {
        if (!this.isBrowser)
            return;

        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address).subscribe((res: any) => {
            this.sol_balance = res.data;

            if (this.data.blockchain === 'solana') {
                this.showConfirmButton = true;
                this.showAllowanceButton = false;
            }

            else {
                this.showConfirmButton = false;
                this.showAllowanceButton = true;
            }
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    confirmWarning() {
        console.log('warning off');

        this.warning_screen = false;
    }

    cancelWarning() {
        this.unstakeCompleted.emit('rewards');

        console.log('warning event emitted.');
    }
}
