import { Injectable } from '@angular/core';
import {
    RECAPTCHA_SITE_KEY, RECAPTCHA_SITE_KEY_V2 
} from './app.config';

declare let grecaptcha: any;

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {
    constructor() {}

    getRecaptcha(recaptcha_purpose: string, use_manual: boolean = false): Promise<any> {
        console.log('getRecaptcha', recaptcha_purpose, use_manual);
        
        if (use_manual)
            return this.getGoogleRecaptchaManual(recaptcha_purpose);
        
        else
            return this.getGoogleBasicRecaptcha(recaptcha_purpose);
    }

    getGoogleRecaptchaManual(recaptcha_purpose: string) {
        return new Promise((resolve, reject) => {
            const verifyCallback = function(response: any) {
                console.log(response);

                resolve({
                    recaptcha: response,
                    recaptcha_purpose: recaptcha_purpose,
                    recaptcha_version: 'manual'
                });

                grecaptcha.reset(`${recaptcha_purpose}-google-recaptcha`);
            };
        
            grecaptcha.render(`${recaptcha_purpose}-google-recaptcha`, {
                sitekey: RECAPTCHA_SITE_KEY_V2,
                callback: verifyCallback,
                theme: 'dark'
            });
        });
    }

    private getGoogleEnterpriseRecaptcha(recaptcha_purpose: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                grecaptcha.enterprise.ready(async () => {
                    const recaptcha = await grecaptcha.enterprise.execute(
                        RECAPTCHA_SITE_KEY,
                        {
                            action: recaptcha_purpose
                        }
                    );

                    resolve({
                        recaptcha: recaptcha,
                        recaptcha_purpose: recaptcha_purpose,
                        recaptcha_version: 'enterprise'
                    });
                });
            }
            catch (err) {
                reject(err);
            }
        });
    }

    private getGoogleBasicRecaptcha(recaptcha_purpose: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                const recaptcha = await grecaptcha.execute(
                    RECAPTCHA_SITE_KEY,
                    {
                        action: recaptcha_purpose
                    }
                );

                resolve({
                    recaptcha: recaptcha,
                    recaptcha_purpose: recaptcha_purpose,
                    recaptcha_version: 'basic'
                });
            }
            catch (err) {
                reject(err);
            }
        });
    }
}
