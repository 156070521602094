<div class="app-my-moon-claim-history" [ngClass]="httpService.theme">
    <div class="app-my-moon-claim-history-container container-fluid">
        <div class="row mb-1" *ngIf="!loading">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <cf-dropdown [options]="statusOptions" (change)="changeStatus($event)" label="Select Status"
                    [showLogo]="false" [selected]="selected_status" [showDescription]="true">
                </cf-dropdown>
            </div>
        </div>
        <div *ngIf="!loading && moon_claims.length > 0">
            <mat-expansion-panel (afterExpand)="afterExpand(item.moon_claim_id)"
                [expanded]="opened_panel === item.moon_claim_id"
                [ngClass]="getStatusClasses(item.moon_claim_id, item.status)" *ngFor="
                            let item of moon_claims;
                            let index = index;
                            first as isFirst
                        ">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span>
                            {{ item.created | date : "mediumDate" }}
                        </span>
                        <span>
                            {{
                            item.moons
                            }}
                            MOON
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table>
                    <tbody>
                        <tr>
                            <td class="txn-heading">Payment Date</td>
                            <td>
                                {{ item.created | date : "medium" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Moons</td>
                            <td>
                                {{
                                item.moons
                                }}
                                MOON
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Blockchain Txid</td>
                            <td>
                                <div class="redirection" *ngIf="item.blockchain_txid">
                                    {{
                                    httpService.sliceAddress(
                                    item.blockchain_txid,
                                    20
                                    )
                                    }}
                                    <i class="fa-solid fa-up-right-from-square ml cursor-hover" (click)="
                                                    httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)
                                                "></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Moon Claim Id</td>
                            <td>
                                {{ item.moon_claim_id }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Status</td>
                            <td>
                                <i class="fa-regular fa-circle-check" *ngIf="item.status === 'completed'"></i>
                                <i class="fa-regular fa-circle-xmark" *ngIf="item.status === 'failed'"></i>
                                {{ item.status | titlecase }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
                (changed)="pageChanged($event)"></cf-paginator>
        </div>

        <div class="no-data" *ngIf="
                total_count === 0 &&
                !loading
            ">
            No records found
        </div>

        <div class="loader" *ngIf="loading">
            <cf-loader></cf-loader>
        </div>
    </div>
</div>