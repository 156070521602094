import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/localstorage.service';
import {
    serverUrl, userServerUrl 
} from './app.config';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    constructor(
        private http: HttpClient, 
        private localStorageService: LocalStorageService
    ) { }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    getProfilePictures() {
        const httpOptions = {
            headers: new HttpHeaders()
        };

        return this.http.get<any>(`${userServerUrl}getProfilePictures`, httpOptions);
    }

    updateProfile(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${userServerUrl}updateProfile`, body, httpOptions);
    }

    acceptTermsAndConditions() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${userServerUrl}acceptTermsAndConditions`, {}, httpOptions);
    }

    rejectTermsAndConditions() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${userServerUrl}rejectTermsAndConditions`, {}, httpOptions);
    }

    validateInviteCode(invite_code: string, purpose: string) {
        const httpOptions = {
            headers: new HttpHeaders()
        };

        return this.http.get<any>(`${userServerUrl}validateInviteCode?invite_code=${invite_code}&purpose=${purpose}`, httpOptions);
    }

    updateSocialMedia(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${userServerUrl}joinSocialMedia`, body, httpOptions);
    }

    shareOnTwitter() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}shareOnTwitter`, {}, httpOptions);
    }

    connectWalletLogin(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.post<any>(`${userServerUrl}connectWalletLogin`, body, httpOptions);
    }

    connectWalletDialog(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            }) 
        };

        return this.http.post<any>(`${userServerUrl}connectWalletDialog`, body, httpOptions);
    }

    loginWithEmail(body: object) {
        const httpOptions = {
            headers: new HttpHeaders()
        };

        return this.http.post<any>(`${userServerUrl}loginWithEmail`, body, httpOptions);
    }

    loginWithGoogle(body: object) {
        const httpOptions = {
            headers: new HttpHeaders(),
            observe: 'response' as 'response'
        };

        return this.http.post<any>(`${userServerUrl}loginWithGoogle`, body, httpOptions);
    }

    verifyLoginEmailOtp(body: object) {
        const httpOptions = {
            headers: new HttpHeaders(),
            observe: 'response' as 'response'
        };

        return this.http.post<any>(`${userServerUrl}verifyLoginEmailOtp`, body, httpOptions);
    }

    registerWithEmail(body: object) {
        const httpOptions = {
            headers: new HttpHeaders()
        };

        return this.http.post<any>(`${userServerUrl}registerWithEmail`, body, httpOptions);
    }

    registerWithGoogle(body: object) {
        const httpOptions = {
            headers: new HttpHeaders(),
            observe: 'response' as 'response'
        };

        return this.http.post<any>(`${userServerUrl}registerWithGoogle`, body, httpOptions);
    }

    verifyRegisterEmailOtp(body: object) {
        const httpOptions = {
            headers: new HttpHeaders(),
            observe: 'response' as 'response'
        };

        return this.http.post<any>(`${userServerUrl}verifyRegisterEmailOtp`, body, httpOptions);
    }

    resendEmailOtp(body: object) {
        const httpOptions = {
            headers: new HttpHeaders()
        };

        return this.http.post<any>(`${userServerUrl}resendEmailOtp`, body, httpOptions);
    }

    linkEmailWithWalletAddress(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.post<any>(`${userServerUrl}linkEmailWithWalletAddress`, body, httpOptions);
    }

    updateReferral(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${userServerUrl}` + 'updateReferral', body, httpOptions);
    }
}
