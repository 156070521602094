<div class="app-plan-details" [ngClass]="httpService.theme">
    <div class="row">
        <div class="controls">
            <cf-button [theme]="httpService.theme" type="outline" [label]="'My Nodes'"
                (clicked)="myNodes()"></cf-button>
            <cf-button [theme]="httpService.theme" type="outline" [label]="'My Network'"
                (clicked)="myNetwork()"></cf-button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <p class="node-description">CreditAI Oracle Nodes require 21 million CRED tokens per node. However, we have
                tokenized node ownership
                into three tiers—Lite, Super, and Ultra. Until TGE, the price of each tier increases by 0.25% with every
                1 million CRED tokens allocated for node sales.</p>

            <p class="node-description">Node holders earn rewards from a fee pool, which consists of 50% of the
                platform’s collected fees and 20%
                of the network joining fee whenever a new node holder joins. The CreditAI Oracle Nodes store, maintain
                and relay the credit rating data and reports with Dapps and fintechs.</p>
        </div>
    </div>

    <div class="node-sale-stats">
        <div class="node-sale-stat">
            <div class="sub-heading">${{ current_rate * total_supply | number:'0.0-0' }}
                (<span style="color: #cf0;">+{{(current_rate - base_rate) / base_rate | percent:'0.0-2' }}</span>)
            </div>
            <div class="heading">
                Implied FDV
                <img matTooltip="Notional FDV where the price increases by 0.25% on node sale worth 1000,000 CRED"
                    class="tooltip-img" src="../../assets/images/tooltip.png" />
            </div>
        </div>
        <div class="node-sale-stat">
            <div class="sub-heading">{{ network_details.total_network_size }}</div>
            <div class="heading">
                Network size
                <img matTooltip="Total number of active node licenses on the network" class="tooltip-img"
                    src="../../assets/images/tooltip.png" />
            </div>
        </div>
        <div class="node-sale-stat">
            <div class="sub-heading">{{ network_details.my_network_size }}</div>
            <div class="heading">
                My Network size
                <img matTooltip="Total number of active node licenses up to five oracle level affiliated to you"
                    class="tooltip-img" src="../../assets/images/tooltip.png" />
            </div>
        </div>
        <div class="node-sale-stat">
            <div class="sub-heading">{{ network_details.total_network_weight | number:'0.0-0' }} vCRED</div>
            <div class="heading">
                Network weight
                <img matTooltip="Total number of CRED staked on nodes the entire Credit AI oracle node network"
                    class="tooltip-img" src="../../assets/images/tooltip.png" />
            </div>
        </div>
        <div class="node-sale-stat">
            <div class="sub-heading">{{ network_details.my_network_weight | number:'0.0-0' }} vCRED</div>
            <div class="heading">
                My Network weight
                <img matTooltip="Total number of staked CRED on nodes up to five oracle level affiliated to you"
                    class="tooltip-img" src="../../assets/images/tooltip.png" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="left col-md-7">
            <cf-card [theme]="httpService.theme" type="empty" *ngIf="chart_loaded">
                <cf-tabs [theme]="httpService.theme" [tabs]="chartTabs" [currentTab]="currentChartTab"
                    (changed)="changeChartTab($event)"></cf-tabs>

                <div class="tabs">
                    <div class="chart" *ngIf="currentChartTab === 'unlock-schedule'">
                        <app-line-chart [height]="550" [lineConfig]="chartLineConfig" title=""
                            [currency]="'vCRED'"></app-line-chart>
                    </div>
                    <div class="chart" *ngIf="currentChartTab === 'implied-fdv'">
                        <app-line-chart [height]="550" [lineConfig]="chartCredLineConfig" title=""
                            [currency]="'vCRED'"></app-line-chart>
                    </div>
                </div>
            </cf-card>
        </div>
        <div class="right col-md-5">
            <app-new-node (changePlanId)="changePlanIdHandler($event)" [nodes]="nodes"></app-new-node>
        </div>
    </div>
    <div class="bottom" *ngIf="plan_id !== ''">
        <cf-card [theme]="httpService.theme" type="empty" class="mt-6">
            <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentTab]="currentTab"
                (changed)="changeTab($event)"></cf-tabs>

            <div class="tabs">
                <app-plan-unlock-schedule *ngIf="currentTab === 'vesting-schedule'"
                    [plan_id]="plan_id"></app-plan-unlock-schedule>
                <app-plan-bonus-schedule *ngIf="currentTab === 'bonus-schedule'"
                    [plan_id]="plan_id"></app-plan-bonus-schedule>
            </div>
        </cf-card>
    </div>
    <app-frequently-asked-questions></app-frequently-asked-questions>
</div>