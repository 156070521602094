<div class="app-referral-reward-withdrawal" [ngClass]="httpService.theme">
    <div class="modal-backdrop" *ngIf="showModal">
        <div class="modal-container" *ngIf="showModal">
            <div class="referral-container">
                <div *ngIf="
            !loading">
                    <div class="heading-container">
                        <span class="title">Invite Code</span>
                        <i class="fa-solid fa-xmark ml icon" (click)="closeModal()" style="color: white;"></i>
                    </div>

                    <div class="row mt-2">
                        <div class="code-input">
                            <cf-input label="Invitation Code" type="text" [showLabel]="true" [value]="invite_code"
                                (valueChanged)="onChangeCode($event)" placeholder="Enter Invitation Code"
                                [theme]="httpService.theme"></cf-input>
                        </div>
                    </div>

                    <div class="actions">
                        <div *ngIf="!btn_loading">
                            <cf-button label="Add Code" type="solid" (clicked)="addInviteCode()"></cf-button>
                        </div>
                        <div *ngIf="btn_loading">
                            <cf-loader></cf-loader>
                        </div>
                    </div>
                </div>
                <div class="loader" *ngIf="loading">
                    <cf-loader></cf-loader>
                </div>
                <!-- <div *ngIf="show_no_purchases" class="no-data">
                    <div>No purchases</div>
                </div> -->
            </div>
        </div>
    </div>