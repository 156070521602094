<div class="app-network container-fluid">
    <!-- <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentOption]="currentOption"
        (changed)="changeTab($event)"></cf-tabs> -->
    <div class="controls">
        <div class="row">
            <div class="col-md-4 col-sm-12 col-12"></div>
            <div class="col-md-4 col-sm-12 col-12"></div>
            <div class="col-md-4 col-sm-12 col-12">
                <cf-dropdown [theme]="httpService.theme" [options]="options" [selected]="currentOption"
                    (change)="changeTab($event)" label="Network type" [showLogo]="false"></cf-dropdown>
            </div>
        </div>
    </div>

    <div class="tabs">
        <app-genesis-members *ngIf="currentOption === 'members'"></app-genesis-members>
        <app-my-referral-rewards *ngIf="currentOption === 'networking-rewards'"></app-my-referral-rewards>
        <app-rewards-claim-history *ngIf="currentOption === 'rewards-claim-history'"></app-rewards-claim-history>
        <app-onboarding *ngIf="currentOption === 'onboarding'"></app-onboarding>
        <app-leaderboard *ngIf="currentOption === 'leaderboard'"></app-leaderboard>
        <app-liquidity *ngIf="currentOption === 'liquidity'"></app-liquidity>
        <app-genesis-moons *ngIf="currentOption === 'moons'"></app-genesis-moons>
    </div>
</div>