const serverUrl = 'https://api.credible.finance/';
const genesisServerUrl = 'https://genesisapi.credible.finance/';
const socketUrl = 'https://sockets.credible.finance/';
const RECAPTCHA_SITE_KEY = '6LcUH1EpAAAAAHOzn2bNsQu-gVu6e8a4HnmdEa2J';
const RECAPTCHA_SITE_KEY_V2 = '6LcaG9cqAAAAACU4_cespPaievTSKgnsd_V6WHwg';
const currencyConversionMapUrl = 'https://conversion.seracle.com/getCurrencyConversionMap';
const userServerUrl = 'https://userapi.credible.finance/';
const SOLANA_ENVIRONMENT = 'MAINNETBETA';
const PLUME_ENVIRONMENT = 'PLUMEMAINNET';
const showConsoleLogs = false;
export {
    showConsoleLogs,
    serverUrl,
    socketUrl,
    genesisServerUrl,
    RECAPTCHA_SITE_KEY,
    RECAPTCHA_SITE_KEY_V2,
    currencyConversionMapUrl,
    PLUME_ENVIRONMENT,
    SOLANA_ENVIRONMENT,
    userServerUrl
};
