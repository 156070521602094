import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, NgZone, OnInit, PLATFORM_ID
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import {Transaction} from '@solana/web3.js';
import { HttpService } from '../../services/http.service';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import { MetamaskService } from '../metamask.service';
import { Router } from '@angular/router';
import { nullChecker } from '../../helpers/nullChecker';
import IPayFiPool from '../../interfaces/IPayFiPool';
import PayFiPool from '../../models/PayFiPool';
import { PayFiService } from '../../services/payfi.service';
import {
    DecimalPipe, isPlatformBrowser
} from '@angular/common';
import ICheckbox from '@crediblefinance/credible-ui/lib/cf-checkbox-large/ICheckbox';
import { KycService } from '../../services/kyc.service';
import { getWindow } from 'ssr-window';

import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import {MatDialog} from '@angular/material/dialog';
import getSolanaRawTransaction from '../../helpers/getSolanaRawTransaction';
import { SolflareService } from '../../services/solflare.service';

@Component({
    selector: 'app-new-payfi-collateral',
    templateUrl: './new-payfi-collateral.component.html',
    styleUrl: './new-payfi-collateral.component.scss'
})
export class NewPayFiCollateralComponent implements OnInit {
    platformId: object = {};
    isBrowser: boolean = false;

    collateral_amount_usd: number = 0;
    collateral_amount: number = 0;
    collateral_currency_precision: string = '0.0-2';
    collateralCurrencyDropdownOptions: Array<IDropdown> = [];
    collateral_currency: string = '';

    currentMintAddressMap: any = {};

    fee: number = 0;

    conversion_rate: number = 0;

    loading: boolean = true;
    new_collateral_loading: boolean = false;
    transaction: any;

    collateral_id: string = '';
    confirm_btn_label: string = 'Confirm';

    pools: Array<IPayFiPool> = [];
    poolDropdownOptions: Array<IDropdown> = [];

    userCollateralInfo: any = {};
    balance: number = 0;
    differentBlockchain: boolean = false;
    differentNetwork: boolean = false;

    payment_method_options: Array<IDropdown> = [];
    collateral_options: Array<IDropdown> = [];
    collateral_type_options: Array<ICheckbox> = [{
        label: 'Add collateral',
        value: 'add',
        optionEnabled: true
    },
    {
        label: 'Remove collateral',
        value: 'remove',
        optionEnabled: true
    }];
    selected_collateral_type: string = this.collateral_type_options[0].value;

    selected_pool: IPayFiPool = new PayFiPool();

    setIntervalId: any = null;
    timer: number = 0;
    initial_timer: number = 45;

    window: Window = getWindow();
    constructor(
        private kycService:KycService,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private decimalPipe: DecimalPipe,
        public walletService: WalletService,
        private metamaskService: MetamaskService,
        private payFiService: PayFiService,
        private router: Router,
        public dialog: MatDialog,
        private ngZone: NgZone,
        @Inject(PLATFORM_ID) platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        console.log('new-payfi-collateral.component.ts ngOnInit()');

        this.getPools();

        this.getUserTotalCollateral();
    }

    getPools() {
        this.payFiService.getPools().subscribe(res => {
            this.pools = res.data;

            this.getCollateralCurrencies();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    getCurrencyBalance(blockchain: string, chain: string, wallet_address: string, mint_address: string) {
        if (nullChecker(this.walletService.wallet_address) || !this.walletService.isWalletConnected())
            return;

        if (!this.isBrowser || this.differentBlockchain || this.differentNetwork)
            return;

        this.httpService.getCurrencyBalance(blockchain, chain, wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getUserTotalCollateral() {
        this.payFiService.getUserTotalCollateral().subscribe((res: any) => {
            this.userCollateralInfo = res.data;
        }, (err: HttpErrorResponse) => {
            console.error('getUserTotalCollateral error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    setConfirmBtnLabel() {
        let label = 'Add Collateral ';

        if (this.selected_collateral_type === 'remove')
            label = 'Remove Collateral ';

        this.confirm_btn_label = label + this.decimalPipe.transform(this.collateral_amount, this.collateral_currency_precision) + ' ' + this.collateral_currency.toUpperCase();
    }

    loanAmountChanged(amount: number) {
        if (!amount)
            amount = 0;

        this.collateral_amount = parseFloat(amount.toString());

        console.log('this.collateral_currency', this.collateral_currency);

        this.conversion_rate = this.httpService.currencyConversionMap[this.collateral_currency].usd;

        this.collateral_amount_usd = this.collateral_amount * this.httpService.currencyConversionMap[this.collateral_currency].usd;

        this.setConfirmBtnLabel();
    }

    changeCollateralCurrency(option: IDropdown) {
        this.collateral_currency = option.value;
        this.conversion_rate = this.httpService.currencyConversionMap[this.collateral_currency].usd;

        this.collateral_amount = this.collateral_amount / (this.selected_pool.approved_ltv_percentage / 100);
        this.collateral_amount_usd = this.collateral_amount * this.httpService.currencyConversionMap[this.collateral_currency].usd;

        this.setConfirmBtnLabel();

        console.log('selected_collateral_id', this.collateral_currency);
    }

    createCollateral() {
        if (nullChecker(this.collateral_amount)) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (nullChecker(this.collateral_currency)) {
            this.cfAlertService.showMessage('Please select collateral currency', true);

            return;
        }

        if (nullChecker(this.collateral_currency)) {
            this.cfAlertService.showMessage('Please select collateral currency', true);

            return;
        }

        if (this.collateral_amount < this.selected_pool.min_borrow_amount) {
            this.cfAlertService.showMessage(`Minimum borrow amount is ${this.selected_pool.min_borrow_amount.toFixed(2)} ${this.collateral_currency.toUpperCase()}`, true);

            return;
        }

        if (this.collateral_amount > this.balance) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        this.new_collateral_loading = true;

        const body = {
            collateral_amount: this.collateral_amount,
            collateral_currency: this.collateral_currency,
            pool_id: this.selected_pool.pool_id,
            addition: true
        };

        if (this.selected_collateral_type === 'remove')
            body.addition = false;

        this.payFiService.newCollateral(body).subscribe((res: any) => {
            this.collateral_id = res.data.collateral_id;

            if ('transaction' in res.data) {
                const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));

                this.sendSolanaTransaction(transaction);
            }
            else if (res.data.abi) {
                const abi = res.data.abi;
                const gas = res.data.gas;
                const contract_address = res.data.contract_address;

                console.log('withdrawal', abi, contract_address, gas);

                this.sendEthereumTransaction(abi, contract_address, gas);
            }
        }, (err: HttpErrorResponse) => {
            console.error('createCollateral error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_collateral_loading = false;
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        else if (this.walletService.current_provider === 'solflare') {
            const response = await this.solflareService.signTransaction(transaction);
        
            return response;
        }

        return undefined;
    }

    updateSignature(signature: string, error_code: number) {
        const body = {
            collateral_id: this.collateral_id,
            blockchain_txid: signature,
            error_code: error_code,
            collateral_type: this.selected_collateral_type
        };

        this.payFiService.updateCollateral(body).subscribe((res: any) => {
            this.new_collateral_loading = false;

            this.cfAlertService.showMessage(res.message);

            this.router.navigateByUrl('/transactions?tab=my-payfi-collaterals');
        }, (err: HttpErrorResponse) => {
            console.error('updateSignature error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_collateral_loading = false;
        });
    }

    async sendSolanaTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res getSignature', res);

            if (!res || !res.signature)
                return;

            transaction = res;

            console.log('transaction', transaction);

            const txnSignature = await getSolanaRawTransaction(transaction);

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature, -1);
        }

        catch (err) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            this.cfAlertService.showError(new HttpErrorResponse({
                error: err
            }));

            this.new_collateral_loading = false;
        }
    }

    async sendEthereumTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('sendEthereumTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    async getEthereumSignature(abi: string, contract_address: string, gas: number) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(abi, contract_address, gas);

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    getCollateralCurrencies() {
        this.payFiService.getCollateralCurrencies().subscribe((res: any) => {
            const currencies = res.data;

            const options: Array<IDropdown> = [];

            for (let i = 0; i < currencies.length; i++) {
                options.push({
                    label: currencies[i].label,
                    value: currencies[i].key,
                    logo: this.httpService.getCurrencyUrl(currencies[i].key),
                    optionEnabled: true
                });
            }

            this.collateral_options = options;
            this.collateral_currency = this.collateral_options[0].value;

            for (let i = 0; i < this.pools.length; i++) {
                if (this.pools[i].drawdown_currency === this.collateral_currency) {
                    this.selected_pool = this.pools[i];

                    break;
                }
            }

            this.getCurrencyBalance(this.selected_pool.blockchain, this.selected_pool.chain, this.walletService.wallet_address, this.selected_pool.drawdown_currency_mint_address);

            this.loanAmountChanged(this.selected_pool.min_borrow_amount);

            if (this.isBrowser)
                this.startTimer();

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error('getCollateralCurrencies error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    startTimer() {
        this.timer = this.initial_timer;

        this.setIntervalId = setInterval(() => {
            this.timer = this.timer - 0.1;

            if (this.timer <= 0)
                this.timer = this.initial_timer;
        }, 100);
    }

    changeCollateralType(option: ICheckbox) {
        console.log('changeCollateralType', option);

        this.selected_collateral_type = option.value;

        this.setConfirmBtnLabel();
    }
    generateKycUrl() {
        this.loading = true;

        this.kycService.generateKycUrl(this.httpService.user).subscribe(
            (res: any) => {
                this.loading = false;

                this.window.location.href = res.data;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    connectWallet() {
        this.ngZone.run(() => {
            this.dialog.open(ConnectWalletComponent, {
                width: '550px',
                height: 'auto',
                data: {}
            });
        });
    }
}