import {
    Component, EventEmitter, Input, OnInit,
    Output
} from '@angular/core';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-purchase-node-widget',
    templateUrl: './purchase-node-widget.component.html',
    styleUrl: './purchase-node-widget.component.scss'
})
export class PurchaseNodeWidgetComponent implements OnInit {
    currentTab: any = 'rewards';
    @Input() data: any;
    @Output() childEvent = new EventEmitter<string>();

    theme: any;

    constructor(public httpService: HttpService) { }

    ngOnInit(): void {
        this.theme = this.httpService.theme;
    }

    changedTab(tab: any) {
        this.currentTab = tab;
    }

    passDataToParent(data: string) {
        if (data === 'rewards')
            this.currentTab = 'rewards';
        else
            this.childEvent.emit(data);
    }
}
