import {
    AfterViewChecked,
    Component, OnInit,
    Output, EventEmitter,
    Input
} from '@angular/core';
import {
    NavigationEnd, Router
} from '@angular/router';
import { HttpService } from '../../services/http.service';

import { LocalStorageService } from '../../services/localstorage.service';
import { getWindow } from 'ssr-window';
import ISideBarMenu from '../../interfaces/ISideBarMenu';
import ISideBarSubMenu from '../../interfaces/ISideBarSubMenu';
import { filter } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, AfterViewChecked {
    environmentToggle: boolean = false;
    currentPath: string = '';
    selectedMenu!: ISideBarMenu;
    selectedSubMenu!: ISideBarSubMenu;

    sideBarMenus: ISideBarMenu[] = [];
    @Output() collapsed = new EventEmitter();
    @Input() currentCollapseStatus: boolean = false;
    tvl_usd = 0;
    window = getWindow();

    newMenus: Array<ISideBarMenu> = [
        {
            id: 'airdrop',
            name: 'Airdrop',
            iconClass: 'fa-brands fa-space-awesome',
            themeIconUrl: 'https://assets.credible.finance/Airdrop11.gif',
            blackIconUrl: 'https://assets.credible.finance/Airdrop11.gif',
            url: '/airdrop',
            isExpandMenu: false,
            subMenus: Array<ISideBarSubMenu>(),
            divider: false
        },
        {
            id: 'payfi',
            name: 'Cash Loan',
            iconClass: 'fa-solid fa-house-chimney',
            themeIconUrl: 'https://assets.credible.finance/fund.png',
            blackIconUrl: 'https://assets.credible.finance/fund-1.png',
            url: '/cash-loan',
            isExpandMenu: false,
            subMenus: Array<ISideBarSubMenu>(),
            divider: false
        },
        {
            id: 'liquidity',
            name: 'Private Credit',
            iconClass: 'fa-solid fa-house-chimney',
            themeIconUrl: 'https://assets.credible.finance/Lend-1.png',
            blackIconUrl: 'https://assets.credible.finance/Lend-2.png',
            url: '/liquidity',
            isExpandMenu: false,
            subMenus: Array<ISideBarSubMenu>(),
            divider: false
        },
        // {
        //     id: 'node-dashboard',
        //     name: 'Nodes',
        //     iconClass: 'fa-solid fa-house-chimney',
        //     themeIconUrl: 'https://assets.credible.finance/Nodes-1.png',
        //     blackIconUrl: 'https://assets.credible.finance/Nodes.png',
        //     url: '/nodes',
        //     isExpandMenu: false,
        //     subMenus: Array<ISideBarSubMenu>(),
        //     divider: false
        // },
        // {
        //     id: 'node-sale',
        //     name: 'Node Sale',
        //     iconClass: 'fa-solid fa-house-chimney',
        //     themeIconUrl: 'https://assets.credible.finance/Lend-1.png',
        //     blackIconUrl: 'https://assets.credible.finance/Lend-2.png',
        //     url: '/node-sale',
        //     isExpandMenu: false,
        //     subMenus: Array<ISideBarSubMenu>(),
        //     divider: false
        // },
        // {
        //     id: 'borrow',
        //     name: 'Tokenize',
        //     iconClass: 'fa-solid fa-house-chimney',
        //     themeIconUrl: 'https://assets.credible.finance/Borrow-3.png',
        //     blackIconUrl: 'https://assets.credible.finance/Borrow-4.png',
        //     url: '/tokenize',
        //     isExpandMenu: false,
        //     subMenus: Array<ISideBarSubMenu>(),
        //     divider: false
        // },
        {
            id: 'transactions',
            name: 'Transactions',
            iconClass: 'fa-solid fa-house-chimney',
            themeIconUrl: 'https://assets.credible.finance/Transaction-1.png',
            blackIconUrl: 'https://assets.credible.finance/Transaction.png',
            url: '/transactions',
            isExpandMenu: false,
            subMenus: Array<ISideBarSubMenu>(),
            divider: false
        },
        {
            id: 'referrals',
            name: 'Referrals',
            iconClass: 'fa-solid fa-cloud-bolt',
            themeIconUrl: 'https://assets.credible.finance/customer2.png',
            blackIconUrl: 'https://assets.credible.finance/customer1.png',
            url: '/referrals',
            isExpandMenu: true,
            subMenus: [],
            divider: false
        },
        {
            id: 'profile',
            name: 'Account',
            iconClass: 'fa-solid fa-cloud-bolt',
            themeIconUrl: 'https://assets.credible.finance/Profile91.png',
            blackIconUrl: 'https://assets.credible.finance/Profile22.png',
            url: '/account',
            isExpandMenu: true,
            subMenus: Array<ISideBarSubMenu>(),
            divider: false
        }
        // {
        //     id: 'points',
        //     name: 'Airdrop',
        //     iconClass: 'fa-solid fa-cloud-bolt',
        //     themeIconUrl: 'https://assets.credible.finance/Loyalty-1.png',
        //     blackIconUrl: 'https://assets.credible.finance/Loyalty-default.png',
        //     url: '/points',
        //     isExpandMenu: true,
        //     subMenus: Array<ISideBarSubMenu>(),
        //     divider: false
        // }
        // {
        //     id: 'airdrop',
        //     name: 'Airdrop',
        //     iconClass: 'fa-solid fa-cloud-bolt',
        //     themeIconUrl: 'https://assets.credible.finance/Loyalty-1.png',
        //     blackIconUrl: 'https://assets.credible.finance/Loyalty-default.png',
        //     url: '/airdrop',
        //     isExpandMenu: true,
        //     subMenus: Array<ISideBarSubMenu>(),
        //     divider: false
        // },
    ];

    constructor(
        private router: Router,
        public httpService: HttpService,
        private localStorageService: LocalStorageService,
        private cfAlertService: CfAlertService) {
    }

    ngOnInit() {
        console.log('node_sale_enabled', !!this.httpService.user.node_sale_enabled);

        this.newMenus = this.newMenus.filter(menu => {
            if (menu.id === 'node-dashboard') {
                if (this.httpService.user.node_sale_enabled)
                    return true;

                if (this.httpService.user.referred_by === 418)
                    return true;

                return false;
            }

            else
                return true;
        });

        if (this.httpService.user.show_funds) {
            this.newMenus.push({
                id: 'funds',
                name: 'Funds',
                iconClass: 'fa-solid fa-cloud-bolt',
                themeIconUrl: 'https://assets.credible.finance/customer2.png',
                blackIconUrl: 'https://assets.credible.finance/customer1.png',
                url: '/funds',
                isExpandMenu: true,
                subMenus: [],
                divider: false
            });
        }

        this.getHomepageStats();

        this.subscribeRouteChanges();

        this.selectedMenu = this.newMenus[0];

        this.currentPath = '/' + this.router.url.split('/')[1].split('?')[0];

        this.newMenus.forEach((menu: ISideBarMenu) => {
            if (menu.url !== '' && this.currentPath === menu.url) {
                this.selectedMenu = menu;
                this.selectedMenu.isExpandMenu = true;
            }

            else if (this.currentPath === menu.url)
                menu.isExpandMenu = true;

            else if (menu.subMenus) {
                menu.subMenus?.forEach((subMenu: ISideBarSubMenu) => {
                    if (this.currentPath === menu.url || this.window.location.pathname === subMenu.url) {
                        this.selectedMenu = menu;
                        this.selectedMenu.isExpandMenu = true;
                        this.selectedSubMenu = subMenu;
                    }
                });
            }
        });
    }

    ngAfterViewChecked(): void {
        this.currentPath = '/' + this.router.url.split('/')[1];
    }

    subscribeRouteChanges() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            console.log('subscribeRouteChanges', event);

            this.currentPath = '/' + this.router.url.split('/')[1].split('?')[0];

            this.newMenus.map((menu: ISideBarMenu) => {
                if ((menu.url !== '') && (this.currentPath === menu.url)) {
                    console.log('menu', menu);

                    this.selectedMenu = menu;
                    this.selectedMenu.isExpandMenu = true;
                }
                else if (menu.subMenus) {
                    menu.subMenus?.forEach((subMenu: ISideBarSubMenu) => {
                        if (menu.id === 'profile' && subMenu.id === 'kyc-kyb') {
                            if (this.httpService.user.account_type === 'individual') {
                                subMenu.name = 'KYC';
                                subMenu.url = '/kyc';
                            }
                            else if (this.httpService.user.account_type === 'business') {
                                subMenu.name = 'KYB';
                                subMenu.url = '/kyb';
                            }
                            else {
                                subMenu.name = 'KYC/KYB';
                                subMenu.url = '/identity-verification';
                            }
                        }

                        if (this.window.location.pathname === menu.url || this.window.location.pathname === subMenu.url) {
                            this.selectedMenu = menu;
                            this.selectedMenu.isExpandMenu = true;
                        }
                    });
                }
            });
        });
    }

    changeModule(menu: ISideBarMenu) {
        this.selectedMenu = menu;
        this.selectedMenu === menu ? this.selectedMenu.isExpandMenu = !this.selectedMenu.isExpandMenu : this.selectedMenu.isExpandMenu = true;

        this.router.navigate([this.selectedMenu.url]);
    }

    changeSubMenu(subMenu: ISideBarSubMenu) {
        const menu: ISideBarMenu = this.newMenus.find((menu: ISideBarMenu) => {
            return menu.subMenus?.some((item) => {
                return subMenu === item;
            });
        }) || this.newMenus[0];

        this.selectedSubMenu = subMenu;
    }

    getIcon(selectedMenu: any, menu: any) {
        return selectedMenu.id === menu.id ? menu.themeIconUrl : menu.blackIconUrl;
    }

    collapseSidebar() {
        this.currentCollapseStatus = !this.currentCollapseStatus;

        this.collapsed.emit(this.currentCollapseStatus);
    }

    getHomepageStats() {
        this.httpService.getHomepageStats().subscribe(res => {
            this.tvl_usd = res.data.tvl_usd;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changeTheme(theme: string): void {
        this.httpService.theme = theme;
        this.httpService.themeChanged.next(theme);

        this.localStorageService.setItem('theme', theme);
    }
}