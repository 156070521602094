<div class="app-register-steps">
    <div class="temp">
        <div class="left-section">
            <div class="logo" [routerLink]="'/'">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" routerLink="/" />
            </div>
        </div>
        <div class="center-section">
            <div class="row gx-0">
                <div class="col-md-12">
                    <span class="heading">Register</span>
                </div>
            </div>
            <div class="row gx-0">
                <div class="col-md-12">
                    <div class="sub-heading">Already a user ?
                        <a href="#" (click)="login()">Login</a>
                    </div>
                </div>
            </div>

            <div class="sidebar-and-steps">
                <div class="steps-web">
                    <div class="steps-content">
                        <div *ngIf="steps[currentStep]?.step === 'email-verification'" class="step-content">
                            <app-email-verification (completed)="notifyEmailVerification($event)"
                                [invite_code]="invite_code"></app-email-verification>
                        </div>

                        <div *ngIf="steps[currentStep]?.step === 'invite-code'" class="step-content">
                            <app-invite-code (completed)="notifyInviteCode()"></app-invite-code>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>