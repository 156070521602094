<div class="app-claim-moon-dialog" [ngClass]="httpService.theme">
    <div class="modal-backdrop">
        <div class="modal-container">
            <div class="container">
                <div *ngIf="
            !loading &&
            httpService.user.user_id > 0 &&
            !show_no_purchases
        ">
                    <div class="heading-container">
                        <span class="title">Claim Moon</span>
                        <i class="fa-solid fa-xmark ml icon" (click)="closeDialog()" style="color: white;"></i>
                    </div>

                    <div class="row mt-2 moon-info">
                        <cf-input-currency-dropdown [theme]="httpService.theme" [options]="dropdownOptions"
                            [value]="moon" [class]="'input'" label="Amount" [selected]="'moon'" type="number"
                            (valueChanged)="moonsChanged($event)" [precision]="0">
                        </cf-input-currency-dropdown>
                        <div class="equivalent-amount mt-1">
                            Available:
                            {{ available_moon }} moon
                        </div>
                    </div>
                    <div class="actions">
                        <div *ngIf="!btn_loading">
                            <div *ngIf="differentBlockchain">
                                <cf-button label="Switch Blockchain" type="solid"
                                    (clicked)="openChangeBlockchainDialog()"></cf-button>
                            </div>

                            <div class="action" *ngIf="!differentBlockchain && walletService.wallet_address !== ''">
                                <cf-button label="Claim" type="solid" (clicked)="claimMoon()" *ngIf="showConfirmButton"
                                    [loading]="btn_loading"></cf-button>

                                <cf-button label="Switch Network" type="solid" (clicked)="openChangeChainDialog()"
                                    *ngIf="differentNetwork"></cf-button>
                            </div>
                            <div class="action" *ngIf="walletService.wallet_address === ''">
                                <cf-button label="Connect Wallet" type="solid" (clicked)="connectWallet()"></cf-button>
                            </div>
                        </div>
                        <div *ngIf="btn_loading">
                            <cf-loader></cf-loader>
                        </div>
                    </div>
                </div>
                <div class="loader" *ngIf="loading">
                    <cf-loader></cf-loader>
                </div>
                <div *ngIf="show_no_purchases" class="no-data">
                    <div>No purchases</div>
                </div>
            </div>
        </div>
    </div>