/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';

@Component({
    selector: 'app-decentralized-finance-terms-conditions',
    templateUrl: './decentralized-finance-terms-conditions.component.html',
    styleUrl: './decentralized-finance-terms-conditions.component.scss'
})
export class DecentralizedFinanceTermsConditionsComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}
