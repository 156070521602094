<div class="privacy-policy-container">

    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="privacy-content">

            <h1 class="heading">Privacy Policy</h1>
            <br>
            <br>
            <h5>Last Updated: 20/01/2025
            </h5>
            <br>
            <div class="paragraph">
                <h2>1. Introduction</h2>
                <p>Kiwimoney Inc. DBA Credible ("we," "our," or "us") values your privacy and is committed to protecting
                    your personal information. This Privacy Policy outlines how we collect, use, store, disclose, and
                    protect your personal data when you use our platform. As a non-custodial digital asset management
                    platform, we ensure that users retain full control over their funds, meaning that we do not hold,
                    manage, or access user assets directly. However, certain financial and custodial services are
                    provided by third-party partners, including Circle for asset custody and Bridge for IBAN services.
                    These partners operate under their own legal and regulatory frameworks, and their respective privacy
                    policies apply to the services they provide.</p>
                <p>By accessing or using our services, you acknowledge that you have read, understood, and agreed to the
                    practices described in this Privacy Policy. If you do not agree with any part of this policy, you
                    must discontinue use of our services immediately. We reserve the right to update or modify this
                    Privacy Policy at any time to reflect changes in our practices, technology, or legal obligations.
                    Any updates will be communicated through our website or via email notifications, and continued use
                    of the platform following such updates will constitute acceptance of the revised policy.</p>

                <h2>2. Scope of this Privacy Policy</h2>
                <p>This Privacy Policy applies to all users who interact with the Credible platform, including those who
                    register for an account, conduct transactions, use our services, or communicate with our support
                    team. It governs how we handle data collected from our website, mobile applications, APIs, and
                    third-party integrations. Additionally, this policy extends to any third-party service providers
                    that process personal data on our behalf under contractual agreements.</p>
                <p>This Privacy Policy does not apply to services offered by third-party websites, applications, or
                    companies that may be linked to our platform. We encourage users to review the privacy policies of
                    these external entities before engaging with their services, as we are not responsible for their
                    data processing activities.</p>

                <h2>3. Information We Collect</h2>
                <h3>3.1 Personal Data You Provide to Us</h3>
                <p>When you use our platform, we may collect personal data that is necessary for account creation,
                    regulatory compliance, security, and transaction processing. Account registration data includes your
                    full name, email address, phone number, username, and password, which are used to verify your
                    identity and provide you with a secure account. Additionally, to comply with KYC (Know Your
                    Customer) and AML (Anti-Money Laundering) regulations, we may require further identity verification,
                    including a government-issued identification document (such as a passport, driver's license, or
                    national ID), proof of address (such as a recent utility bill or bank statement), and a biometric
                    selfie for authentication.</p>
                <p>For users who conduct transactions on our platform, we collect financial and transaction data,
                    including details of crypto and fiat transactions, linked IBAN numbers, external wallet addresses,
                    payment method information, and transaction timestamps. This data helps us process transactions
                    efficiently, prevent fraudulent activity, and comply with financial regulations.</p>

                <h3>3.2 Data Automatically Collected</h3>
                <p>In addition to the personal data you provide, we automatically collect certain information when you
                    use our platform. This includes device and technical data, such as your device type, operating
                    system, browser version, IP address, geolocation, unique device identifiers (UDID, IMEI), and
                    network connection details. This data is essential for optimizing platform performance, detecting
                    unauthorized access, and maintaining security standards.</p>
                <p>Furthermore, we collect usage and security data to monitor platform interactions. This includes login
                    attempts, authentication logs, pages visited, session duration, error reports, and user interactions
                    with features. Collecting this data enables us to improve the user experience, troubleshoot issues,
                    and safeguard accounts from potential security threats.</p>

                <h3>3.3 Data from Third-Party Sources</h3>
                <p>We may receive additional information about users from third-party sources, including identity
                    verification services, banking and payment providers, regulatory bodies, and analytics platforms.
                    These external data sources help us ensure compliance with financial regulations, prevent fraudulent
                    activities, and analyze service performance. For example, if a user attempts to link an IBAN or
                    conduct a large transaction, we may verify account details with our banking partners to prevent
                    unauthorized activity. Additionally, our analytics providers help us assess user engagement and
                    improve our services accordingly.</p>

                <h2>4. Legal Basis for Processing Personal Data</h2>
                <p>Credible processes personal data in compliance with applicable data protection laws, ensuring that
                    our practices align with legal, contractual, and operational requirements. The primary legal grounds
                    for data processing include contractual necessity, legal obligations, legitimate interests, and user
                    consent.</p>
                <p>When you create an account and use our platform, we process your personal data as part of our
                    contractual obligations to provide our services, including account management, transaction
                    facilitation, and security measures. Additionally, we collect and store personal data to comply with
                    legal obligations, such as AML, KYC, counter-terrorism financing, and other financial regulations
                    mandated by relevant authorities.</p>
                <p>Certain processing activities are carried out based on our legitimate interests, such as fraud
                    prevention, security enhancement, and platform optimization. These activities are essential to
                    maintaining a safe and reliable service. However, in cases where processing is based on user
                    consent, such as for marketing communications and optional data collection, users have the right to
                    withdraw consent at any time.</p>

                <h2>5. How We Use Your Information</h2>
                <p>We process user data for several purposes, including service delivery, security, compliance, and
                    platform enhancements.</p>

                <h3>5.1 Service-Related Processing</h3>
                <p>Your personal data is used to create and manage user accounts, authenticate logins, facilitate
                    transactions, and enable IBAN-linked banking services. This ensures that you can use the platform
                    securely and efficiently.</p>

                <h3>5.2 Compliance & Risk Management</h3>
                <p>We process user data to detect fraudulent activities, monitor transactions, and ensure compliance
                    with financial laws. Our risk assessment protocols allow us to flag suspicious transactions and take
                    necessary actions to protect users and the platform.</p>

                <h3>5.3 User Experience Enhancement</h3>
                <p>Data is used to troubleshoot issues, analyze user engagement, and develop new features. This helps us
                    continuously improve our services based on user needs.</p>

                <h3>5.4 Marketing & Communication</h3>
                <p>With user consent, we may send promotional content, service updates, and personalized
                    recommendations. Users can opt out of marketing communications at any time by updating their
                    preferences.</p>

                <h2>6. Custodial Services by Circle</h2>
                <p>While Credible is a non-custodial platform, certain services may be provided by Circle, our partner
                    for custodial services. When you use features of the platform that require custody of assets (such
                    as for storing certain digital assets), Circle will act as the custodian.</p>
                <p>In this context, the custody of your funds will be managed by Circle, and Circle's Privacy Policy
                    will apply to any personal data or transaction information collected during those custodial
                    transactions.</p>
                <p><strong>Key Points:</strong></p>
                <ul>
                    <li>Circle will process your personal information as part of the custodial services provided.</li>
                    <li>Circle may collect and retain information to meet regulatory requirements, provide secure
                        custody, and support customer service operations.</li>
                    <li>Please review Circle's Privacy Policy for full details on how they process your data and manage
                        security.</li>
                </ul>

                <h2>7. Bridge for IBAN Integration</h2>
                <p>If you use Credible's integration with International Bank Account Numbers (IBAN) for transferring or
                    receiving funds, we may share certain transaction details with trusted partners and financial
                    institutions involved in the process. These details may include your IBAN, account holder
                    information, and transaction history to ensure the smooth processing of financial transactions.</p>
                <p>The sharing of this data is necessary for the completion of your transaction and is subject to the
                    privacy policies of the third-party financial institutions we partner with. We encourage you to
                    review the privacy policies of these entities before using the IBAN integration feature.</p>

                <h2>8. Data Security</h2>
                <p>We implement strict technical and organizational measures to protect your personal information from
                    unauthorized access, alteration, or destruction. Credible employs industry-leading security measures
                    to protect user data. This includes end-to-end encryption, multi-factor authentication (MFA),
                    role-based access controls, regular security audits, and penetration testing. Our servers are
                    monitored 24/7 to detect and prevent security breaches. However, users are encouraged to take
                    additional security precautions, such as enabling two-factor authentication and using strong
                    passwords.</p>
                <p>These measures include:</p>
                <ul>
                    <li><strong>Encryption:</strong> We use encryption protocols to protect sensitive information during
                        transmission.</li>
                    <li><strong>Access Control:</strong> We limit access to your personal data to authorized personnel
                        who need it to perform their job functions.</li>
                    <li><strong>Regular Audits and Security Monitoring:</strong> We conduct regular security audits and
                        monitor the platform for suspicious activities.</li>
                    <li>However, while we take all necessary precautions to safeguard your data, no system is entirely
                        foolproof. Therefore, we cannot guarantee the absolute security of your information against
                        unforeseen breaches.</li>
                </ul>

                <h2>9. STORAGE AND RETENTION</h2>
                <p>Personal Data is stored for variable periods of time depending on the category, of Personal Data,
                    processing purposes and its usage:</p>
                <ul>
                    <li>Some information might be deleted automatically based on specific schedules or via script upon
                        request. If you have opted out of receiving marketing communications, we will hold your email
                        address on our suppression list so that we know you do not want to receive these communications;
                    </li>
                    <li>Other data, such as account information, might be retained for a longer period based on the
                        contract you have with us, in accordance with relevant industry standards or guidelines, and in
                        accordance with our legitimate business interests, including prevention of promotion abuse and
                        similar activities;</li>
                    <li>We might further retain information for business practises based on our legitimate interest such
                        as product and service improvement, fraud prevention, record-keeping, in the event of complaint
                        or enforcing our legal rights;</li>
                    <li>We might have to retain a certain set of Personal Data to comply with our audit, reporting and
                        other legal and regulatory obligations (including but not limited to the FATF recommendations
                        and the relevant anti-money laundering legislation which oblige us to retain your Personal Data
                        for a period of five (5) years after the end of the relationship between us or the date of the
                        occasional transaction, which retention period may be further extended in certain cases if so
                        provided by and in accordance with the applicable legislation).</li>
                </ul>
                <p>Subject to Data Processing Agreements concluded by and between Credible and third party service
                    providers in their capacity of Processors on behalf of Credible, such Processors undertake to store
                    and retain Personal Data in accordance with the terms contained in this Privacy Policy.</p>

                <h2>9. Your Rights and Choices</h2>
                <p>Depending on where you live, you may have the following rights regarding your personal data:</p>
                <ul>
                    <li><strong>Access:</strong> You can request to access the personal data we hold about you.</li>
                    <li><strong>Correction:</strong> You have the right to request corrections to any inaccurate or
                        incomplete information.</li>
                    <li><strong>Deletion:</strong> You can request the deletion of your personal data, subject to legal
                        and operational restrictions.</li>
                    <li><strong>Data Portability:</strong> You may request a copy of your data in a structured, commonly
                        used format.</li>
                    <li><strong>Objection to Processing:</strong> You can object to the processing of your personal data
                        in certain circumstances, particularly if the processing is based on legitimate interests or
                        consent.</li>
                    <li><strong>Withdraw Consent:</strong> Where we rely on your consent to process data, you may
                        withdraw your consent at any time.</li>
                    <li>To exercise any of these rights, please contact us using the contact details below. We will
                        respond to your request in accordance with applicable privacy laws.</li>
                </ul>

                <h2>10. Cookies and Tracking Technologies</h2>
                <ul>
                    <li>Credible uses cookies and similar tracking technologies to enhance the user experience, improve
                        functionality, and analyze usage patterns. Cookies are small files that are placed on your
                        device, and they help us remember your preferences, provide features, and measure the
                        effectiveness of our platform.</li>
                    <li>You can manage cookie preferences through your browser settings, including the option to block
                        or delete cookies. However, please note that disabling cookies may limit certain features of our
                        platform.</li>
                </ul>

                <h2>11. Children's Privacy</h2>
                <p>Credible is not intended for use by children under the age of 18. We do not knowingly collect
                    personal information from children under 18. If we become aware that a child has provided us with
                    personal information, we will take steps to delete it as soon as possible.</p>

                <h2>12. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices, technology,
                    or legal requirements. The updated Privacy Policy will be posted on this page, and the "Last
                    Updated" date will be revised accordingly.</p>
                <p>We encourage you to periodically review this policy to stay informed about how we are protecting your
                    data.</p>

                <h2>10. Contact Information</h2>
                <p><strong>Email:</strong> <a
                        href="mailto:support@credibleplatform.com">support&#64;credibleplatform.com</a></p>
                <p><strong>Address:</strong> [Credible's Physical Address]</p>
                <p><strong>Phone:</strong> [Credible's Phone Number]</p>
            </div>
        </div>
    </div>

</div>