import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, OnInit
} from '@angular/core';
import {
    MAT_DIALOG_DATA, MatDialogRef 
} from '@angular/material/dialog';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-sale-agreement',
    templateUrl: './sale-agreement.component.html',
    styleUrls: [ './sale-agreement.component.scss' ]
})
export class SaleAgreementComponent implements OnInit {
    date: Date = new Date();
    ip_location: any = {};
    ip_location_string: string = '';
    unlock_schedule: any;

    constructor(
        public httpService: HttpService, 
        private genesisService: GenesisService,
        private cfAlertService: CfAlertService, 
        private dialogRef: MatDialogRef<SaleAgreementComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {plan_id: string, cred_rate_payment_currency: number, payment_currency: string, receivable_amount: number}) { }

    ngOnInit(): void {
        this.getIpLocation();
        this.getPlanUnlockSchedule();
    }

    getIpLocation() {
        this.genesisService.getIpLocation().subscribe((res) => {
            this.ip_location = res.data;

            const arr = [];

            if (this.ip_location.city)
                arr.push(this.ip_location.city);

            if (this.ip_location.regionName)
                arr.push(this.ip_location.regionName);

            if (this.ip_location.country)
                arr.push(this.ip_location.country);

            this.ip_location_string = arr.join(', ');
        }, (err: HttpErrorResponse) => {
            console.log(err);
            this.cfAlertService.showError(err);
        });
    }

    acceptSaleAgreement() {
        this.dialogRef.close({
            status: 'accept'
        });
    }

    rejectSaleAgreement() {
        this.dialogRef.close({
            status: 'reject'
        });
    }

    getPlanUnlockSchedule() {
        this.genesisService.getPlanUnlockSchedule(this.data.plan_id).subscribe(
            (res: any) => {
                this.unlock_schedule = res.data;
            },
            (err: HttpErrorResponse) => {
                console.error(err);
                this.cfAlertService.showError(err);
            }
        );
    }
}
