<div class="loans-credit-terms-container">

    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="loans-credit-terms-content">

            <h1 class="heading">Loans and Credit Terms and Conditions</h1>
            <br>
            <br>
            <h5>Last Updated: 05/02/2025
            </h5>
            <br>
            <div class="paragraph">
                <h2>1. Introduction</h2>
                <p>These Loans and Credit Terms and Conditions ("Terms") govern the provision of loans, credit lines,
                    and other financing solutions offered by Credible ("we," "us," or "our") to eligible users
                    ("Borrowers" or "Users"). These Terms outline the eligibility criteria, application process,
                    interest rates, repayment obligations, security measures, late payment consequences, dispute
                    resolution mechanisms, and regulatory compliance applicable to all loan and credit services provided
                    by Credible.</p>
                <p>By applying for or accepting a loan or credit facility from Credible, you acknowledge and agree to
                    these Terms. If you do not agree, you must not proceed with any loan application or use any
                    credit-related services offered by Credible.</p>

                <h2>2. Definitions</h2>
                <p>For clarity, the following definitions apply throughout these Terms:</p>
                <ul>
                    <li><strong>"Credible"</strong> -- The platform providing loan and credit facilities, including its
                        affiliates, partners, and financial service providers.</li>
                    <li><strong>"User" or "Borrower"</strong> -- Any individual or entity applying for and utilizing
                        loan or credit services from Credible.</li>
                    <li><strong>"Loan"</strong> -- A sum of money borrowed from Credible, subject to repayment within an
                        agreed timeframe along with applicable interest and fees.</li>
                    <li><strong>"Credit Line"</strong> -- A revolving credit facility allowing users to borrow up to a
                        pre-approved limit.</li>
                    <li><strong>"Interest Rate"</strong> -- The cost of borrowing, expressed as a percentage of the
                        outstanding balance.</li>
                    <li><strong>"APR (Annual Percentage Rate)"</strong> -- The annualized cost of borrowing, including
                        interest and fees.</li>
                    <li><strong>"Repayment Schedule"</strong> -- The agreed plan for repaying borrowed funds, including
                        due dates and installment amounts.</li>
                    <li><strong>"Collateral"</strong> -- Assets pledged as security for a loan.</li>
                    <li><strong>"Default"</strong> -- Failure to make timely loan or credit payments as per the
                        repayment schedule.</li>
                    <li><strong>"Late Payment Fees"</strong> -- Charges imposed for missed or delayed payments.</li>
                    <li><strong>"Credit Score"</strong> -- A numerical representation of a user's creditworthiness.</li>
                    <li><strong>"Debt Recovery"</strong> -- The process of collecting unpaid amounts from borrowers who
                        default on their loans.</li>
                </ul>

                <h2>3. Loan and Credit Eligibility</h2>
                <h3>3.1 General Eligibility Requirements</h3>
                <p>To qualify for a loan or credit facility, you must:</p>
                <ul>
                    <li>Be at least 18 years old or the legal age of majority in your jurisdiction.</li>
                    <li>Provide valid identity verification documents (e.g., government-issued ID, proof of address).
                    </li>
                    <li>Have an active Credible account in good standing.</li>
                    <li>Meet the platform's creditworthiness criteria, which may include:
                        <ul>
                            <li>A satisfactory credit score or repayment history.</li>
                            <li>Stable income or financial resources to support loan repayments.</li>
                            <li>Sufficient collateral (if applicable).</li>
                        </ul>
                    </li>
                    <li>Reside in a jurisdiction where Credible's lending services are legally permitted.</li>
                </ul>

                <h3>3.2 Loan Approval and Credit Assessment</h3>
                <p>Loan approval is subject to risk evaluation and credit assessment, which includes:</p>
                <ul>
                    <li>Reviewing your credit history and financial stability.</li>
                    <li>Evaluating your debt-to-income ratio (DTI).</li>
                    <li>Assessing collateral (for secured loans).</li>
                    <li>Compliance with Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations.</li>
                </ul>
                <p>Credible reserves the right to reject loan applications at its sole discretion if the borrower does
                    not meet the required criteria.</p>

                <h2>4. Loan Types and Credit Limits</h2>
                <h3>4.1 Types of Loans Available</h3>
                <p>Credible offers the following types of loans and credit products:</p>
                <ul>
                    <li><strong>Personal Loans</strong> -- Fixed-term loans provided for general expenses, subject to
                        eligibility.</li>
                    <li><strong>Business Loans</strong> -- Financing solutions designed for business owners and
                        entrepreneurs.</li>
                    <li><strong>Secured Loans</strong> -- Loans backed by collateral (e.g., real estate, digital assets,
                        or other assets).</li>
                    <li><strong>Unsecured Loans</strong> -- Loans granted based on creditworthiness without requiring
                        collateral.</li>
                    <li><strong>Revolving Credit Lines</strong> -- A credit facility allowing users to borrow funds up
                        to an approved limit, repay, and borrow again as needed.</li>
                    <li><strong>Buy Now, Pay Later (BNPL)</strong> -- Short-term financing for purchases, with
                        installment payment options.</li>
                </ul>

                <h3>4.2 Credit Limits and Loan Amounts</h3>
                <p>The amount you can borrow depends on:</p>
                <ul>
                    <li>Your credit profile and repayment history.</li>
                    <li>The type of loan or credit facility requested.</li>
                    <li>Applicable collateral (if any).</li>
                    <li>Credible's internal lending policies and risk assessments.</li>
                </ul>
                <p>The final approved amount will be disclosed before loan acceptance.</p>

                <h2>5. Interest Rates, Fees, and Charges</h2>
                <h3>5.1 Interest Rates and APR</h3>
                <p>Interest rates vary based on:</p>
                <ul>
                    <li>Loan type and duration.</li>
                    <li>Creditworthiness and risk assessment.</li>
                    <li>Market conditions and regulatory requirements.</li>
                </ul>
                <p>The applicable APR (Annual Percentage Rate) will be disclosed before loan acceptance.</p>

                <h3>5.2 Fees and Other Charges</h3>
                <p>Borrowers may be subject to the following fees:</p>
                <ul>
                    <li><strong>Origination Fees</strong> -- A one-time fee charged at loan disbursement.</li>
                    <li><strong>Late Payment Fees</strong> -- Charges incurred for overdue payments.</li>
                    <li><strong>Early Repayment Fees</strong> -- Fees that may apply for prepayment of the loan before
                        the maturity date (if applicable).</li>
                    <li><strong>Processing Fees</strong> -- Administrative costs related to loan application and credit
                        assessment.</li>
                </ul>
                <p>All fees will be clearly outlined in the loan agreement before acceptance.</p>

                <h2>6. Repayment Terms and Obligations</h2>
                <h3>6.1 Repayment Schedule</h3>
                <p>The loan repayment terms will be specified in your loan agreement and will include:</p>
                <ul>
                    <li>Payment due dates.</li>
                    <li>Monthly or periodic installment amounts.</li>
                    <li>Accepted payment methods (bank transfers, cryptocurrency payments, etc.).</li>
                </ul>

                <h3>6.2 Early Repayment and Prepayment</h3>
                <p>Borrowers may repay the loan early without penalty unless otherwise stated in the loan agreement. Any
                    applicable early repayment fees will be disclosed before loan acceptance.</p>

                <h3>6.3 Late Payments and Default</h3>
                <p>Failure to make payments on time may result in:</p>
                <ul>
                    <li>Late payment penalties.</li>
                    <li>Interest rate increases on outstanding balances.</li>
                    <li>Negative credit reporting that may affect future borrowing ability.</li>
                    <li>Legal actions and debt recovery measures, including collections and legal proceedings.</li>
                </ul>
                <p>If a borrower fails to remedy a default, Credible may liquidate collateral (if applicable) or pursue
                    other legal avenues to recover outstanding debts.</p>

                <h2>7. Security and Collateral Requirements</h2>
                <h3>7.1 Secured vs. Unsecured Loans</h3>
                <p>Some loans require collateral, while others do not:</p>
                <ul>
                    <li><strong>Secured Loans</strong> -- Require pledged assets, such as crypto assets, real estate, or
                        financial securities, which may be liquidated in case of default.</li>
                    <li><strong>Unsecured Loans</strong> -- Granted based on creditworthiness without collateral but may
                        have higher interest rates.</li>
                </ul>

                <h3>7.2 Collateral Liquidation Process</h3>
                <p>If a borrower defaults on a secured loan, the pledged collateral may be sold or transferred to
                    recover outstanding amounts. Credible will notify the borrower before liquidation, subject to
                    applicable laws.</p>

                <h2>8. Legal and Regulatory Compliance</h2>
                <h3>8.1 Compliance with Laws</h3>
                <p>Credible complies with applicable lending laws, financial regulations, and consumer protection
                    statutes. Borrowers must also comply with all legal obligations related to borrowing and repaying
                    funds.</p>

                <h3>8.2 Anti-Fraud and AML Measures</h3>
                <p>To prevent fraud, Credible may:</p>
                <ul>
                    <li>Conduct KYC and AML verification before loan approval.</li>
                    <li>Report suspicious activities to regulatory authorities.</li>
                    <li>Freeze accounts or terminate services in case of fraud detection.</li>
                </ul>

                <h2>9. Dispute Resolution and Governing Law</h2>
                <h3>9.1 Filing a Complaint</h3>
                <p>Borrowers can raise disputes by contacting Credible support with relevant details.</p>

                <h3>9.2 Governing Law and Arbitration</h3>
                <p>These Terms are governed by Washington, DE laws, and disputes may be settled through arbitration or
                    legal proceedings.</p>

                <h2>10. Contact Information</h2>
                <p><strong>Email:</strong> <a href="mailto:help@credible.finance">help&#64;credible.finance</a></p>
                <p><strong>Address:</strong> [Credible's Physical Address]</p>
                <p><strong>Phone:</strong> [Credible's Phone Number]</p>

            </div>
        </div>
    </div>

</div>