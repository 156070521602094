<div class="app-my-payfi-investments" [ngClass]="httpService.theme">
    <div class="app-my-payfi-investments-container container">
        <div class="row stats 123" *ngIf="!loading_stats && showStats">
            <div>
                <cf-card [theme]="httpService.theme" *ngIf="investmentStats?.all?.amount_usd >= 0" [stat]="
                        '$' +
                        (investmentStats?.all?.amount_usd | number : '0.0-0')
                    " [subtitle]="
                        httpService.getUsdFormatting(
                            investmentStats?.all?.count
                        ) + ' Supply'
                    " heading="All supplies" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" *ngIf="investmentStats?.days_1?.amount_usd >= 0" [stat]="
                        '$' +
                        (investmentStats?.days_1?.amount_usd | number : '0.0-0')
                    " [subtitle]="
                        httpService.getUsdFormatting(
                            investmentStats?.days_1?.count
                        ) + ' Supply'
                    " heading="Last 24 hours" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" *ngIf="investmentStats?.days_7?.amount_usd >= 0" [stat]="
                        '$' +
                        (investmentStats?.days_7?.amount_usd | number : '0.0-0')
                    " [subtitle]="
                        httpService.getUsdFormatting(
                            investmentStats?.days_7?.count
                        ) + ' Supply'
                    " heading="Last 7 days" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" *ngIf="investmentStats?.days_30?.amount_usd >= 0" [stat]="
                        '$' +
                        (investmentStats?.days_30?.amount_usd
                            | number : '0.0-0')
                    " [subtitle]="
                        httpService.getUsdFormatting(
                            investmentStats?.days_30?.count
                        ) + ' Supply'
                    " heading="Last 30 days" type="compact"></cf-card>
            </div>
        </div>

        <div *ngIf="!loading && httpService.user.user_id > 0" class="my-payfi-investments">
            <div class="row mb-1">
                <div class="col-md-9"></div>
                <div class="col-md-3">
                    <cf-dropdown [theme]="httpService.theme" [options]="statusOptions" (change)="changeStatus($event)"
                        label="Select Status" [showLogo]="false" [selected]="selected_status" [showDescription]="true">
                    </cf-dropdown>
                </div>
            </div>
            <mat-expansion-panel (afterExpand)="afterExpand(item.lending_id)"
                [expanded]="opened_panel === item.lending_id" [ngClass]="getStatusClasses(item.lending_id, item.status)"
                *ngFor="
                    let item of investments;
                    let index = index;
                    first as isFirst
                ">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span>
                            {{ item.created | date : "mediumDate" }}
                        </span>
                        <span>
                            {{
                            item.amount
                            | number
                            : httpService.getPrecision(
                            item.currency
                            )
                            }}
                            {{ item.currency | uppercase }}
                            <span class="equivalent-amount">
                                ~
                                {{
                                item.amount_usd
                                | number
                                : httpService.getPrecision("usd")
                                }}
                                USD
                            </span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table>
                    <tbody>
                        <tr>
                            <td class="txn-heading">Supply Date</td>
                            <td>
                                {{ item.created | date : "medium" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Duration</td>
                            <td *ngIf="item.duration_days === 0">Flexible</td>
                            <td *ngIf="item.duration_days > 0">
                                {{ item.duration_days }} days
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Amount</td>
                            <td>
                                {{
                                item.amount
                                | number
                                : httpService.getPrecision(
                                item.currency
                                )
                                }}
                                {{ item.currency | uppercase }}
                                <span class="equivalent-amount">
                                    ~
                                    {{
                                    item.amount_usd
                                    | number
                                    : httpService.getPrecision(
                                    "usd"
                                    )
                                    }}
                                    USD
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Withdrawn</td>
                            <td>
                                {{
                                item.repaid_amount
                                | number
                                : httpService.getPrecision(
                                item.currency
                                )
                                }}
                                {{ item.currency | uppercase }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Earnings Claimed</td>
                            <td>
                                {{
                                item.claimed_interest
                                | number
                                : httpService.getPrecision(
                                item.currency
                                )
                                }}
                                {{ item.currency | uppercase }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Blockchain</td>
                            <td>
                                {{ item.blockchain | titlecase }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Chain</td>
                            <td>
                                {{ item.chain | titlecase }}
                            </td>
                        </tr>
                        <tr *ngIf="item.approval_txid && item.approval_txid.length > 0">
                            <td class="txn-heading">Approval Txid</td>
                            <td>
                                <div class="redirection">
                                    {{ httpService.sliceAddress(item.approval_txid, 20) }}
                                    <i class="fa-solid fa-up-right-from-square ml cursor-hover" (click)="
                                            httpService.redirectionTx(item.blockchain, item.chain, item.network, item.approval_txid)
                                        "></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Wallet address</td>
                            <td>
                                <div class="redirection" *ngIf="item.wallet_address">
                                    {{ httpService.sliceAddress(item.wallet_address, 20) }}
                                    <i class="fa-solid fa-up-right-from-square ml cursor-hover" (click)="
                                            httpService.redirectionAddress(item.blockchain, item.chain, item.network, item.wallet_address)
                                        "></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Blockchain Txid</td>
                            <td>
                                <div class="redirection" *ngIf="item.blockchain_txid">
                                    {{ httpService.sliceAddress(item.blockchain_txid, 20) }}
                                    <i class="fa-solid fa-up-right-from-square ml cursor-hover" (click)="
                                            httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)
                                        "></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Pool</td>
                            <td>
                                {{ item.pool_name }}
                                <i class="fa-solid fa-up-right-from-square ml cursor-hover" (click)="
                                        redirectionPool(
                                            item.category_id,
                                            item.pool_id
                                        )
                                    "></i>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Supply Id</td>
                            <td>
                                {{ item.lending_id }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Status</td>
                            <td>
                                <i class="fa-regular fa-circle-check" *ngIf="item.status === 'completed'"></i>
                                <i class="fa-regular fa-circle-xmark" *ngIf="item.status === 'failed'"></i>
                                {{ item.status | titlecase }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <cf-paginator [theme]="httpService.theme" *ngIf="total_count > 0" [page]="page + 1" [count]="total_count"
                [limit]="limit" (changed)="pageChanged($event)"></cf-paginator>
        </div>
        <div class="no-data" *ngIf="
                total_count === 0 &&
                !loading &&
                httpService.user.user_id > 0
            ">
            No records found
        </div>
        <div class="loader" *ngIf="loading">
            <cf-loader [theme]="httpService.theme"></cf-loader>
        </div>
    </div>
</div>