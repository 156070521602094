<div class="app-my-nodes-transactions" [ngClass]="httpService.theme">
    <div class="app-my-nodes-transactions-container container-fluid">
        <div class="row mb-1" *ngIf="!loading">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <cf-dropdown [options]="statusOptions" (change)="changeStatus($event)" label="Select Status"
                    [showLogo]="false" [selected]="selected_status" [showDescription]="true">
                </cf-dropdown>
            </div>
        </div>
        <div *ngIf="!loading && nodes.length > 0" class="my-nodes">
            <mat-expansion-panel (afterExpand)="afterExpand(item.node_id)" [expanded]="opened_panel === item.node_id"
                [ngClass]="getStatusClasses(item.node_id, item.status)" *ngFor="
                            let item of nodes;
                            let index = index;
                            first as isFirst
                        ">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span>
                            {{ item.created | date : "mediumDate" }}
                        </span>
                        <span>
                            {{
                            item.payment_amount
                            | number
                            : httpService.getPrecision(
                            item.payment_currency
                            )
                            }}
                            {{ item.payment_currency | uppercase }}
                            <span class="equivalent-amount">
                                ~
                                {{
                                item.payment_amount_usd
                                | number
                                : httpService.getPrecision("usd")
                                }}
                                USD
                            </span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table>
                    <tbody>
                        <tr>
                            <td class="txn-heading">Purchase Date</td>
                            <td>
                                {{ item.created | date : "medium" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Current Node Value</td>
                            <td>
                                {{
                                item.payment_amount
                                | number
                                : httpService.getPrecision(
                                item.payment_currency
                                )
                                }}
                                {{ item.payment_currency | uppercase }}
                                <span class="equivalent-amount">
                                    ~
                                    {{
                                    item.payment_amount_usd
                                    | number
                                    : httpService.getPrecision(
                                    "usd"
                                    )
                                    }}
                                    USD
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Unlock vCRED</td>
                            <td>
                                {{
                                item.cred_unlocked
                                | number
                                : httpService.getPrecision(
                                item.payment_currency
                                )
                                }}
                                {{ item.payment_currency | uppercase }}
                                <span class="equivalent-amount">
                                    ~
                                    {{
                                    item.cred_unlocked_usd
                                    | number
                                    : httpService.getPrecision(
                                    "usd"
                                    )
                                    }}
                                    USD
                                </span>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td class="txn-heading">Available</td>
                            <td>
                                {{
                                        item.staked
                                        | number
                                        : httpService.getPrecision(
                                        item.currency
                                        )
                                        }}
                                {{ item.currency | uppercase }}
                                <span class="equivalent-amount">
                                    ~
                                    {{
                                            item.staked * item.usd_rate
                                            | number
                                            : httpService.getPrecision(
                                            "usd"
                                            )
                                            }}
                                    USD
                                </span>
                            </td>
                        </tr> -->
                        <tr>
                            <td class="txn-heading">Blockchain Txid</td>
                            <td>
                                <div class="redirection" *ngIf="item.blockchain_txid">
                                    {{
                                    httpService.sliceAddress(
                                    item.blockchain_txid,
                                    20
                                    )
                                    }}
                                    <i class="fa-solid fa-up-right-from-square ml cursor-hover" (click)="
                                                    httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)
                                                "></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Staking Id</td>
                            <td>
                                {{ item.staking_id }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Status</td>
                            <td>
                                <i class="fa-regular fa-circle-check" *ngIf="item.status === 'completed'"></i>
                                <i class="fa-regular fa-circle-xmark" *ngIf="item.status === 'failed'"></i>
                                {{ item.status | titlecase }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
                (changed)="pageChanged($event)"></cf-paginator>
        </div>

        <div class="no-data" *ngIf="
                total_count === 0 &&
                !loading
            ">
            No records found
        </div>

        <div class="loader" *ngIf="loading">
            <cf-loader></cf-loader>
        </div>
    </div>
</div>