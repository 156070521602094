import { Injectable } from '@angular/core';
import { getWindow } from 'ssr-window';
import MetaMaskSDK, { SDKProvider } from '@metamask/sdk';
import { WalletService } from '../services/wallet.service';
import Web3 from 'web3';
import ETHEREUM_CHAINS from '../constants/EthereumChains';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    PLUME_ENVIRONMENT, serverUrl 
} from './app.config';

@Injectable({
    providedIn: 'root'
})
export class MetamaskService {
    window: Window = getWindow();
    provider: SDKProvider | undefined;
    wallet_address: string = '';
    chainId: string | undefined | null = '';
    web3: any;
    
    constructor(private walletService: WalletService, private cfAlertService: CfAlertService) {
        this.window = getWindow();

        const isPresent = this.isPresent();

        console.log('MetamaskService isPresent', isPresent);
    }

    isPresent() {
        console.log('MetamaskService isPresent');

        return 'ethereum' in this.window;
    }
    
    setProvider() {
        console.log('MetamaskService setProvider');

        const MMSDK = new MetaMaskSDK({
            injectProvider: true,
            dappMetadata: {
                name: 'Credible Finance',
                url: 'https://credible.finance'
            },
            infuraAPIKey: ETHEREUM_CHAINS[PLUME_ENVIRONMENT].rpc
        });

        console.log('MetamaskService MMSDK', MMSDK);

        if ('ethereum' in this.window) {
            this.provider = this.window.ethereum;

            this.attachProviderEvents();
        }

        console.log('MetamaskService provider', this.provider);
        console.log('MetamaskService isConnected', ('ethereum' in window), ('ethereum' in this.window));
    }

    attachProviderEvents() {
        console.log('MetamaskService attachProviderEvents');

        this.onConnect();

        this.onDisconnect();

        this.onAccountsChanged();

        this.onChainChanged();

        this.provider?.on('message', (message: any) => {
            console.log('MetamaskService Metamask message', message);
        });

        this.provider?.on('error', (error: any) => {
            console.log('MetamaskService Metamask error', error);
        });
    }

    onConnect() {
        console.log('MetamaskService onConnect');

        if (!this.provider)
            this.setProvider();

        this.provider?.on('connect', (...args: unknown[]) => {
            console.log('MetamaskService Metamask connected', args);

            const address = args[0] as string;

            this.newAddressConnected(address);
        });
    }

    onDisconnect() {
        console.log('MetamaskService onDisconnect');

        this.provider?.on('disconnect', () => {
            console.log('MetamaskService Metamask disconnected');
        });
    }

    onAccountsChanged() {
        console.log('MetamaskService onAccountsChanged');

        this.provider?.on('accountsChanged', (...args: unknown[]) => {
            console.log('MetamaskService Metamask accounts changed', args);

            const chainIdHex = args[0] as string;
            const chainIdDecimal = parseInt(chainIdHex, 16);

            console.log('MetamaskService chainIdHex', chainIdHex);
            console.log('MetamaskService chainIdDecimal', chainIdDecimal);

            if (args.length === 0) {
                console.log('MetamaskService Metamask disconnected');

                this.walletService.wallet_address = '';
                this.walletService.current_provider = '';
                this.wallet_address = '';

                return ;
            }

            const address = args[0] as string;

            this.newAddressConnected(address);
        });
    }

    onChainChanged() {
        console.log('MetamaskService onChainChanged');

        this.provider?.on('chainChanged', (...args: unknown[]) => {
            console.log('MetamaskService Metamask chain changed', args);

            this.window.location.reload();

            if (args.length > 0)
                this.chainId = args[0] as string;
        });
    }

    signMessageLedger(publicKey: string) {
        console.log('MetamaskService signMessageLedger', publicKey);
    }

    async signMessageNonLedger() {
        console.log('MetamaskService signMessageNonLedger');

        const exampleMessage = 'Sign below to authenticate with Credible finance';

        try {
            const from = this.wallet_address;
            // For historical reasons, you must submit the message to sign in hex-encoded UTF-8.
            // This uses a Node.js-style buffer shim in the browser.
            const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
            const signedMessage = await this.provider?.request({
                method: 'personal_sign',
                params: [ msg, from ]
            });

            console.log('MetamaskService signedMessage', signedMessage);

            if (!signedMessage) {
                this.walletService.setEvent('metamask', 'signMessage', {
                    status: 'failure'
                });
            }

            else {
                this.walletService.setEvent('metamask', 'signMessage', {
                    status: 'success',
                    signature: signedMessage,
                    current_provider: 'metamask',
                    blockchain: 'solana',
                    wallet_address: this.wallet_address
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    signTransaction(abi: string, contract_address: string, gas: number) {
        console.log('MetamaskService signTransaction', abi, contract_address, this.wallet_address, gas);

        const extra_gas = 100 * 1000;

        const promise = new Promise<string>((resolve, reject) => {
            this.provider?.request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        data: abi,
                        from: this.wallet_address,
                        to: contract_address,
                        value: '0',
                        gas: (gas + extra_gas).toString(16)
                    }
                ]
            }).then((txHash) => {
                console.log('MetamaskService txHash', txHash);

                const txnSignature = txHash as string;

                resolve(txnSignature);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });

        return promise;
    }

    switchNetwork() {
        console.log('MetamaskService switchNetwork');

        const chainInfo = ETHEREUM_CHAINS[PLUME_ENVIRONMENT];

        this.provider?.request({
            method: 'wallet_switchEthereumChain',
            params: [{
                chainId: Web3.utils.toHex(chainInfo.chainId)
            }]
        }).then((res) => {
            this.cfAlertService.showMessage(`Switched to ${chainInfo.chainName}`, false);
        }).catch((e) => {
            this.cfAlertService.showMessage(`Failed to switch to ${chainInfo.chainName}`, true);
            console.error('switchNetwork error', e);
        });
    }

    async signMessage(address: string) {
        console.log('MetamaskService signMessage', address);

        if (this.walletService.ledger)
            await this.signMessageLedger(address);

        else
            await this.signMessageNonLedger();
    }

    downloadWallet() {
        console.log('MetamaskService downloadWallet');

        this.window.open('https://metamask.io/download/', '_blank');
    }

    async connectWallet() {
        console.log('MetamaskService connectWallet');

        if (!this.provider)
            this.setProvider();

        console.log('MetamaskService provider', this.provider);
        console.log('MetamaskService isConnected', this.provider?.isConnected());
        console.log('MetamaskService isMetaMask', this.provider?.isMetaMask);
        
        const accounts = await this.provider?.request({
            method: 'eth_requestAccounts'
        });

        console.log('MetamaskService connectWallet', accounts);
        
        this.chainId = await this.getChainId();

        console.log('MetamaskService chainIdHex', this.chainId);

        if (!accounts) {
            console.log('MetamaskService No accounts found');

            return ;
        }

        const accountsArr = accounts as string[];

        if (this.chainId) {
            const chainIdDecimal = parseInt(this.chainId, 16);

            console.log('MetamaskService chainIdDecimal', chainIdDecimal);
            
            const address = accountsArr[0];

            this.newAddressConnected(address);
        }

        else
            this.switchNetwork();
    }

    newAddressConnected(wallet_address: string) {
        console.log('MetamaskService newAddressConnected', wallet_address);

        this.wallet_address = wallet_address;

        this.walletService.setEvent('metamask', 'connect', {
            wallet_address: wallet_address
        });

        this.signMessage(this.wallet_address);
    }

    disconnectWallet() {
        console.log('MetamaskService disconnectWallet');
    }

    async getChainId() {
        console.log('MetamaskService getChainId');

        const chainId = await this.provider?.request({
            method: 'eth_chainId' 
        });

        this.chainId = chainId as string;

        return chainId as string;
    }

    connectWalletMobile() {
        console.log('MetamaskService connectWalletMobile');

        let callbackUrl = 'https://credible.finance';

        if (serverUrl.includes('testnet'))
            callbackUrl = 'https://testnet.credible.finance';

        callbackUrl = encodeURI(callbackUrl.replace('https://', ''));

        const deepLink = `https://metamask.app.link/dapp/${callbackUrl}?ref=${callbackUrl}`;

        console.log('MetamaskService RELOAD ISSUE URL : HeaderComponent deepLink => ', this.window.location.href);

        return this.window.location.href = deepLink;
    }
}
