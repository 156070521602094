import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit, Output, HostListener,
    EventEmitter,
    OnDestroy
} from '@angular/core';
import {
    FormGroup, FormBuilder
} from '@angular/forms';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import WalletEvent from '../../../models/WalletEvent';
import { HttpService } from '../../../services/http.service';
import { LocalStorageService } from '../../../services/localstorage.service';
import { PhantomService } from '../../../services/phantom.service';
import { SolflareService } from '../../../services/solflare.service';
import { WalletService } from '../../../services/wallet.service';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { MetamaskService } from '../../metamask.service';
import { OnboardingService } from '../../onboarding.service';
import { nullChecker } from '../../../helpers/nullChecker';
import {
    Subject, takeUntil 
} from 'rxjs';
import { RecaptchaService } from '../../recaptcha.service';

@Component({
    selector: 'app-login-connect-wallet',
    templateUrl: './login-connect-wallet.component.html',
    styleUrls: [ './login-connect-wallet.component.scss' ]

})
export class LoginConnectWalletComponent implements OnInit, OnDestroy {
    wallet_address: string = '';

    showControls = {
        connectButton: true,
        sendCodeButton: false,
        signingLoader: false,
        disconnectButton: false
    };

    phantomDetected: boolean = true;
    solflareDetected: boolean = true;
    metamaskDetected: boolean = true;

    form!: FormGroup;

    default_provider: string = 'phantom';
    current_provider: string = this.default_provider;
    window: Window;
    width: any;
    ledger: boolean = false;

    allowedProviders: string[] = [ 'phantom', 'solflare', 'metamask' ];
    wallet_list_loader: boolean = false;

    @Output() connected = new EventEmitter<any>();
    @Output() next = new EventEmitter<any>();
    private unsubscribe$: Subject<WalletEvent> = new Subject<WalletEvent>();
    subscription: any;

    use_manual_recaptcha: boolean = false;
    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    constructor(
        private router: Router,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private localStorageService: LocalStorageService,
        private recaptchaService: RecaptchaService,
        private fb: FormBuilder,
        public phantomService: PhantomService,
        public solflareService: SolflareService,
        public metamaskService: MetamaskService,
        private onboardingService: OnboardingService,
        private walletService: WalletService,
        private route: ActivatedRoute
    ) {
        this.window = getWindow();

        this.form = this.fb.group({
            wallet_address: [ '' ],
            signed_message: [ '' ],
            signature: [ '' ]
        });
    }

    ngOnInit(): void {
        console.log('connect-wallet ngOnInit');

        this.width = this.window.innerWidth;

        this.phantomDetected = this.phantomService.isPresent();
        this.solflareDetected = this.solflareService.isPresent();
        this.metamaskDetected = this.metamaskService.isPresent();

        this.walletService.ledger = false;

        this.subscribeWalletObservables();
        this.subscribeQueryObservables();
    }

    async connectWallet(address: string, provider: string) {
        console.log('connectWallet', address, provider);

        if (this.showControls.signingLoader) {
            console.error('connectWallet already in progress');

            return;
        }

        this.showControls.signingLoader = true;

        this.connectWalletLogin(address, provider);
    }

    async connectWalletLogin(address: string, provider: string) {
        const recaptcha = await this.recaptchaService.getRecaptcha('loginWithWallet', this.use_manual_recaptcha);

        const body: any = {
            ...recaptcha,
            wallet_address: this.wallet_address,
            provider: provider,
            blockchain: this.walletService.blockchain,
            ledger: this.walletService.ledger
        };

        if (this.walletService.ledger) {
            const serializedTx = Array.from(this.form.controls['signed_message'].value);

            body.serializedTx = serializedTx;
        }

        else {
            const signature = this.form.controls['signature'].value;

            if (provider === 'metamask')
                body.signature = signature;
        
            else
                body.signature = Array.from(signature);
        }

        this.onboardingService.connectWalletLogin(body).subscribe({
            next: (res: any) => {
                console.log(address, res.data);

                this.walletService.wallet_address = this.wallet_address;
                this.walletService.current_provider = provider;

                this.showControls.connectButton = false;
                this.showControls.signingLoader = false;
                this.showControls.disconnectButton = true;

                this.httpService.getCurrentUser().then(() => {
                    this.connected.emit({
                        provider,
                        email: res.data.email,
                        wallet_address: address
                    });
                });
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);

                if (err.error.message === 'Invalid recaptcha') {
                    this.use_manual_recaptcha = true;

                    this.connectWalletLogin(address, provider);

                    return ;
                }

                this.showControls.connectButton = false;
                this.showControls.signingLoader = false;
                this.showControls.disconnectButton = true;

                this.cfAlertService.showError(err);
            }
        });
    }

    walletChanged(value: string) {
        console.log('walletChanged', value);

        this.wallet_address = value;
    }

    subscribeQueryObservables() {
        this.route.queryParams.subscribe(params => {
            console.log('queryParams params', params);

            if (params['provider']) {
                const provider = params['provider'];

                if (this.allowedProviders.includes(provider))
                    this.current_provider = provider;
            }
        });
    }

    subscribeWalletObservables() {
        console.log('connect-wallet subscribeWalletObservables');

        this.subscription = this.walletService.getEvent().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((event: WalletEvent) => {
            console.log('set array event  => ', event);
        
            switch (event.name) {
                case 'connect':

                    this.wallet_address = event.metadata.wallet_address;

                    this.showControls.connectButton = true;
                    this.showControls.signingLoader = false;
                    this.showControls.disconnectButton = false;
                    this.showControls.sendCodeButton = true;

                    break;

                case 'signMessage':
                    this.signMessage(event);

                    break;

                case 'disconnect':

                    this.cfAlertService.showSuccess(`${this.httpService.sliceAddress(this.wallet_address)}... account disconnected`);
                    this.wallet_address = '';

                    this.showControls.connectButton = true;
                    this.showControls.disconnectButton = false;
                    this.showControls.sendCodeButton = false;

                    this.router.navigate([ '/' ]);

                    break;

                case 'accountChanged':
                    if (event.metadata.new_address) {
                        console.log(`Switched to account ++ ${event.metadata.new_address}`);

                        this.router.navigate([ '/' ]);
                    }

                    break;
            }
        });
    }

    async signMessage(event: WalletEvent) {
        console.log('signMessage', event);

        if (nullChecker(this.wallet_address)){
            console.error('Wallet address not provided');

            return;
        }

        if (event.metadata.status === 'failure')
            return this.cfAlertService.showMessage('Invalid signature');

        else if (event.metadata.status === 'success') {
            if (this.walletService.ledger)
                this.form.controls['signed_message'].setValue(event.metadata.serializedTx);

            else
                this.form.controls['signature'].setValue(event.metadata.signature);

            this.connectWallet(this.wallet_address, event.provider);
        }
    }

    changeProvider(provider: string) {
        this.current_provider = provider;
    }
    
    nextHandler() {
        this.next.emit();
    }

    ledgerChanged(event: any) {
        console.log('ledgerChanged', event);

        this.ledger = event;
        this.walletService.ledger = event;
    }

    getSupportedWallets() {
        this.httpService.getSupportedWallets().subscribe(res => {

        }, err => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    ngOnDestroy(): void {
        console.log('connect-wallet ngOnDestroy');
        
        // this.unsubscribe$.next();
        this.unsubscribe$.complete();

        this.subscription.unsubscribe();
    }
}