import {
    Component, Input, OnInit 
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';

@Component({
    selector: 'app-frequently-asked-questions',
    templateUrl: './frequently-asked-questions.component.html',
    styleUrl: './frequently-asked-questions.component.scss'
})
export class FrequentlyAskedQuestionsComponent implements OnInit {
    openedFaqIndex: number | null = null;
    faqs: Array<{
        question: string;
        answer: string;
        index: string;
    }> = [];
    // 

    @Input() theme: string = 'dark';

    constructor(public httpService: HttpService, private cfAlertService: CfAlertService) {}

    ngOnInit(): void {
        this.getFaqs();
    }

    getFaqs() {
        this.httpService.getNodeSaleFaqQuestions().subscribe((res: any) => {
            this.faqs = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
        
            this.cfAlertService.showError(err);
        });
    }

    openFaq(index: number) {
        this.openedFaqIndex = this.openedFaqIndex === index ? null : index;
    }
}
