<div class="app-my-nodes container-fluid" [ngClass]="httpService.theme">
    <div *ngIf="!loading" class="my-nodes">
        <div class="row mb-4">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <cf-dropdown [options]="statusOptions" (change)="changeStatus($event)" label="Select Status"
                    [showLogo]="false" [selected]="selected_status" [showDescription]="true">
                </cf-dropdown>
            </div>
        </div>

        <div class="node-list" *ngIf="nodes.length > 0">
            <div class="node-list-item" *ngFor="let node of nodes">
                <div class="top-section">
                    <div class="top-section-item">
                        <div class="heading-text">
                            Current Node Value
                        </div>
                        <div class="value-percentage-change">
                            <div class="value">
                                ${{node.current_node_value | number: '0.0-2'}} USD
                            </div>
                            <div *ngIf="node.percentage_change !== 0"
                                [ngClass]="{'percentage-change-positive': node.percentage_change > 0, 'percentage-change-negative' : node.percentage_change < 0 }">
                                {{node.current_node_value_change_percentage}}
                            </div>
                        </div>
                    </div>
                    <div class="top-section-item">
                        <div class="heading-text">
                            Unlock vCRED
                        </div>
                        <div class="value-text">
                            {{ node.status === 'pending' ? 0 : getUnlockCredValue(node) | number: '0.0-6' }} CRED
                        </div>
                    </div>
                    <div class="top-section-item">
                        <div class="heading-text">
                            Staking Rewards
                        </div>
                        <div class="value-text">
                            {{node.staking_reward_info?.amount | number: '0.0-6'}} vCRED
                        </div>
                    </div>
                </div>
                <div class="chart-container">
                    <div class="chart">
                        <!-- <app-line-chart [data]="node.rate_chart" [precision]="6" [currency]="'vCRED'"
                            [name]="'vCRED Rate'" [height]="350" [date_format]="'mediumDate'"></app-line-chart> -->

                        <app-line-chart [lineConfig]="[node.rate_chart]" title="" [currency]="'vCRED'"></app-line-chart>
                    </div>
                </div>
                <div class="bottom-section">
                    <div>
                        <div class="heading-text">
                            Node Size
                        </div>
                        <div class="value-text">
                            {{ node.cred_receivable | number : '0.0-6' }} vCRED
                        </div>
                    </div>
                    <div>
                        <div class="heading-text">
                            Start Date
                        </div>
                        <div class="value-text">
                            {{ node.created | date: 'mediumDate' }}
                        </div>
                    </div>
                    <div>
                        <div class="heading-text">
                            Unlock Days
                        </div>
                        <div class="value-text">
                            {{node.staking_info?.staking_duration}} days
                        </div>
                    </div>
                    <div class="view-details" *ngIf="node.status === 'completed'">
                        <cf-button [label]="'View Details'" (clicked)="redirectToNodeDetails(node.node_id)"
                            type="solid"></cf-button>
                    </div>
                    <div *ngIf="node.status !== 'completed'" class="node-status">
                        <cf-loader [theme]="httpService.theme" [diameter]="30"></cf-loader> {{ node.status | titlecase
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="no-data" *ngIf="
                total_count === 0 &&
                !loading
            ">
        No records found
    </div>

    <div class="loader" *ngIf="loading">
        <cf-loader [diameter]="50"></cf-loader>
    </div>
</div>