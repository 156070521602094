import ETHEREUM_CHAINS from '../constants/EthereumChains';

function getRedirectionTxUrl(blockchain: string, chain: string, network: string, blockchain_txid: string): string {
    if (blockchain === 'solana') {
        if (network === 'devnet')
            return `https://explorer.solana.com/tx/${blockchain_txid}?cluster=devnet`;

        else 
            return `https://explorer.solana.com/tx/${blockchain_txid}`;
    }

    if (blockchain === 'ethereum') {
        if (chain === 'plume') {
            if (network === 'devnet')
                return `${ETHEREUM_CHAINS['PLUMEDEVNET'].blockExplorerUrl}/tx/${blockchain_txid}`;

            else
                return `${ETHEREUM_CHAINS['PLUMEMAINNET'].blockExplorerUrl}/tx/${blockchain_txid}`;
        }
        
        else if (chain === 'base') {
            if (network === 'testnet')
                return `${ETHEREUM_CHAINS['BASETESTNET'].blockExplorerUrl}/tx/${blockchain_txid}`;

            else
                return `${ETHEREUM_CHAINS['BASETESTNET'].blockExplorerUrl}/tx/${blockchain_txid}`;
        }
    }

    return '';
}

export default getRedirectionTxUrl;