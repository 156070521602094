/* eslint-disable array-bracket-spacing */
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit
} from '@angular/core';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { HttpService } from '../../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { GenesisService } from '../../genesis.service';

@Component({
    selector: 'app-leaderboard',
    templateUrl: './leaderboard.component.html',
    styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
    levelOptions: Array<IDropdown> = [];
    current_level = '1';
    topPerformers: Array<any> = [];
    loading_tvl_stats = true;

    topCredUsers: Array<any> = [];
    loading_cred_stats = true;

    constructor(
        private genesisService: GenesisService,
        public httpService: HttpService,
        private cfAlertService: CfAlertService
    ) { }

    ngOnInit(): void {
        this.getReferralLevels();
        this.getTopCredUsers();
    }

    getReferralLevels() {
        this.httpService.getReferralLevels().subscribe((res) => {
            const levels = res.data;

            const levelOptions: any[] = [];

            levels.forEach((level: any) => {
                levelOptions.push({
                    value: level.toString(),
                    label: 'Level ' + level,
                    optionEnabled: true
                });
            });

            this.levelOptions = levelOptions;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    levelChange(option: IDropdown) {
        console.log('levelChange', option);

        this.current_level = option.value;

        this.getTopCredUsers();
    }

    getTopCredUsers() {
        this.loading_tvl_stats = true;

        this.genesisService.getTopCredUsers(this.current_level).subscribe((res) => {
            this.topCredUsers = res.data;
            this.loading_tvl_stats = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading_tvl_stats = false;

            this.cfAlertService.showError(err);
        });
    }
}
