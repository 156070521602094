import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import IUser from '../../../interfaces/IUser';
import User from '../../../models/User';
import { HttpService } from '../../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

@Component({
    selector: 'app-my-moon-claim-history',
    templateUrl: './my-moon-claim-history.component.html',
    styleUrls: [ './my-moon-claim-history.component.scss' ]
})

export class MyMoonClaimHistoryComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    currentTime: number = (new Date()).getTime();
    moon_claims: Array<any> = [];

    total_count: number = 0;
    page: number = 0;
    limit: number = 10;
    opened_panel: string = '';

    statusOptions: Array<IDropdown> = [{
        label: 'All',
        value: '',
        optionEnabled: true
    },
    {
        label: 'Pending',
        value: 'pending',
        optionEnabled: true
    },
    {
        label: 'Processing',
        value: 'processing',
        optionEnabled: true
    }, {
        label: 'Completed',
        value: 'completed',
        optionEnabled: true
    }, {
        label: 'Failed',
        value: 'failed',
        optionEnabled: true
    }];
    selected_status: string = 'completed';

    constructor(public httpService: HttpService, private cfAlertService: CfAlertService, private router: Router) { }

    ngOnInit(): void {
        console.log('my-moon-claim-history.component.ts ngOnInit()');

        this.subscribeObservables();

        this.httpService.setMetaData('My Moon Claims', 'Moons claimed by the user');
    }

    afterExpand(lending_id: string) {
        this.opened_panel = lending_id;
    }

    getMoonClaimTransactions() {
        this.loading = true;

        const data = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            status: this.selected_status
        };

        this.httpService.getMoonClaimTransactions(data).subscribe((res: any) => {
            this.moon_claims = res.data;
            this.total_count = res.total_count;

            if (this.moon_claims.length > 0)
                this.opened_panel = this.moon_claims[0].moon_claim_id;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    subscribeObservables() {
        if (this.httpService.user.user_id > 0) {
            this.httpService.getUserId().subscribe((user_id: number) => {
                this.user.user_id = user_id;

                this.getMoonClaimTransactions();
            });
        }
    }

    pageChanged(event: any) {
        this.page = event;
        this.getMoonClaimTransactions();
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changeStatus(event: any) {
        this.selected_status = event.value;
        this.page = 0;
        this.getMoonClaimTransactions();
    }

    getStatusClasses(id: string, status: string) {
        const classes: any = {};

        if (this.opened_panel === id)
            classes['current-opened'] = true;

        if (status === 'pending')
            classes['status-pending'] = true;

        else if (status === 'processing')
            classes['status-processing'] = true;

        else if (status === 'completed')
            classes['status-success'] = true;

        else if (status === 'failed')
            classes['status-failed'] = true;

        return classes;
    }
}

