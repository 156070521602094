<div class="card-provider-terms-container">

    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="card-provider-terms-content">

            <h1 class="heading">Credible Card Terms and Conditions</h1>
            <br>
            <br>
            <h5>Last Updated: 15/02/2025
            </h5>
            <br>
            <div class="paragraph">
                <h2>1. Introduction</h2>
                <p>Welcome to the Kiwimoney Inc. DBA Credible Card Terms and Conditions ("Terms"). These Terms set out
                    the
                    rights, responsibilities, and obligations associated with the use of the Credible Card (the "Card")
                    and any associated financial services. The Card is issued by [Card Issuer] in collaboration with
                    Credible. By using the Card, you agree to comply with these Terms. If you do not agree, you must
                    stop using the Card immediately.</p>
                <p>The Card is a prepaid or debit card, linked to your Credible account and is designed to facilitate
                    seamless crypto and fiat transactions. These Terms govern card issuance, transactions, security
                    protocols, liability, dispute resolution, and other relevant conditions.</p>

                <h2>2. Definitions</h2>
                <p>For clarity, the following definitions apply throughout this Agreement:</p>
                <ul>
                    <li><strong>"Credible"</strong> -- The entity providing access to financial services, in
                        collaboration with the card issuer.</li>
                    <li><strong>"Card"</strong> -- The Credible-branded debit, prepaid, or virtual card issued under
                        Mastercard or Visa.</li>
                    <li><strong>"User" or "Cardholder"</strong> -- Any individual or entity authorized to use the Card.
                    </li>
                    <li><strong>"Issuer"</strong> -- The financial institution that issues the Credible Card under
                        applicable financial regulations.</li>
                    <li><strong>"PIN"</strong> -- Personal Identification Number required for certain transactions.</li>
                    <li><strong>"KYC"</strong> -- Know Your Customer, a regulatory process for identity verification.
                    </li>
                    <li><strong>"Merchant"</strong> -- Any retailer, business, or service provider accepting Card
                        payments.</li>
                    <li><strong>"Transaction"</strong> -- Any payment, withdrawal, or deposit made using the Card.</li>
                    <li><strong>"Crypto-to-Fiat Conversion"</strong> -- The process of converting cryptocurrency into
                        fiat currency for spending purposes.</li>
                </ul>

                <h2>3. Card Issuance and Activation</h2>
                <h3>3.1 Eligibility for Issuance</h3>
                <p>To apply for and use the Card, you must:</p>
                <ul>
                    <li>Be at least 18 years old or the legal age of majority in your jurisdiction.</li>
                    <li>Pass the KYC (Know Your Customer) verification process, which requires submission of
                        government-issued identification, proof of address, and a biometric verification (where
                        applicable).</li>
                    <li>Not be a resident of restricted jurisdictions where card services are not permitted.</li>
                    <li>Comply with Credible's policies, issuer requirements, and applicable financial laws.</li>
                </ul>

                <h3>3.2 Activation and Usage</h3>
                <p>Once approved, the Card must be activated through the Credible mobile app or website before it can be
                    used. Activation steps may include:</p>
                <ul>
                    <li>Setting a Personal Identification Number (PIN) for ATM withdrawals and point-of-sale purchases.
                    </li>
                    <li>Accepting these Terms and Conditions electronically.</li>
                    <li>Completing additional security verifications as required by Credible or the issuer.</li>
                </ul>

                <h2>4. Card Functions and Usage</h2>
                <h3>4.1 Supported Transactions</h3>
                <p>The Card can be used for:</p>
                <ul>
                    <li>Purchases at retail stores, restaurants, and online merchants that accept Mastercard or Visa.
                    </li>
                    <li>ATM withdrawals, subject to applicable limits.</li>
                    <li>Crypto-to-fiat conversions, allowing users to spend cryptocurrencies at merchants.</li>
                    <li>Contactless payments, including integration with mobile wallets (Google Pay, Apple Pay).</li>
                    <li>Recurring payments and subscriptions, as permitted by the issuer.</li>
                </ul>

                <h3>4.2 Prohibited Uses</h3>
                <p>The Card must not be used for the following activities:</p>
                <ul>
                    <li>Illegal transactions, including money laundering, terrorism financing, or purchasing prohibited
                        goods.</li>
                    <li>Gambling and betting services, unless explicitly allowed by the issuer.</li>
                    <li>Unregistered businesses or high-risk financial services not compliant with regulatory
                        requirements.</li>
                    <li>Third-party transfers outside of authorized channels.</li>
                </ul>
                <p>If any unauthorized or prohibited use is detected, Credible and the issuer reserve the right to
                    suspend or terminate the Card without prior notice.</p>

                <h2>5. Fees and Limits</h2>
                <h3>5.1 Applicable Fees</h3>
                <p>The use of the Card may be subject to the following fees:</p>
                <ul>
                    <li><strong>Card Issuance Fee:</strong> A one-time fee for issuing a new or replacement card.</li>
                    <li><strong>Transaction Fees:</strong> Certain transactions, such as foreign currency conversions,
                        may incur fees.</li>
                    <li><strong>ATM Withdrawal Fees:</strong> A fixed or percentage-based fee may apply to cash
                        withdrawals.</li>
                    <li><strong>Crypto-to-Fiat Conversion Fees:</strong> If cryptocurrency is converted to fiat for
                        spending, exchange fees may apply.</li>
                    <li><strong>Inactivity Fees:</strong> If the Card remains unused for a prolonged period, a
                        maintenance fee may be charged.</li>
                </ul>

                <h3>5.2 Transaction and Withdrawal Limits</h3>
                <p>The Card is subject to daily and monthly spending, withdrawal, and deposit limits, which are
                    determined based on:</p>
                <ul>
                    <li>KYC verification level (higher verification may grant increased limits).</li>
                    <li>Issuer policies and regulatory compliance requirements.</li>
                    <li>Transaction history and risk assessment.</li>
                </ul>
                <p>Users can review applicable fees and limits in the Credible app or website.</p>

                <h2>6. Security and Fraud Prevention</h2>
                <h3>6.1 User Responsibilities</h3>
                <p>The Cardholder must:</p>
                <ul>
                    <li>Keep the PIN, CVV, and Card details confidential at all times.</li>
                    <li>Immediately report lost or stolen cards via the Credible app or customer support.</li>
                    <li>Regularly monitor transactions for unauthorized activities.</li>
                    <li>Use only secure internet connections for online transactions.</li>
                </ul>

                <h3>6.2 Unauthorized Transactions and Liability</h3>
                <ul>
                    <li>If an unauthorized transaction occurs, the User must report it within 24 hours.</li>
                    <li>Users may be liable for up to €50 (or equivalent) in losses due to unauthorized use if they
                        failed to safeguard their credentials.</li>
                    <li>If fraud, negligence, or misuse is detected, the full financial loss may be the User's
                        responsibility.</li>
                </ul>

                <h3>6.3 Freezing and Blocking the Card</h3>
                <p>Credible and the issuer may suspend, restrict, or terminate the Card under the following conditions:
                </p>
                <ul>
                    <li>Suspected fraud or unauthorized use.</li>
                    <li>Violation of these Terms and Conditions.</li>
                    <li>Regulatory or compliance violations.</li>
                </ul>
                <p>If the Card is frozen due to suspicious activity, the User may be required to provide additional
                    identity verification.</p>

                <h2>7. Dispute Resolution</h2>
                <h3>7.1 Filing a Dispute</h3>
                <p>If a transaction is disputed, the User must submit a claim within 45 days of the transaction date.
                    The claim must include:</p>
                <ul>
                    <li>Transaction details (date, amount, merchant name).</li>
                    <li>Reason for the dispute (e.g., fraudulent transaction, service not received).</li>
                    <li>Any supporting documents (receipts, screenshots, or police reports).</li>
                </ul>

                <h3>7.2 Resolution Process</h3>
                <p>The issuer will investigate the dispute and provide a resolution within 30 to 60 days.</p>
                <p>If a refund is approved, funds will be credited back to the User's Card balance or linked account.
                </p>
                <p>If the dispute is denied, the User may appeal the decision or seek arbitration under governing law.
                </p>

                <h2>8. Termination and Card Expiry</h2>
                <h3>8.1 Termination by User</h3>
                <p>Users may request card cancellation at any time by:</p>
                <ul>
                    <li>Contacting Credible support via app, website, or phone.</li>
                    <li>Withdrawing any remaining funds (if applicable).</li>
                    <li>Destroying the physical card (if issued).</li>
                </ul>

                <h3>8.2 Termination by Credible</h3>
                <p>Credible reserves the right to terminate or suspend the Card without prior notice in cases of:</p>
                <ul>
                    <li>Fraudulent activity or security breaches.</li>
                    <li>Violation of these Terms.</li>
                    <li>Regulatory or compliance obligations.</li>
                </ul>

                <h3>8.3 Card Expiry and Renewal</h3>
                <ul>
                    <li>Cards have a predefined validity period (usually 3-5 years).</li>
                    <li>Users may be notified before expiry and provided with options for renewal or closure.</li>
                </ul>

                <h2>9. Contact Information</h2>
                <p><strong>Address:</strong> [Credible's Physical Address]</p>
                <p><strong>Phone:</strong> [Credible's Phone Number]</p>
                <p><strong>Email:</strong> <a
                        href="mailto:support@credibleplatform.com">support&#64;credibleplatform.com</a></p>

            </div>
        </div>
    </div>

</div>