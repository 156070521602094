<div class="decentralized-finance-terms-container">

    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="decentralized-finance-content">

            <h1 class="heading">Decentralized Finance (DeFi) Terms of Service </h1>
            <br>
            <br>
            <h5>Effective Date: [Date]
            </h5>
            <h5>Last Updated: [Date]
            </h5>
            <br>
            <div class="paragraph">
                <h2>1. Introduction</h2>
                <p>Welcome to Credible ("we," "our," or "us"), a non-custodial Decentralized Finance (DeFi) platform
                    that provides users with access to various blockchain-based financial services, including but not
                    limited to lending, borrowing, staking, yield farming, token swaps, and decentralized identity
                    verification. These services are built on smart contract technology, ensuring transparency and
                    automation without requiring intermediaries.</p>
                <p>By accessing or using Credible, you acknowledge that you fully understand and accept the risks
                    associated with DeFi services and agree to abide by the terms outlined in this DeFi Terms of Service
                    Agreement ("Terms"). If you do not agree to these Terms, you must discontinue use of our platform
                    immediately.</p>
                <p>These Terms form a legally binding agreement between you ("User") and Credible, governing your use of
                    our platform. Please read these Terms carefully, as they contain important information regarding
                    eligibility, compliance, risks, security, and dispute resolution.</p>

                <h2>2. Definitions</h2>
                <p>To ensure clarity, the following terms are defined for the purposes of this Agreement:</p>
                <ul>
                    <li><strong>"Credible"</strong> -- The non-custodial DeFi platform, its affiliates, and
                        subsidiaries.</li>
                    <li><strong>"User" or "You"</strong> -- Any individual or entity accessing and using Credible's DeFi
                        services.</li>
                    <li><strong>"Smart Contracts"</strong> -- Self-executing programs deployed on blockchain networks
                        that automate financial transactions without intermediaries.</li>
                    <li><strong>"Non-Custodial"</strong> -- A system where users maintain full control of their assets
                        without Credible holding or managing funds.</li>
                    <li><strong>"Decentralized Autonomous Organization (DAO)"</strong> -- A governance structure
                        allowing users to vote on platform upgrades and decisions.</li>
                    <li><strong>"Blockchain"</strong> -- A decentralized, distributed ledger used to record transactions
                        securely and immutably.</li>
                    <li><strong>"Staking"</strong> -- The process of locking digital assets in a smart contract to
                        support network operations and earn rewards.</li>
                    <li><strong>"Yield Farming"</strong> -- A strategy that allows users to earn returns by providing
                        liquidity to DeFi protocols.</li>
                    <li><strong>"Liquidity Pools"</strong> -- Smart contract-based reserves that facilitate
                        decentralized trading, lending, and other financial activities.</li>
                    <li><strong>"Gas Fees"</strong> -- Blockchain transaction costs required to execute smart contracts
                        and process transactions.</li>
                </ul>

                <h2>3. Eligibility and User Obligations</h2>
                <h3>3.1 Eligibility Criteria</h3>
                <p>To use Credible's services, you must meet the following criteria:</p>
                <ul>
                    <li>You must be at least 18 years old or the legal age of majority in your jurisdiction.</li>
                    <li>You must not be a resident of restricted countries where DeFi services are prohibited due to
                        legal or regulatory constraints.</li>
                    <li>You must not be on any sanctions list, including those maintained by the Office of Foreign
                        Assets Control (OFAC), the European Union, or other global regulatory bodies.</li>
                    <li>You must use our platform for lawful purposes only and not engage in any prohibited activities
                        such as money laundering, fraud, or financing of terrorism.</li>
                </ul>

                <h3>3.2 User Responsibilities</h3>
                <ul>
                    <li><strong>Self-Custody of Assets:</strong> Since Credible is a non-custodial platform, you are
                        solely responsible for securing your private keys, wallet credentials, and recovery phrases.
                        Loss of access to your wallet means irreversible loss of assets, and Credible cannot assist in
                        recovery.</li>
                    <li><strong>Risk Awareness:</strong> You acknowledge that DeFi involves high financial risks, smart
                        contract vulnerabilities, impermanent loss, regulatory uncertainty, and potential hacking
                        incidents. You assume full responsibility for your decisions.</li>
                    <li><strong>Compliance with Laws:</strong> You agree to comply with all applicable local, national,
                        and international laws regarding digital assets and blockchain transactions.</li>
                    <li><strong>Security Measures:</strong> You are responsible for implementing appropriate security
                        measures, such as two-factor authentication (2FA), hardware wallets, and regular security audits
                        of your digital assets.</li>
                </ul>

                <h2>4. Services Provided</h2>
                <p>Credible provides the following DeFi services, subject to the conditions outlined in this Agreement:
                </p>

                <h3>4.1 Lending and Borrowing</h3>
                <p>Users can lend their digital assets to liquidity pools and earn interest based on supply and demand
                    dynamics. Borrowers can access funds by providing collateral, which is subject to liquidation if the
                    loan-to-value (LTV) ratio exceeds the limit set by smart contracts.</p>

                <h3>4.2 Staking and Yield Farming</h3>
                <p>Users can stake their tokens to earn network rewards or participate in yield farming strategies by
                    providing liquidity to decentralized protocols. Returns are variable and dependent on market
                    conditions, protocol fees, and staking duration.</p>

                <h3>4.3 Decentralized Swaps and Liquidity Pools</h3>
                <p>Users can swap tokens through automated market makers (AMMs) without intermediaries. Liquidity
                    providers earn a share of transaction fees in proportion to their contribution to liquidity pools.
                </p>

                <h3>4.4 Governance and Voting</h3>
                <p>Credible may implement DAO-based governance where users can propose and vote on protocol changes.
                    Governance tokens may be required to participate in decision-making processes.</p>

                <h2>5. Risks and Disclaimers</h2>
                <h3>5.1 Market and Investment Risks</h3>
                <p>DeFi markets are highly volatile, and asset values can fluctuate drastically. Users acknowledge that
                    past performance is not indicative of future results.</p>

                <h3>5.2 Smart Contract Risks</h3>
                <p>Smart contracts are immutable once deployed. If a bug, vulnerability, or exploit exists in the code,
                    funds may be permanently lost, and Credible bears no responsibility for such incidents.</p>

                <h3>5.3 Regulatory Risks</h3>
                <p>DeFi regulations are evolving, and governments may introduce new laws that impact digital assets,
                    taxation, and platform operations. Users must stay informed about compliance requirements in their
                    jurisdiction.</p>

                <h3>5.4 Third-Party Risks</h3>
                <p>Credible integrates with third-party services such as wallet providers, liquidity pools, and
                    custodial partners. We do not own or control these services and are not liable for their security or
                    performance issues.</p>

                <h2>6. Fees and Gas Costs</h2>
                <ul>
                    <li><strong>Transaction Fees:</strong> Users are responsible for covering all gas fees required for
                        executing smart contract transactions on blockchain networks.</li>
                    <li><strong>Protocol Fees:</strong> Credible may charge service fees for specific transactions,
                        including lending, borrowing, staking, and swapping. Fee structures will be transparently
                        disclosed on our platform.</li>
                    <li><strong>Slippage and Price Impact:</strong> Token swaps may experience slippage, meaning the
                        final transaction price may differ from the quoted price. Users should review all details before
                        confirming transactions.</li>
                </ul>

                <h2>7. Limitation of Liability</h2>
                <p>To the maximum extent permitted by law, Credible shall not be liable for:</p>
                <ul>
                    <li>Any loss of funds, data, or assets due to user error, smart contract failure, or hacking
                        incidents.</li>
                    <li>Market losses, impermanent loss, or adverse financial impacts resulting from price fluctuations.
                    </li>
                    <li>Security breaches, phishing attacks, or compromised wallets caused by user negligence.</li>
                    <li>Actions taken by regulatory authorities that affect the availability of DeFi services.</li>
                </ul>

                <h2>8. Dispute Resolution</h2>
                <p>In the event of disputes between users and Credible, the following process applies:</p>
                <ul>
                    <li><strong>Informal Resolution:</strong> Users should first attempt to resolve disputes by
                        contacting Credible's support team.</li>
                    <li><strong>Arbitration:</strong> If a dispute cannot be resolved informally, it shall be settled
                        through binding arbitration, conducted under the rules of an internationally recognized
                        arbitration body.</li>
                    <li><strong>Governing Law:</strong> These Terms shall be governed by the laws of [Jurisdiction],
                        without regard to conflict of law principles.</li>
                </ul>

                <h2>9. Amendments and Termination</h2>
                <p>Credible reserves the right to modify these Terms at any time. Users will be notified of major
                    changes via email or platform announcements. Continued use of the platform after amendments take
                    effect constitutes acceptance of the revised Terms.</p>
                <p>Users who wish to terminate their account must withdraw funds and discontinue interactions with smart
                    contracts, as blockchain-based transactions are irreversible.</p>

                <h2>10. Contact Information</h2>
                <p>📧 <strong>Email:</strong> <a
                        href="mailto:support@credibleplatform.com">support&#64;credibleplatform.com</a></p>
                <p>🏢 <strong>Address:</strong> [Credible's Physical Address]</p>
            </div>
        </div>
    </div>

</div>