import { Transaction} from '@solana/web3.js';
import getSolanaConnection from './getSolanaConnection';

async function getSolanaRawTransaction(transaction: Transaction) {
    const connection = getSolanaConnection();

    console.log('transaction', transaction);

    const signature = await connection.sendRawTransaction(transaction.serialize({
        requireAllSignatures: true,
        verifySignatures: true
    }), {
        maxRetries: 0,
        skipPreflight: true
    });

    return signature;
}

export default getSolanaRawTransaction;