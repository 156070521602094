import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, Input, NgZone, OnChanges, OnInit,
    PLATFORM_ID,
    SimpleChanges
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import {Transaction} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';
import { isPlatformBrowser} from '@angular/common';
import { StakingService } from '../staking.service';
import {
    MatDialog, MatDialogRef 
} from '@angular/material/dialog';
import { ChangeBlockchainDialogComponent } from '../change-blockchain-dialog/change-blockchain-dialog.component';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import { ChangeChainDialogComponent } from '../change-chain-dialog/change-chain-dialog.component';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';
import { MetamaskService } from '../metamask.service';
import getSolanaConnection from '../../helpers/getSolanaConnection';
import { nullChecker } from '../../helpers/nullChecker';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-new-staking-claim',
    templateUrl: './new-staking-claim.component.html',
    styleUrls: [ './new-staking-claim.component.scss' ]
})
export class NewStakingClaimComponent implements OnInit, OnChanges {
    currencyDropdownOptions: Array<IDropdown> = [
        {
            label: 'vCRED',
            value: 'cred',
            logo: this.httpService.getCurrencyUrl('vcred')
        }
    ];

    amount: number = 0;
    unstaked: number = 0;
    currency: string = 'vCred';
    @Input() data: any;

    loading: boolean = false;
    btn_loading: boolean = false;

    current_staking_index: number = 0;
    show_no_stakings: boolean = false;

    confirm_btn_label: string = 'Confirm';
    balanceInfo: any;
    staking_reward_withdrawal_id: string = '';

    showConfirmButton: boolean = false;
    showAllowanceButton: boolean = false;
    differentNetwork: boolean = false;
    differentBlockchain: boolean = false;
    isBrowser: boolean = false;

    balance: number = 0;
    sol_balance: number = 0;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private stakingService: StakingService,
        private solflareService: SolflareService,
        public walletService: WalletService,
        private router: Router,
        private dialog: MatDialog,
        private genesisService: GenesisService,
        private blockchainDialogRef: MatDialogRef<ChangeBlockchainDialogComponent>,
        private networkDialogRef: MatDialogRef<ChangeChainDialogComponent>,
        private connectWalletDialogRef: MatDialogRef<ConnectWalletComponent>,
        private ngZone: NgZone,
        private metamaskService: MetamaskService,
        @Inject(PLATFORM_ID) platformId: object

    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        if (this.data.staking_reward_info)
            this.amount = this.data.staking_reward_info.unclaimed_amount;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['data'] && changes['data'].currentValue) {
            this.data = changes['data'].currentValue;
    
            if (this.walletService.isWalletConnected()){
                if (this.data.blockchain === 'solana') {
                    this.showConfirmButton = true;
                    this.showAllowanceButton = false;
                }
    
                else {
                    this.showConfirmButton = false;
                    this.showAllowanceButton = true;
                }

                this.getGasFeeBalance(this.data.blockchain, this.data.chain);
            }
        }
    }

    getStakingRewardBalance() {
        this.showLoader();

        const body = {
            currency: 'cred'
        };

        this.stakingService.getStakingRewardBalance(body).subscribe(
            (res) => {
                this.balanceInfo = res.data;

                if (this.balanceInfo) this.amount = this.balanceInfo.balance;
                else this.amount = 0;

                this.hideLoader();
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            }
        );
    }

    showLoader() {
        this.show_no_stakings = false;
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    claimStakingReward() {
        if (
            nullChecker(this.amount) || isNaN(this.amount) || this.amount <= 0
        ) {
            this.cfAlertService.showError(
                new HttpErrorResponse({
                    error: {
                        message: 'Insufficient balance'
                    }
                })
            );

            return;
        }

        if (this.sol_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient SOL balance', true);

            return;
        }

        const body = {
            currency: this.currency,
            wallet_address: this.walletService.wallet_address
        };

        this.btn_loading = true;

        this.genesisService.claimStakingReward(body).subscribe(
            (res: any) => {
                this.btn_loading = false;
                this.staking_reward_withdrawal_id = res.data.staking_reward_withdrawal_id;
                const transaction = Transaction.from(
                    Buffer.from(res.data.transaction, 'base64')
                );

                this.sendTransaction(transaction);
            },
            (err: HttpErrorResponse) => {
                console.error('claimReferralReward error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    updateStakingRewardClaimSignature(signature: string, error_code: number) {
        const body = {
            staking_reward_withdrawal_id: this.staking_reward_withdrawal_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.genesisService.updateStakingRewardClaimSignature(body).subscribe(
            (res: any) => {
                this.btn_loading = false;

                this.cfAlertService.showMessage('Reward claimed');
                // this.showModal = false;

                this.router.navigate([ '/staking' ], {
                    queryParams: {
                        data: 2
                    }
                });
            },
            (err: HttpErrorResponse) => {
                console.error('updateReferralRewardClaim error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(
                transaction
            );

            console.log('signTransaction', response);

            return response;
        }

        else if (this.walletService.current_provider === 'solflare') {
            const response = await this.solflareService.signTransaction(transaction);

            return response;
        }

        return undefined;
    }

    async sendTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature) return;

            transaction = res;

            const connection = getSolanaConnection();

            console.log('transaction', transaction);

            txnSignature = await connection.sendRawTransaction(
                transaction.serialize({
                    requireAllSignatures: true,
                    verifySignatures: true
                })
            );

            console.log('sendTransaction : txn signature => ', txnSignature);
        }
        catch (err: any) {
            console.error('sendTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split(
                'custom program error:'
            );

            if (split_items.length > 0) {
                error_code = parseInt(
                    split_items[split_items.length - 1].trim(),
                    16
                );
            }

            console.log('sendTransaction : error_code => ', error_code);
        }
        finally {
            this.updateStakingRewardClaimSignature(txnSignature, error_code);
        }
    }

    connectWallet() {
        this.connectWalletDialogRef = this.dialog.open(ConnectWalletComponent, {
            width: '550px',
            height: 'auto',
            data: {}
        });

        this.connectWalletDialogRef.afterClosed().subscribe((status: string) => {
            this.ngZone.run(async () => {
                console.log('connectWallet afterClosed', status);

                if (this.walletService.isWalletConnected()) {
                    if (this.data.blockchain !== this.walletService.blockchain) {
                        console.log(this.data.blockchain, this.walletService.blockchain);
                        this.openChangeBlockchainDialog();
                    }

                    else {
                        const networkMatched = await this.openChangeChainDialog();

                        if (!networkMatched) {
                            this.differentNetwork = true;

                            return;
                        }
                    }

                    this.showConfirmButton = true;

                    console.log('showConfirmButton', this.showConfirmButton);
                    console.log('showAllowanceButton', this.showAllowanceButton);
                    console.log('differentBlockchain', this.differentBlockchain);
                    console.log('differentNetwork', this.differentNetwork);
                    console.log('current_provider', this.walletService.current_provider);

                    this.getCurrencyBalance(this.walletService.blockchain, this.data.chain, this.data.payment_currency_mint_address);
                    this.getGasFeeBalance(this.walletService.blockchain, this.data.chain);
                }

                else
                    console.log('wallet not connected');
            });
        });
    }

    openChangeBlockchainDialog() {
        console.log('openChangeBlockchainDialog');

        this.differentBlockchain = true;

        this.blockchainDialogRef = this.dialog.open(ChangeBlockchainDialogComponent, {
            width: '500px',
            data: {
                expected_blockchain: 'this.node.blockchain',
                current_blockchain: this.walletService.blockchain
            }
        });

        this.blockchainDialogRef.afterClosed().subscribe(result => {
            if (result)
                console.log('result', result);
        });
    }

    async openChangeChainDialog() {
        console.log('openChangeChainDialog', this.walletService.blockchain);

        if (this.walletService.blockchain === 'solana') {
            if (this.data.chain === 'solana')
                return true;
        }

        const chainId = await this.metamaskService.getChainId();

        this.cfAlertService.showMessage(`Chain id: ${chainId}`, false);

        console.log('chainId', chainId);

        const chainIdDecimal = parseInt(chainId, 16);

        console.log('chainIdDecimal', chainIdDecimal);

        const currentChainInfo = ETHEREUM_CHAINS[chainIdDecimal];

        console.log('currentChainInfo', currentChainInfo);

        console.log(this.data.chain_id, currentChainInfo.chainId);

        if (this.data.chain_id === currentChainInfo.chainId)
            return true;

        this.differentNetwork = true;

        this.networkDialogRef = this.dialog.open(ChangeChainDialogComponent, {
            width: '500px',
            data: {
                expected_chain: this.data.chain_id,
                current_chain: currentChainInfo.chainId
            }
        });

        this.networkDialogRef.afterClosed().subscribe(result => {
            if (result)
                console.log('result', result);
        });

        return false;
    }

    getCurrencyBalance(blockchain: string, chain: string, mint_address: string) {
        if (!this.isBrowser)
            return;

        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address).subscribe((res: any) => {
            this.sol_balance = res.data;

            if (this.data.blockchain === 'solana') {
                this.showConfirmButton = true;
                this.showAllowanceButton = false;
            }

            else {
                this.showConfirmButton = false;
                this.showAllowanceButton = true;
            }
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}
