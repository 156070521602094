import { Injectable } from '@angular/core';
import { getWindow } from 'ssr-window';
import Solflare from '@solflare-wallet/sdk';
import {
    PublicKey, Transaction 
} from '@solana/web3.js';
import { WalletService } from './wallet.service';
import { TransactionOrVersionedTransaction } from '@solflare-wallet/sdk/lib/cjs/types';

@Injectable({
    providedIn: 'root'
})
export class SolflareService {
    window: Window;
    wallet: Solflare | undefined;
    wallet_address: string = '';

    constructor(private walletService: WalletService) {
        this.window = getWindow();
        
        this.setProvider();
        this.attachProviderEvents();
    }

    isPresent() {
        return 'solflare' in this.window;
    }

    setProvider() {
        if ('solflare' in this.window) 
            this.wallet = new Solflare();
    }

    async connectWallet() {
        console.log('connectWallet');

        await this.wallet?.connect();
    }

    attachProviderEvents() {
        console.log('attachProviderEvents');

        this.wallet?.on('connect', async (publicKey: PublicKey) => {
            const address = publicKey.toBase58();

            console.log('solflare connect', address);

            this.wallet_address = address;

            this.walletService.setEvent('solflare', 'connect', {
                wallet_address: this.wallet_address
            });

            await this.signMessage(new PublicKey(address));
        });

        this.wallet?.on('disconnect', () => {
            console.log('disconnected');

            this.disconnectWallet();

            this.walletService.setEvent('solflare', 'disconnect', {
                wallet_address: this.wallet_address
            });
        });

        this.wallet?.on('accountChanged', (publicKey: PublicKey) => {
            if (publicKey) {
                // Set new public key and continue as usual
                console.log(`Switched to account ${publicKey.toBase58()}`);

                this.walletService.setEvent('solflare', 'disconnect', {
                    old_address: this.wallet_address,
                    new_address: publicKey.toBase58()
                });
            }
            else {
                // Attempt to reconnect to Solflare
                this.wallet?.connect().catch((error: Error) => {
                    console.error(error);
                });
            }
        });
    }

    disconnectWallet() {
        console.log('disconnectWallet');

        this.wallet?.disconnect();
    }

    async signMessageLedger(publicKey: PublicKey) {
        console.log('signMessageLedger', publicKey);

        const tx = this.walletService.buildAuthTx(this.walletService.messageForSigning);

        tx.feePayer = publicKey; // not sure if needed but set this properly
        tx.recentBlockhash = await this.walletService.getLatestBlockhash();

        // Encode and send tx to signer, decode and sign
        const signedTx = await this.wallet?.signTransaction(tx) as Transaction;

        console.log('signedTx', signedTx);

        if (!signedTx) {
            this.walletService.setEvent('solflare', 'signMessage', {
                status: 'failure'
            });

            return;
        }

        const serializedTx = signedTx.serialize();

        const validSignature = this.walletService.validateAuthTx(signedTx, this.walletService.messageForSigning);

        console.log('validSignature', validSignature);

        if (!validSignature) {
            this.walletService.setEvent('solflare', 'signMessage', {
                status: 'failure'
            });
        }

        else {
            console.log('signature', signedTx?.signature);

            this.walletService.setEvent('solflare', 'signMessage', {
                status: 'success',
                serializedTx: serializedTx,
                current_provider: 'solflare',
                blockchain: 'solana',
                wallet_address: this.wallet_address
            });
        }
    }

    async signMessageNonLedger() {
        console.log('signMessageNonLedger');

        const messageBytes = new TextEncoder().encode(this.walletService.messageForSigning);

        const signature: Uint8Array | undefined = await this.wallet?.signMessage(messageBytes, 'utf8');

        console.log('signature', signature);

        if (!signature) {
            this.walletService.setEvent('solflare', 'signMessage', {
                status: 'failure'
            });
        }

        else {
            this.walletService.setEvent('solflare', 'signMessage', {
                status: 'success',
                signature: Array.from(signature),
                current_provider: 'solflare',
                blockchain: 'solana',
                wallet_address: this.wallet_address
            });
        }
    }

    async signMessage(publicKey: PublicKey) {
        console.log('signMessage', publicKey);

        if (this.walletService.ledger)
            await this.signMessageLedger(publicKey);

        else
            await this.signMessageNonLedger();
    }

    signAndSendTransaction(transaction: Transaction) {
        console.log('signAndSendTransaction', transaction);

        return this.wallet?.signAndSendTransaction(transaction);
    }

    signTransaction(transaction: Transaction): Promise<Transaction> {
        console.log('signTransaction', transaction);

        return new Promise((resolve, reject) => {
            this.wallet?.signTransaction(transaction).then((value: TransactionOrVersionedTransaction) => {
                console.log('value', value);

                const response = value as Transaction;
        
                console.log('response', response);

                resolve(response);
            }).catch((error: Error) => {
                console.error(error);

                reject(error);
            });
        });
    }

    downloadWallet() {
        console.log('downloadWallet');

        this.window.open('https://solflare.com/', '_blank');
    }
}
