<div class="terms-condition-container">
    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="terms-content">
            <h1 class="heading">Terms and conditions</h1>
            <br>
            <br>
            <h5>Last Updated: 20/01/2025
            </h5>
            <br>
            <div class="paragraph">
                <p>Welcome to Kiwimoney Inc. DBA Credible. By accessing or using our platform and services (the
                    "Services"), you agree to comply with and be bound by the following Terms and Conditions ("Terms").
                    If you do not agree to these Terms, you should not use our platform.</p>
                <p>Credible is a non-custodial platform, meaning that users retain full control over their digital
                    assets. Unlike custodial services, Credible does not store or manage user funds. However, for
                    certain features, custodial services are provided by Circle, and IBAN integration is facilitated by
                    Bridge. Please review these Terms carefully to understand your rights and obligations.</p>

                <h2>1. Definitions</h2>
                <p><strong>"User"</strong>: An individual or entity that uses the platform, creates an account, or
                    engages with the services provided by Credible.</p>
                <p><strong>"Platform"</strong>: Refers to the website, application, or services provided by Credible for
                    digital asset transactions.</p>
                <p><strong>"Credible Services"</strong>: The features provided through the platform, including asset
                    management, transaction processing, and integration with Circle for custodial services and Bridge
                    for IBAN transactions.</p>
                <p><strong>"Circle"</strong>: A third-party partner responsible for providing custodial services for
                    certain assets, in compliance with applicable laws.</p>
                <p><strong>"Bridge"</strong>: A third-party service provider facilitating IBAN transactions for
                    fiat-digital asset interactions.</p>
                <p><strong>"Digital Assets"</strong>: Cryptocurrencies or other digital tokens that users manage through
                    the platform.</p>
                <p><strong>"Fiat Currency"</strong>: Government-issued currency that is accepted in exchange for digital
                    assets via IBAN services.</p>

                <h2>2. Account Registration</h2>
                <p>To access certain features of the platform, you must create an account. You agree to:</p>
                <ul>
                    <li>Provide accurate, current, and complete information.</li>
                    <li>Maintain the security of your credentials.</li>
                    <li>Accept responsibility for all activities under your account.</li>
                    <li>Comply with Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations.</li>
                </ul>

                <h2>3. Use of Services</h2>
                <h3>3.1 Non-Custodial Nature</h3>
                <p>Credible does not hold or control user assets. Users retain full ownership and are responsible for
                    securing their funds, including private keys and digital wallets.</p>
                <h3>3.2 Custodial Services by Circle</h3>
                <p>For specific services requiring custody, Credible partners with Circle. Any assets held under custody
                    will be governed by Circle's terms, and users acknowledge that all custodial transactions will be
                    subject to Circle's policies. Users must ensure compliance with Circle's requirements.</p>
                <h3>3.3 IBAN Services via Bridge</h3>
                <p>IBAN services allow fiat transactions linked to digital asset accounts. These services are provided
                    by Bridge, and users must comply with any verification or compliance requirements imposed by the
                    provider. Transactions involving fiat currency are subject to regulatory requirements in relevant
                    jurisdictions.</p>
                <h3>3.4 Prohibited Uses</h3>
                <p>Users may not:</p>
                <ul>
                    <li>Engage in illegal activities, including fraud, money laundering, and terrorist financing.</li>
                    <li>Use the platform to conduct unauthorized financial services.</li>
                    <li>Manipulate or disrupt the platform's security or operations.</li>
                    <li>Circumvent applicable laws and regulations.</li>
                </ul>

                <h2>4. User Responsibilities</h2>
                <p>Users agree to:</p>
                <ul>
                    <li>Comply with all applicable laws and regulations.</li>
                    <li>Provide accurate and updated information.</li>
                    <li>Secure their account and not share credentials.</li>
                    <li>Avoid engaging in fraud, money laundering, or illegal activities.</li>
                    <li>Acknowledge the risks of digital asset volatility and accept full responsibility for their
                        transactions.</li>
                </ul>

                <h2>5. OBLIGATIONS AND WARRANTIES</h2>
                <p>1. You hereby declare, warrant, and confirm that:</p>
                <ol>
                    <li>You meet the conditions under Section IV;</li>
                    <li>You validly undertake any action or enter into any transaction with regard to the Agreement;
                    </li>
                    <li>The Digital Assets, which you will top up into your Credible Account, or the funds, which you
                        will use for the purchase of the Digital Assets to be topped up into your Credible Account, as
                        the case may be, are not derived from money laundering, terrorist financing, fraud, or any other
                        illegal or criminal activity under any Applicable Law;</li>
                    <li>You have submitted to Credible only complete, correct, and up-to-date information and documents;
                    </li>
                    <li>You are aware that you are subject to the tax regulation in your jurisdiction and shall be fully
                        responsible for any filing/reporting and paying any tax due to the competent tax authority, as
                        required by the Applicable Law. Credible shall not be required to compensate you for your tax
                        obligations or advise you in relation to your tax issues. The uncertainty in tax legislation
                        relating to the Digital Assets may expose you to any currently unknown or future tax
                        consequences, associated with your holding of Digital Assets and the use of the Credible
                        Services, for which Credible shall not be held liable. You shall hold Credible harmless from any
                        expenses and losses, resulting from the above uncertainty;</li>
                    <li>You are aware that you use the Credible Account, the Credible Platform, and the Credible
                        Services fully at your own risk.</li>
                </ol>
                <p>2. You hereby undertake to:</p>
                <ol>
                    <li>Use the Credible Account, the Credible Platform, and the Credible Services in good faith;</li>
                    <li>Not violate or breach any terms of these Terms, the Privacy Policy and the Cookies Policy, or
                        any Applicable Law;</li>
                    <li>Not commit, conduct, or perform any misrepresentation, fraud, malicious act, or criminal
                        activities;</li>
                    <li>Not use the Credible Account, the Credible Platform, and the Credible Services to conduct
                        electronic spamming or otherwise distribute any unsolicited or unauthorised advertising,
                        promotional or marketing materials, junk mail, or chain letters;</li>
                    <li>Not provide false, inaccurate, incomplete, out-of-date, or misleading information;</li>
                    <li>Not interfere, intercept, or expropriate our network, system, data, or information;</li>
                    <li>Not transmit or upload any virus or other malicious software, or program, or try to gain
                        unauthorised access to other Clients' accounts, websites, networks, or systems relating to the
                        Credible Services;</li>
                    <li>Not decompile, reverse engineer, or disassemble any of our programs, systems, or products, or in
                        any way infringe the Intellectual Property rights and/or such of our partners;</li>
                    <li>Not cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or
                        otherwise data mining any portion of any of our programs, systems, or products, or unduly
                        burdening or hindering the operation and/or functionality of any aspect of the Credible
                        Services;</li>
                    <li>Not use the Credible Platform in a way that could damage, disable, impair, or compromise the
                        Credible Platform or the provision of the Credible Services, interfere with other Clients, or
                        affect the reputation of Credible. You undertake not to gain or attempt to gain unauthorised
                        access to the Credible Accounts or the Digital Asset Wallets of other Clients;</li>
                    <li>Not take any action that imposes an unreasonable or disproportionately large burden or load on
                        the Credible's infrastructure (including our servers, networks, data centres, and related
                        equipment) and detrimentally interfere with, intercept, or expropriate any system, data, or
                        information belonging to other Clients using the Credible Services;</li>
                    <li>Not engage in any written or verbal communication or conduct towards Credible, its affiliates,
                        third-party service providers, each of their respective employees, officers, directors, and
                        representatives, which may in any way be deemed harmful, threatening, aggressive, abusive,
                        inappropriate, disparaging, or in any manner disrespectful;</li>
                    <li>Keep your device safe and maintain adequate security and control of your username and password,
                        including but not limited to keep such username and password only to your knowledge and not
                        disclose them to any third party;</li>
                    <li>Inform Credible about any change in the information and documents submitted to Credible, in
                        particular in your contact details, within 1 (one) day of the change.</li>
                </ol>

                <h2>6. Fees and Charges</h2>
                <p>Certain services may be subject to fees, which will be disclosed at the time of the transaction.
                    Users are responsible for any third-party charges imposed by Circle or Bridge. Credible reserves the
                    right to update fee structures with prior notice.</p>

                <h2>7. LIMITATION OF LIABILITY</h2>
                <p>1. ALL THE Credible SERVICES ARE PROVIDED 'AS IS' AND WITHOUT ANY REPRESENTATIONS OR WARRANTIES,
                    WHETHER EXPRESS, IMPLIED, OR STATUTORY, EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, TO THE EXTENT
                    PERMITTED BY APPLICABLE LAW. Credible DISCLAIMS ALL OTHER REPRESENTATIONS OR WARRANTIES, EXPRESS OR
                    IMPLIED, MADE TO YOU, YOUR AFFILIATES OR ANY THIRD PARTY, INCLUDING WITHOUT LIMITATION, ANY
                    WARRANTIES REGARDING QUALITY, SUITABILITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                    OTHERWISE OF ANY SERVICE OR ANY GOODS PROVIDED INCIDENTAL TO THE Credible SERVICES UNDER THESE
                    TERMS.</p>
                <p>Credible'S LIABILITY IN RESPECT OF THE REPRESENTATIONS AND WARRANTIES, WHICH CANNOT BE EXCLUDED, IS
                    LIMITED TO ANY OF THE FOLLOWING OPTIONS CHOSEN BY Credible, AT OUR SOLE AND ABSOLUTE DISCRETION:</p>
                <ol>
                    <li>RE-SUPPLYING, REPLACING, OR REPAIRING THE Credible SERVICES IN RESPECT OF WHICH THE BREACH
                        OCCURRED; OR</li>
                    <li>PAYING THE COST OF THE RE-SUPPLYING, REPLACING, OR REPAIRING OF THE Credible SERVICES IN RESPECT
                        OF WHICH THE BREACH OCCURRED.</li>
                </ol>
                <p>2. NOTWITHSTANDING ANY PROVISIONS IN THESE TERMS, IN NO EVENT SHALL WE OR ANY OF OUR EMPLOYEES,
                    OFFICERS, DIRECTORS, AGENTS, REPRESENTATIVES, PROXIES, OR PARTNERS BE LIABLE TO YOU FOR ANY LOSSES,
                    DAMAGES, OR CLAIMS:</p>
                <ol>
                    <li>DUE TO AN UNUSUAL OR UNFORESEEABLE EVENT OUTSIDE OUR REASONABLE CONTROL, THE CONSEQUENCES OF
                        WHICH COULD NOT HAVE BEEN AVOIDED EVEN IF ALL DUE CARE HAD BEEN EXERCISED (E.G. FORCE MAJEURE,
                        INCLUDING ACTS OF GOD, WAR, OR CIVIL UNREST, DISASTERS, ACTS OF DOMESTIC OR FOREIGN COURTS AND
                        GOVERNMENTAL AUTHORITIES, STRIKES, LOCKOUTS, LABOUR DISPUTES, TERRORIST ACTS, RIOTS);</li>
                    <li>ARISING FROM OR IN CONNECTION WITH:
                        <ol>
                            <li>ANY DELAY, SUSPENSION, DISCONTINUATION, INTERRUPTION OF THE Credible PLATFORM OR THE
                                Credible SERVICES;</li>
                            <li>FAILURE OR INTERRUPTION IN PUBLIC OR PRIVATE TELECOMMUNICATION NETWORKS, COMMUNICATION
                                CHANNELS, OR INFORMATION SYSTEMS;</li>
                            <li>ACTS OR OMISSIONS OF ACTS OF A PARTY FOR WHOM WE ARE NOT RESPONSIBLE;</li>
                            <li>DELAY, FAILURE, OR INTERRUPTION IN, OR UNAVAILABILITY OF, THIRD-PARTY SERVICES;</li>
                            <li>ANY REFUSAL TO PROCESS OR AUTHORIZE, OR ANY REVERSAL OR ADJUSTMENT OF, ANY TRANSACTION
                                FOR ANY REASON;</li>
                            <li>YOUR INABILITY TO EFFECT OR COMPLETE ANY TRANSACTION DUE TO SYSTEM MAINTENANCE,
                                BREAKDOWN, OR NON-AVAILABILITY OF THE Credible PLATFORM OR THE Credible SERVICES;</li>
                            <li>ANY UNAUTHORIZED OR INELIGIBLE USE OF THE Credible SERVICES CONTRARY TO THESE TERMS;
                            </li>
                            <li>ANY TERMINATION, SUSPENSION, LIMITATION, OR REVERSAL OF YOUR USE OF THE Credible
                                SERVICES, OR YOUR ACCESS TO YOUR Credible ACCOUNT (INCLUDING FREEZING OR CLOSING OF THE
                                LATTER);</li>
                        </ol>
                    </li>
                    <li>DUE TO COMPLIANCE WITH ANY APPLICABLE LAW, COURT ORDERS, OR ACTS OF ANY GOVERNMENTAL AUTHORITY;
                    </li>
                    <li>RESULTING FROM HACKING, TAMPERING, COMPUTER VIRUS TRANSMISSION, OR OTHER UNAUTHORIZED ACCESS OR
                        USE OF THE RELEVANT Credible SERVICE, YOUR Credible ACCOUNT OR ANY INFORMATION CONTAINED
                        THEREIN;</li>
                    <li>RESULTING FROM OR ARISING OUT OF ANY THIRD-PARTY SERVICE PROVIDER'S CUSTODY, CONTROL, OR
                        HANDLING OF THE DIGITAL ASSETS, INCLUDING BUT NOT LIMITED TO MALFEASANCE, NEGLIGENCE, OR
                        MISHANDLING. ANY CLAIMS OR DISPUTES REGARDING SUCH MATTERS SHOULD BE DIRECTLY ADDRESSED TO THE
                        RESPECTIVE THIRD-PARTY SERVICE PROVIDER. YOUR RECOURSE IN SUCH CASES SHALL BE SOLELY AGAINST THE
                        RESPECTIVE THIRD-PARTY SERVICE PROVIDER, AND NOT AGAINST Credible;</li>
                    <li>RESULTING FROM ANY RISK OR OTHER CIRCUMSTANCE DISCLOSED TO THE CLIENT UNDER SECTION IX OR
                        ELSEWHERE IN THESE TERMS.</li>
                </ol>
                <p>3. NOTWITHSTANDING ANY PROVISIONS IN THESE TERMS, IN NO EVENT SHALL WE BE LIABLE TO YOU FOR ANY TYPE
                    OF INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT
                    LIMITED TO LOST REVENUE, LOST PROFITS, REPLACEMENT GOODS, LOSS OF TECHNOLOGY, LOSS OF DATA, OR
                    INTERRUPTION OR LOSS OF USE OF SERVICE OR EQUIPMENT, REGARDLESS OF WHETHER SUCH DAMAGES BEING DIRECT
                    OR INDIRECT, FORESEEABLE OR UNFORESEEABLE, OR EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES, AND WHETHER ARISING UNDER THEORY OF CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE.
                </p>
                <p>4. IN NO EVENT WILL OUR AGGREGATE LIABILITY FOR ANY LOSS OR DAMAGE ARISING IN CONNECTION WITH THE
                    Credible SERVICES AND THESE TERMS EXCEED THE FEES YOU PAID TO Credible FOR YOUR USE OF THE RELEVANT
                    Credible SERVICE DURING THE 12-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT, GIVING RISE TO THE
                    CLAIM FOR LIABILITY, IF ANY. THE ABOVE SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY THE RELEVANT
                    APPLICABLE LAW.</p>
                <p>5. WE SHALL NOT BE LIABLE FOR ANY FAULT ON THE PART OF ANY THIRD-PARTY SERVICE PROVIDER INSTRUCTED BY
                    US.</p>
                <p>6. NOTHING IN THESE TERMS SHALL OPERATE TO LIMIT OR EXCLUDE ANY LIABILITY FOR FRAUD OR GROSS
                    NEGLIGENCE.</p>

                <h2>8. DEFAULT. INDEMNIFICATION.</h2>
                <p>1. The occurrence of one of the following events constitutes an Event of Default:</p>
                <ol>
                    <li>The Client breaches any of the Client's obligations or warranties under these Terms and any
                        Exhibit;</li>
                    <li>It is or becomes illegal for the Client to perform the Client's obligations under these Terms
                        and any Exhibit;</li>
                    <li>Credible is unable to contact the Client in accordance with the Client's most recent
                        instructions for sending correspondence.</li>
                </ol>
                <p>2. In case of an Event of Default, Credible is entitled:</p>
                <ol>
                    <li>To terminate this Agreement with immediate effect;</li>
                    <li>To take any action it deems necessary to protect its interests.</li>
                </ol>
                <p>3. You shall defend, indemnify, and hold harmless Credible, its affiliates, third-party service
                    providers, and each of their respective employees, officers, directors, and representatives, from
                    and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable
                    attorney's fees), arising out of or relating to any third-party claim concerning these Terms, any
                    Exhibit, or your use of the Credible Services in violation of these Terms, any Exhibit, or an</p>

                <h2>9. Disclaimers</h2>
                <ul>
                    <li>No Financial Advice: Credible does not provide investment or legal advice.</li>
                    <li>No Guaranteed Returns: Users acknowledge the risks associated with digital assets.</li>
                    <li>Platform Availability: Services may experience temporary disruptions due to maintenance or
                        external factors.</li>
                    <li>Third-Party Risks: Use of third-party services is subject to their terms and conditions.</li>
                </ul>
                <h2>10. Intellectual Property</h2>
                <p>All content and intellectual property on the Credible platform are owned by Credible or its
                    licensors. Users are granted a limited, non-exclusive right to use the platform in compliance with
                    these Terms. Unauthorized reproduction or modification is prohibited.</p>

                <ol>
                    <li>
                        <p>The Intellectual Property remains an exclusive property of Credible. You acknowledge and
                            agree that no part or parts of the Intellectual Property may be reproduced, distributed,
                            republished, displayed, broadcast, hyperlinked, transmitted, adapted, modified to create
                            derivative works, or otherwise commercially exploited in any manner or by any means or
                            stored in an information retrieval system without our prior written permission. You are
                            expressly and emphatically restricted from all of the following:</p>
                        <ol>
                            <li>selling, sublicensing, and/or otherwise commercialising any Intellectual Property,</li>
                            <li>using the Credible Platform in any way that is, or maybe, damaging,</li>
                            <li>using the Credible Platform in any way that impacts Clients' access,</li>
                            <li>using the Credible Platform contrary to Applicable Laws, or in a way that causes, or may
                                cause, harm to the Credible Platform, or to any person or business entity,</li>
                            <li>engaging in any data mining, data harvesting, data extracting, or any other similar
                                activity in relation to the Credible Platform.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Other than the content you own, including any audio, video, text, images, or other material
                            which you may have opted to include on the Credible Platform under these Terms or any
                            Exhibit, Credible and/or its licensors own all rights to the Intellectual Property and
                            material contained, and all such rights are reserved. In regard to your content, you grant
                            Credible a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to
                            use, reproduce, adapt, publish, translate, and distribute it in any and all media. Your
                            content must be your own and must not be infringing on any third party's rights. Credible
                            reserves the right to remove any of your content from the Credible Platform at any time and
                            for any reason without notice.</p>
                    </li>
                    <li>
                        <p>Credible hereby grants you a non-assignable and non-exclusive personal, worldwide, revocable,
                            royalty-free license to use the Credible Platform. However, certain areas of the Credible
                            Platform are restricted from access by you, and Credible may further restrict access by you
                            to any areas, at any time, at its sole and absolute discretion.</p>
                    </li>
                </ol>

                <h2>11. Privacy Policy</h2>
                <p>Use of the platform is governed by the <a href="#">Privacy Policy</a>, detailing how personal data is
                    collected, stored, and used in compliance with applicable regulations.</p>

                <h2>12. Termination of Account</h2>
                <p>Credible reserves the right to suspend or terminate accounts for violations of these Terms. Users may
                    also request account termination, but they remain responsible for any pending transactions or
                    obligations.</p>

                <h2>13. Changes to Terms and Conditions</h2>
                <p>Credible may update these Terms at any time, with notice provided via the platform. Continued use of
                    the platform after changes constitutes acceptance of the revised Terms.</p>

                <h2>14. Governing Law and Dispute Resolution</h2>
                <ol>
                    <li>
                        <p>The Agreement and all Exhibits shall be governed exclusively by the substantive law of the
                            Wilmington, DE.</p>
                    </li>
                    <li>
                        <p>Any dispute arising out of or in connection with the Agreement and all Exhibits, unless
                            amicably settled between the Parties, shall be referred to the competent court in the Cayman
                            Islands, determined as per the procedural law of the Wilmington, DE. You agree that any
                            dispute resolution proceeding shall be conducted only on an individual basis and not as a
                            plaintiff or class member in any purported class, consolidated or representative action or
                            proceeding. No court or other dispute resolution authority can consolidate or join more than
                            one claim and can otherwise preside over any form of a consolidated, representative, or
                            class proceeding. Any relief awarded cannot affect other Credible's Clients.</p>
                    </li>
                </ol>

                <h2>15. Contact Information</h2>
                <ol>
                    <li>
                        <p>Any notice required or made under these Terms or any Exhibit from/by Credible to the Client
                            shall be deemed duly received when sent to the Client's last used e-mail address, mailing
                            address, or phone number. Credible may also issue notices through announcements on the
                            Credible Platform. Such notices shall be deemed duly received by the Client once the latter
                            logs into the Credible Platform after the notice has been posted. If a notice requires the
                            Client's explicit consent, continued use of the Credible Platform and Credible Services by
                            the Client after receiving the notice shall be deemed acceptance of the notice.</p>
                    </li>
                    <li>
                        <p>Any notice required or made under these Terms or any Exhibit from/by the Client to Credible
                            shall only be made via e-mail in English to <a
                                href="mailto:support@Credible.com">support&#64;credible.com</a>.</p>
                    </li>
                    <li>
                        <p>Your feedback, questions, or complaints may be referred to Credible via e-mail in English to
                            <a href="mailto:support@Credible.com">support&#64;credible.com</a> or through other
                            communication channels indicated by Credible from time to time on the Credible Platform. We
                            will do our best to respond as soon as possible. However, for more complicated issues, it
                            may take Credible up to 30 (thirty) days to get back to you. We will not be responsible for
                            any loss or damage suffered as a result of our failure to or delay in responding within the
                            above period.
                        </p>
                    </li>
                    <li>
                        <p>You shall be fully responsible for the security and authenticity of all instructions sent
                            through the Credible Platform, and you shall be bound by all such instructions. We will
                            assume that all the instructions received from your Credible Account are coming from you,
                            and we shall not be obliged to verify this.</p>
                    </li>
                    <li>
                        <p>You accept the risk that your instructions and information may not be protected against
                            unauthorised access when given through the Credible Platform, as being transmitted via the
                            Internet.</p>
                    </li>
                    <li>
                        <p>Any instructions sent to Credible shall only be deemed received by Credible when we have
                            successfully retrieved them from the relevant system and duly informed you of the receipt.
                            Any instructions sent by you to any third parties will only be deemed received by them in
                            accordance with their terms and conditions.</p>
                    </li>
                    <li>
                        <p>You agree that, to the extent there are any terms in your local jurisdiction governing the
                            time and place of dispatch and receipt of electronic communications, such terms will not
                            apply to your use of the Credible Platform and the Credible Services (subject to the maximum
                            extent permitted under Applicable Law), and you will be liable for any damages caused
                            through the use of connectivity means.</p>
                    </li>
                    <li>
                        <p>You acknowledge and agree that in the event of any dispute arising in connection with your
                            use of the Credible Platform and the Credible Services, our records of all matters related
                            to your use of the Credible Platform and the Credible Services on any specific date will be
                            binding on you for all claims and purposes whatsoever. Additionally, you agree to the
                            admissibility of all such records and documents without further requirement of proof of
                            authenticity or accuracy in a court of law or similar institution under any Applicable Law.
                        </p>
                    </li>
                </ol>

                <p>For any questions, contact us at:</p>
                <p><strong>Email:</strong> <a
                        href="mailto:support@credibleplatform.com">support&#64;credibleplatform.com</a></p>
                <p><strong>Address:</strong> [Credible's Physical Address]</p>
                <p><strong>Phone:</strong> [Credible's Phone Number]</p>

                <h2>14. Severability</h2>
                <p>If any provision of these Terms is found invalid, the remaining provisions shall remain enforceable.
                </p>
            </div>
        </div>
    </div>
</div>