/* eslint-disable array-bracket-spacing */
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import IUser from '../../interfaces/IUser';
import User from '../../models/User';
import { HttpService } from '../../services/http.service';
import { getWindow } from 'ssr-window';
import INode from '../../interfaces/INode';
import Node from '../../models/Node';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import ILineConfig from '../charts/line-chart/ILineConfig';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-my-node-details',
    templateUrl: './my-node-details.component.html',
    styleUrls: ['./my-node-details.component.scss']
})
export class MyNodeDetailsComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    currentTime: number = new Date().getTime();
    node: INode = new Node();
    node_id: string = '';
    selected_status: string = '';
    credChartData: Array<any> = [];
    limit: number = 16;
    chartLineConfig: Array<ILineConfig> = [];

    tabs: Array<TabOption> = [
        {
            label: 'Vesting Schedule',
            value: 'vesting-schedule'
        },

        {
            label: 'Bonus Schedule',
            value: 'bonus-schedule'
        },
        {
            label: 'Rewards',
            value: 'rewards'
        },
        {
            label: 'Stakings',
            value: 'my-unstakings'
        }
    ];
    currentTab = '';

    daily_unlock: number = 0;
    unlocked_cred: number = 0;

    bonus_cred: number = 0;

    window: Window;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private genesisService: GenesisService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.currentTab = this.tabs[0].value;

        this.httpService.setMetaData('My Node', 'Node purchased by the user');

        const node_id = this.route.snapshot.paramMap.get('node_id');

        if (node_id) {
            this.node_id = node_id;

            this.getNode();
        }

        this.subscribeQueryParamsObservables();
    }

    subscribeQueryParamsObservables() {
        this.route.queryParams.subscribe((params) => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find((t) => t.value === tab))
                    this.currentTab = tab;
            }
        });
    }

    getNode() {
        this.loading = true;

        this.genesisService.getNode(this.node_id).subscribe(
            (res: any) => {
                this.node = res.data;

                this.daily_unlock = parseFloat(
                    (
                        this.node.cred_receivable / this.node.staking_info.staking_duration
                    ).toFixed(6)
                );

                const currentTime = new Date().getTime();
                const delta_days = Math.floor((currentTime - this.node.created) / (1000 * 60 * 60 * 24));

                this.unlocked_cred = delta_days * this.daily_unlock;

                this.chartLineConfig = [{
                    name: '',
                    data: this.node.rate_chart,
                    lineColor: '#cf0'
                }];

                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    connectWallet() {
        this.router.navigate(['/connect-wallet'], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    getStatusClasses(id: string, status: string) {
        const classes: any = {};

        if (status === 'pending') classes['status-pending'] = true;
        else if (status === 'processing') classes['status-processing'] = true;
        else if (status === 'completed') classes['status-success'] = true;
        else if (status === 'failed') classes['status-failed'] = true;

        return classes;
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                tab: this.currentTab
            },
            queryParamsHandling: 'merge' // remove to replace all query params by provided
        });
    }
    handleData(data: string) {
        this.currentTab = data;
    }

    getTotalCred() {
        this.bonus_cred = this.node.loyalty_percentage / 100 * this.node.cred_receivable;

        return this.node.cred_receivable + this.bonus_cred;
    }
}
