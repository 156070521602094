/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';

@Component({
    selector: 'app-cookies-policy',
    templateUrl: './cookies-policy.component.html',
    styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}
