<div class="app-new-drawdown dark">
    <div *ngIf="!loading">
        <div class="row mt-2">
            <cf-input-currency-dropdown theme="dark" [options]="currencyDropdownOptions" [value]="amount"
                [class]="'input'" label="Enter amount" [selected]="currency" type="number"
                (valueChanged)="amountChanged($event)" (currencyChanged)="currencyChanged($event)" [precision]="2">
            </cf-input-currency-dropdown>
        </div>

        <div class="new-drawdown-summary">
            <div class="summary-item">
                <span class="summary-heading">Whitelisted address</span>
                <span class="summary-value">
                    {{ httpService.sliceAddress(pool.borrower) }}</span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Total amount</span>
                <span class="summary-value">{{
                    amount | number : httpService.getPrecision(currency)
                    }}
                    {{ currency | uppercase }}</span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Fee</span>
                <span class="summary-value">{{
                    fee | number : httpService.getPrecision(currency)
                    }}
                    {{ currency | uppercase }}</span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">You will receive</span>
                <span class="summary-value">{{
                    received_amount
                    | number : httpService.getPrecision(currency)
                    }}
                    {{ currency | uppercase }}</span>
            </div>
        </div>

        <div class="actions">
            <div *ngIf="!differentBlockchain &&
                    !(walletService.current_provider === '' || walletService.current_provider === 'email')">
                <cf-button label="Confirm" type="solid" [disabled]="httpService.user.user_id === 0"
                    (clicked)="createDrawdown()" [loading]="new_drawdown_loading"></cf-button>
            </div>
        </div>
        <div class="action" *ngIf="walletService.current_provider === '' || walletService.current_provider === 'email'">
            <cf-button label="Connect Wallet" type="solid" (clicked)="connectWallet()"></cf-button>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
</div>