<div class="app-my-earnings" [ngClass]="httpService.theme">
    <div class="app-my-earnings-container container">
        <div class="row stats 123" *ngIf="!loading_stats">
            <div>
                <cf-card [theme]="httpService.theme" *ngIf="stats?.all?.amount_usd >= 0"
                    [stat]="'$' + (stats?.all?.amount_usd | number:'0.0-0')" [subtitle]="
                        httpService.getUsdFormatting(
                            stats?.all?.count
                        ) + ' earnings'
                    " heading="All earnings" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" *ngIf="stats?.days_1?.amount_usd >= 0"
                    [stat]="'$' + (stats?.days_1?.amount_usd | number:'0.0-0')" [subtitle]="
                        httpService.getUsdFormatting(
                            stats?.days_1?.count
                        ) + ' earnings'
                    " heading="Last 24 hours" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" *ngIf="stats?.days_7?.amount_usd >= 0"
                    [stat]="'$' + (stats?.days_7?.amount_usd | number:'0.0-0')" [subtitle]="
                        httpService.getUsdFormatting(
                            stats?.days_7?.count
                        ) + ' earnings'
                    " heading="Last 7 days" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" *ngIf="stats?.days_30?.amount_usd >= 0"
                    [stat]="'$' + (stats?.days_30?.amount_usd | number:'0.0-0')" [subtitle]="
                        httpService.getUsdFormatting(
                            stats?.days_30?.count
                        ) + ' earnings'
                    " heading="Last 30 days" type="compact"></cf-card>
            </div>
        </div>

        <div *ngIf="!loading && httpService.user.user_id > 0" class="my-earnings">
            <div class="row mb-1">
                <div class="col-md-9"></div>
                <div class="col-md-3">
                    <cf-dropdown [theme]="httpService.theme" [options]="statusOptions" (change)="changeStatus($event)"
                        label="Select Status" [showLogo]="false" [selected]="selected_status" [showDescription]="true">
                    </cf-dropdown>
                </div>
            </div>
            <mat-expansion-panel (afterExpand)="afterExpand(item.lending_id)"
                [expanded]="opened_panel === item.lending_id" [ngClass]="getStatusClasses(item.lending_id, item.status)"
                *ngFor="let item of earnings; let index = index; first as isFirst">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span>
                            {{ item.created | date: 'mediumDate' }}
                        </span>
                        <span>
                            {{
                            item.amount
                            | number
                            : httpService.getPrecision(
                            item.currency
                            )
                            }}
                            {{ item.currency | uppercase }}
                            <span class="equivalent-amount">
                                ~
                                {{
                                item.amount_usd
                                | number: httpService.getPrecision('usd')
                                }}
                                USD
                            </span>
                        </span>
                        <span>
                            {{ item?.pool_info?.name }}
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table>
                    <tbody>
                        <tr>
                            <td class="txn-heading">Date</td>
                            <td>
                                {{ item.created | date: 'medium' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Amount</td>
                            <td>
                                {{ item.amount | number: httpService.getPrecision(item.currency) }}
                                {{ item.currency | uppercase }}
                                <span class="equivalent-amount">
                                    ~ {{ item.amount_usd | number: httpService.getPrecision('usd') }} USD
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Blockchain</td>
                            <td>
                                {{ item.blockchain | titlecase }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Chain</td>
                            <td>
                                {{ item.chain | titlecase }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Blockchain Txid</td>
                            <td>
                                <div class="redirection" *ngIf="item.blockchain_txid">
                                    {{ httpService.sliceAddress(item.blockchain_txid, 20) }}
                                    <i class="fa-solid fa-up-right-from-square ml cursor-hover"
                                        (click)="httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)"></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Wallet address</td>
                            <td>
                                <div class="redirection" *ngIf="item.wallet_address">
                                    {{ httpService.sliceAddress(item.wallet_address, 20) }}
                                    <i class="fa-solid fa-up-right-from-square ml cursor-hover" (click)="
                                            httpService.redirectionAddress(item.blockchain, item.chain, item.network, item.wallet_address)
                                        "></i>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="item?.pool_info?.name">
                            <td class="txn-heading">Pool</td>
                            <td>
                                {{ item.pool_info.name }}
                                <i class="fa-solid fa-up-right-from-square ml cursor-hover"
                                    (click)="redirectionPool(item.category_id,item.pool_id)"></i>
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Earning Id</td>
                            <td>
                                {{ item.interest_id }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Supply Id</td>
                            <td>
                                {{ item.lending_id }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">CRED points</td>
                            <td>
                                {{ item.cred_points }}
                            </td>
                        </tr>
                        <tr>
                            <td class="txn-heading">Status</td>
                            <td>
                                <i class="fa-regular fa-circle-check" *ngIf="item.status === 'completed'"></i>
                                <i class="fa-regular fa-circle-xmark" *ngIf="item.status === 'failed'"></i>
                                {{ item.status | titlecase }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <cf-paginator [theme]="httpService.theme" *ngIf="total_count > 0" [page]="page + 1" [count]="total_count"
                [limit]="limit" (changed)="pageChanged($event)"></cf-paginator>
        </div>
        <div class="no-data" *ngIf="total_count === 0 && !loading && httpService.user.user_id > 0">
            No records found
        </div>
        <div class="loader" *ngIf="loading">
            <cf-loader [theme]="httpService.theme"></cf-loader>
        </div>
    </div>
</div>