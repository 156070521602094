import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, Input, NgZone, OnChanges, OnInit,
    PLATFORM_ID,
    SimpleChanges
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { Transaction} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import {Router} from '@angular/router';
import IDrawdown from '../../interfaces/IDrawdown';
import { nullChecker } from '../../helpers/nullChecker';
import { MetamaskService } from '../metamask.service';
import { isPlatformBrowser } from '@angular/common';
import PrivateCreditPool from '../../models/PrivateCreditPool';
import IPrivateCreditPool from '../../interfaces/IPrivateCreditPool';

import {
    MatDialogRef, MatDialog 
} from '@angular/material/dialog';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';
import { ChangeBlockchainDialogComponent } from '../change-blockchain-dialog/change-blockchain-dialog.component';
import { ChangeChainDialogComponent } from '../change-chain-dialog/change-chain-dialog.component';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import getSolanaRawTransaction from '../../helpers/getSolanaRawTransaction';
import { SolflareService } from '../../services/solflare.service';

@Component({
    selector: 'app-new-repayment',
    templateUrl: './new-repayment.component.html',
    styleUrls: [ './new-repayment.component.scss' ]

})

export class NewRepaymentComponent implements OnInit, OnChanges {
    amount: number = 0;
    amount_usd: number = 0;
    conversion_rate: number = 0;
    currency: string = 'usdc';
    platformId: object = {};
    isBrowser: boolean = false;

    drawdownDropdownOptions: Array<IDropdown> = [];
    drawdowns: Array<IDrawdown> = [];

    repayment_id: string = '';

    loading: boolean = false;
    btn_loading: boolean = false;

    drawdown_info: any = {};
    show_no_drawdowns: boolean = false;

    @Input() pool_id: string = '';
    @Input() drawdown_id: string = '';
    pool: IPrivateCreditPool = new PrivateCreditPool();

    differentNetwork: boolean = false;
    differentBlockchain: boolean = false;
    showConfirmButton: boolean = false;
    gas_fees_balance: number = 0;
    balance: number = 0;

    constructor(
        private metamaskService: MetamaskService,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        public walletService: WalletService,
        private router: Router,
        public blockchainDialogRef: MatDialogRef<ChangeBlockchainDialogComponent>,
        public networkDialogRef: MatDialogRef<ChangeChainDialogComponent>,
        public dialog: MatDialog,
        private connectWalletDialogRef: MatDialogRef<ConnectWalletComponent>,
        @Inject(PLATFORM_ID) platformId: object,
        private ngZone: NgZone
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        if (this.httpService.user.user_id > 0) 
            this.getDrawdowns();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pool_id'] && changes['pool_id'].currentValue) {
            this.pool_id = changes['pool_id'].currentValue;

            this.getPoolDetails();
        }
    }
    
    getDrawdowns() {
        this.show_no_drawdowns = false;
        this.loading = true;

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            usage: 'create_repayment'
        };

        this.httpService.getDrawdowns(body).subscribe(res => {
            this.drawdowns = res.data;

            if (this.drawdowns.length === 0)
                this.show_no_drawdowns = true;

            else {
                this.drawdowns.forEach((element: any) => {
                    this.drawdownDropdownOptions.push({
                        label: `${element.amount.toFixed(2)} ${element.currency.toUpperCase()} (${new Date(element.created).toDateString()})`,
                        value: element.drawdown_id,
                        optionEnabled: true
                    });
                });

                if (nullChecker(this.drawdown_id)) {
                    this.drawdown_id = res.data[0].drawdown_id;
                    this.getGasFeeBalance(res.data[0].blockchain, res.data[0].chain);

                    this.drawdown_info = res.data.find((item: any) => {
                        return item.drawdown_id === this.drawdown_id;
                    });
                }

                else 
                    this.drawdown_info = res.data[0];
                
                this.calculateAmount();
            }

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    amountChanged(amount: number) {
        if (!amount) 
            amount = 0;
        
        this.amount = parseFloat(amount.toString());

        this.conversion_rate = this.httpService.currencyConversionMap[this.currency].usd;
        this.amount_usd = this.amount * this.conversion_rate;
    }

    changeDrawdown(event: any) {
        this.drawdown_id = event.value;
        this.drawdown_info = this.drawdowns.find((item: any) => {
            return item.drawdown_id === this.drawdown_id;
        });

        this.calculateAmount();
    }

    calculateAmount() {
        this.currency = this.drawdown_info.currency;

        let pending_interest = this.calculatePendingInterest(this.drawdown_info.last_interest_payment_time, this.drawdown_info.outstanding_amount, this.drawdown_info.interest_rate);

        console.log('pending_interest initial : ', pending_interest);

        if (pending_interest < Math.pow(10, -2))
            pending_interest = 0;

        console.log('pending_interest final : ', pending_interest);
    
        this.amountChanged(pending_interest);
    }

    currencyChanged(event: IDropdown) {
        this.currency = event.value;

        this.getCurrencyBalance(this.pool.blockchain, this.pool.chain, this.pool.lending_currency_mint_address);
    }

    calculatePendingInterest(interest_claimed_time: number, outstanding_principal_amount: number, interest_rate: number) {
        const current_time = new Date().getTime();
        const difference_in_minutes = (current_time - interest_claimed_time) / 60000;
        
        const interest_per_min = outstanding_principal_amount * interest_rate / 100 / 365 / 24 / 60;
        
        const interest = interest_per_min * difference_in_minutes;
    
        return interest;
    }

    createRepayment() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Please enter valid amount'
                }
            }));

            return;
        }

        const body = {
            drawdown_id: this.drawdown_id,
            wallet_address: this.walletService.wallet_address,
            amount: this.amount,
            currency: this.currency.toLowerCase()
        };

        this.btn_loading = true;

        this.httpService.createRepayment(body).subscribe((res: any) => {    
            this.btn_loading = false;
            this.repayment_id = res.data.repayment_id;

            if (res.data.transaction) {
                const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));
                
                this.sendSolanaTransaction(transaction);
            }

            else if (res.data.abi) {
                const abi = res.data.abi;
                const gas = res.data.gas;
                const contract_address = res.data.contract_address;

                console.log('lending', abi, contract_address, gas);
                
                this.sendEthereumTransaction(abi, contract_address, gas);
            }
        }, (err: HttpErrorResponse) => {
            console.error('Withdrawal error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.btn_loading = false;
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        else if (this.walletService.current_provider === 'solflare') {
            const response = await this.solflareService.signTransaction(transaction);

            return response;
        }

        return undefined;
    }

    updateSignature(signature: string, error_code: number = -1) {
        const body = {
            drawdown_id: this.drawdown_id,
            repayment_id: this.repayment_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updateRepayment(body).subscribe((res: any) => {
            this.btn_loading = false;

            this.cfAlertService.showMessage('Repayment succesful');

            this.router.navigateByUrl('/transactions?tab=my-repayments');
        }, (err: HttpErrorResponse) => {
            console.error('updateRepayment error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.btn_loading = false;
        });
    }

    async sendSolanaTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;
            
            transaction = res;

            const txnSignature = await getSolanaRawTransaction(transaction);

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature);
        }

        catch (err) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            this.cfAlertService.showError(new HttpErrorResponse({
                error: err
            }));

            this.btn_loading = false;
        }
    }

    login() {
        this.httpService.redirectUrl(this.constructor.name, '/login', {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    approveAllowance() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.balance < this.amount) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        if (this.gas_fees_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient gas fees', true);

            return;
        }

        this.loading = true;

        const body = {
            amount: this.amount,
            currency: this.currency,
            pool_id: this.pool_id,
            vault_id: ''
        };

        this.httpService.approveAllowance(body).subscribe((res: any) => {
            this.drawdown_id = res.data.drawdown_id;
            
            const abi = res.data.abi;
            const contract_address = res.data.contract_address;
            const gas = res.data.gas;

            console.log('allowance_abi', abi);
            console.log('allowance_contract_address', contract_address);
            console.log('allowance_gas', gas);
                
            this.sendEthereumAllowanceTransaction(abi, contract_address, gas);
        }, (err: HttpErrorResponse) => {
            console.error('approveAllowance error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.loading = false;
        });
    }

    async getEthereumSignature(abi: string, contract_address: string, gas: number) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(abi, contract_address, gas);

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    async sendEthereumAllowanceTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let allowance_signature = '';

        try {
            allowance_signature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('allowance_signature', allowance_signature);

            this.cfAlertService.showMessage('Allowance succesful');

            this.createRepayment();
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }
    }

    async sendEthereumTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('sendEthereumTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    getPoolDetails() {
        this.loading = true;

        this.httpService.getPoolDetails(this.pool_id).subscribe(res => {
            this.pool = res.data;
    
            if (!nullChecker(this.walletService.wallet_address) && this.walletService.isWalletConnected())
                this.getCurrencyBalance(this.pool.blockchain, this.pool.chain, this.pool.lending_currency_mint_address);

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            this.loading = false;

            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    openChangeBlockchainDialog() {
        console.log('openChangeBlockchainDialog', this.pool.blockchain, this.walletService.blockchain);

        this.differentBlockchain = true;
        
        this.blockchainDialogRef = this.dialog.open(ChangeBlockchainDialogComponent, {
            width: '500px',
            data: {
                expected_blockchain: this.pool.blockchain,
                current_blockchain: this.walletService.blockchain
            }
        });

        this.blockchainDialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log('result', result);
        });
    }

    async openChangeChainDialog() {
        console.log('openChangeChainDialog', this.walletService.blockchain, this.pool.chain);
        
        if (this.walletService.blockchain === 'solana') {
            if (this.pool.chain === 'solana')
                return true;
        }
        
        const chainId = await this.metamaskService.getChainId();
        const chainIdDecimal = parseInt(chainId, 16);

        const currentChainInfo = ETHEREUM_CHAINS[chainIdDecimal];

        if (this.pool.chain_id === currentChainInfo.chainId)
            return true;

        this.differentNetwork = true;

        this.networkDialogRef = this.dialog.open(ChangeChainDialogComponent, {
            width: '500px',
            data: {
                expected_chain: this.pool.chain_id,
                current_chain: currentChainInfo.chainId
            }
        });

        this.networkDialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log('result', result);
        });

        return false;
    }

    getCurrencyBalance(blockchain: string, chain: string, mint_address: string) {
        console.log('getCurrencyBalance', blockchain, chain, mint_address);
        console.log('isWalletConnected', this.walletService.isWalletConnected());
        console.log('differentBlockchain', this.differentBlockchain);
        console.log('differentNetwork', this.differentNetwork);
        console.log('isBrowser', this.isBrowser);
        console.log('wallet_address', this.walletService.wallet_address);

        if (nullChecker(this.walletService.wallet_address) || !this.walletService.isWalletConnected())
            return;

        if (!this.isBrowser || this.differentBlockchain || this.differentNetwork)
            return;

        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        if (nullChecker(this.walletService.wallet_address) || !this.walletService.isWalletConnected())
            return;
    
        if (this.differentBlockchain || this.differentNetwork)
            return;
    
        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address).subscribe((res: any) => {
            this.gas_fees_balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
    
            this.cfAlertService.showError(err);
        });
    }

    connectWallet() {
        this.connectWalletDialogRef = this.dialog.open(ConnectWalletComponent, {
            width: '550px',
            height: 'auto',
            data: {}
        });
    
        this.connectWalletDialogRef.afterClosed().subscribe((status: string) => {
            console.log('connectWallet afterClosed', status);
    
            this.ngZone.run(async () => {
                if (this.walletService.isWalletConnected()) {
                    console.log('wallet connected');
    
                    console.log('walletService.current_provider', this.walletService.current_provider);
    
                    if (this.pool.blockchain !== this.walletService.blockchain) 
                        this.openChangeBlockchainDialog();
            
                    else {
                        const networkMatched = await this.openChangeChainDialog();
    
                        console.log('networkMatched', networkMatched);
    
                        if (!networkMatched)
                            return;
                    }

                    if (this.walletService.wallet_address !== this.pool.borrower) {
                        console.log(this.walletService.wallet_address, this.pool.borrower);

                        this.cfAlertService.showMessage('Not authorized to borrow from this pool', true);

                        return;
                    }
            
                    if (this.pool.blockchain === 'solana') 
                        this.showConfirmButton = true;
            
                    else 
                        this.showConfirmButton = false;

                    console.log('currency', this.currency);
    
                    this.getCurrencyBalance(this.pool.blockchain, this.pool.chain, this.pool.lending_currency_mint_address);
                    this.getGasFeeBalance(this.pool.blockchain, this.pool.chain);
                }
                else
                    console.log('wallet not connected');
            });
        });
    }
}
