<div class="airdrop" [ngClass]="httpService.theme">
    <div class="w-100 mb-5 outer-box first">
        <div class="right d-flex flex-column gap-3">
            <div class="top p-3 font_xl bold gradient">
                Collect Moons for $CRED airdrops
            </div>
            <div class="bottom p-3">
                <div class="mb-4 one">
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div class="mb-2">Moons Earned</div>
                        <div
                            class="color font_xxl bold d-flex gap-2 align-items-baseline"
                        >
                            <span class="total-moons"
                                >{{ total_user_moon }}
                            </span>
                            <img
                                [width]="30"
                                src="https://assets.credible.finance/IMG312.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="font">
                        <div class="text-left">
                            Complete tasks to qualify for $CRED airdrops!
                        </div>
                    </div>
                </div>
                <div class="progress-container mb-3">
                    <div class="progress-bar" [style.width.%]="progress"></div>
                </div>
                <div class="d-flex mb-4 font gap-2">
                    <div class="task-completed font bold_xl">
                        {{ completed_task_count }}/{{ total_task_count }}
                    </div>
                    <div>Tasks Completed</div>
                </div>
                <div class="w-100">
                    <cf-button
                        theme="dark"
                        label="Learn about Airdrop"
                        (clicked)="
                            redirectUrl(
                                'https://crediblefinance.notion.site/airdrop'
                            )
                        "
                        type="text"
                    >
                    </cf-button>
                </div>
            </div>
        </div>
    </div>

    <div id="joinSocialMedia-google-recaptcha"></div>

    <div class="w-100 second mb-5">
        <div class="font_l bold mb-3">Tasks to get Moons</div>

        <div class="grid w-100" *ngIf="non_multiplier_tasks.length > 0">
            <div
                class="box w-100 p-3"
                *ngFor="let task of non_multiplier_tasks"
            >
                <div
                    class="d-flex justify-content-between align-items-center mb-3"
                >
                    <div class="font_lg bold d-flex gap-2">
                        <span>
                            {{ task.title }}
                        </span>
                        <span *ngIf="task.icon">
                            <img width="20" [src]="task.icon" alt="" />
                        </span>
                    </div>

                    <div *ngIf="task.id !== 'referral'">
                        <div
                            [ngClass]="{
                                pending: task.status === 'Pending',
                                completed: task.status === 'Completed',
                                coming_soon: task.coming_soon
                            }"
                        >
                            {{ task.status }}
                        </div>
                    </div>
                    <div *ngIf="task.id === 'referral'" class="referral-count">
                        <span>{{ referred_user_count }}</span>
                        <i class="fa-solid fa-user"></i>
                    </div>
                </div>
                <div
                    class="desc_color mb-3"
                    style="text-align: left; width: 75%"
                >
                    {{ task.desc }}
                </div>
                <div
                    class="d-flex"
                    *ngIf="
                        (task.id !== 'connect_wallet' &&
                            task.id !== 'discord' &&
                            task.status === 'Pending' &&
                            !task.coming_soon) ||
                        task.id === 'share_on_twitter' ||
                        task.id === 'lend_pusd'
                    "
                >
                    <cf-button
                        *ngIf="
                            task.id !== 'referral' ||
                            task.id === 'share_on_twitter' ||
                            task.id === 'lend_pusd'
                        "
                        [theme]="httpService.theme"
                        [label]="task.cta"
                        (clicked)="loginWith(task.id, task.redirect_url)"
                    >
                    </cf-button>
                </div>

                <div
                    class="d-flex"
                    *ngIf="task.id === 'discord' && task.status === 'Pending'"
                >
                    <cf-button
                        [loading]="
                            this.httpService.user.discord &&
                            this.httpService.user.discord
                                .verifying_server_joined
                                ? this.httpService.user.discord
                                      .verifying_server_joined
                                : false
                        "
                        [disabled]="
                            this.httpService.user.discord &&
                            this.httpService.user.discord.server_joined
                                ? this.httpService.user.discord.server_joined
                                : false
                        "
                        [theme]="httpService.theme"
                        [label]="task.cta"
                        (clicked)="loginWith(task.id, task.redirect_url)"
                    >
                    </cf-button>
                </div>
                <div
                    class="d-flex"
                    *ngIf="
                        task.id === 'connect_wallet' &&
                        !walletService.isWalletConnected() &&
                        task.status === 'Pending'
                    "
                >
                    <cf-button
                        [theme]="httpService.theme"
                        [label]="'Connect Wallet'"
                        (clicked)="connectWallet()"
                    >
                    </cf-button>
                </div>
                <div *ngIf="task.id === 'referral'">
                    <cf-input
                        [theme]="httpService.theme"
                        [label]="'Invite Link'"
                        [value]="invitation_link"
                        [copy]="true"
                        type="text"
                        [readonly]="true"
                    ></cf-input>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100 third pt-5">
        <div class="text-center mb-5">
            <div class="font_l bold mb-4">Frequently Asked Questions</div>
            <div class="desc_color text-center font">
                Do you have any questions? Here are some frequently asked
                questions that we have answered
            </div>
        </div>
        <div class="faq_questions pt-3" *ngIf="questions.length > 0">
            <div *ngFor="let item of questions; index as i">
                <div class="box">
                    <div
                        *ngIf="!item.open"
                        class="one mb-2 d-flex justify-content-between"
                        (click)="openQues(i)"
                    >
                        <span class="questions bold">
                            {{ item.q }}
                        </span>
                        <span>
                            <i
                                class="fa-solid fa-plus"
                                *ngIf="!item.open"
                                (click)="openQues(i)"
                            ></i>
                        </span>
                    </div>
                    <div
                        *ngIf="item.open"
                        class="one mb-2 d-flex justify-content-between"
                        (click)="closeQues(i)"
                    >
                        <span class="questions bold">
                            {{ item.q }}
                        </span>
                        <span>
                            <i
                                class="fa-solid fa-minus"
                                *ngIf="item.open"
                                (click)="closeQues(i)"
                            ></i>
                        </span>
                    </div>
                    <div *ngIf="item.open" class="two">
                        <ul *ngIf="item.hasBullets">
                            <li
                                class="answers font_lg desc_color"
                                *ngFor="let answer of item.a"
                            >
                                {{ answer }}
                            </li>
                        </ul>
                        <p
                            *ngIf="!item.hasBullets"
                            class="font_lg desc_color answers"
                        >
                            {{ item.a }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
