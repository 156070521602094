<div class="affiliate-policy-container">

    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="affiliate-content">

            <h1 class="heading">Affiliate Program Policy for Credible</h1>
            <br>
            <br>
            <h5>Last Updated: 15/01/2025
            </h5>
            <br>
            <div class="paragraph">
                <p>At Kiwimoney Inc. DBA <strong>Credible</strong>, we offer an Affiliate Program that allows
                    individuals,
                    influencers, and businesses (the "Affiliates") to earn commissions by referring new users to our
                    platform. This <strong>Affiliate Program Policy</strong> outlines the terms and conditions governing
                    the affiliate relationship between <strong>Credible</strong> and its affiliates.</p>
                <p>By participating in the Credible Affiliate Program, you agree to comply with this policy. If you do
                    not agree to these terms, you should not participate in the program.</p>

                <h2>1. Definitions</h2>
                <ul>
                    <li><strong>"Affiliate"</strong>: An individual or entity that participates in the Credible
                        Affiliate Program and promotes the platform to potential users.</li>
                    <li><strong>"Affiliate Link"</strong>: A unique referral link provided by Credible that tracks the
                        traffic or conversions generated by the Affiliate's promotional activities.</li>
                    <li><strong>"Referral"</strong>: A new user who registers on the Credible platform through an
                        Affiliate's unique referral link.</li>
                    <li><strong>"Commission"</strong>: The payment earned by the Affiliate based on the referral's
                        activities or transactions on the platform.</li>
                    <li><strong>"Credible Services"</strong>: The digital asset management services, custodial services,
                        and IBAN features offered by Credible.</li>
                </ul>

                <h2>2. Affiliate Program Enrollment</h2>
                <ul>
                    <li>To become an Affiliate, you must complete the registration process on the Credible platform by
                        providing accurate and complete information.</li>
                    <li>By registering as an Affiliate, you confirm that you are over the age of 18 and have the legal
                        capacity to enter into agreements and earn commissions.</li>
                    <li>Credible reserves the right to approve or reject any affiliate application at its sole
                        discretion.</li>
                </ul>

                <h2>3. Affiliate Responsibilities</h2>
                <p>As an Affiliate, you agree to:</p>
                <ul>
                    <li><strong>Promote Credible in a Responsible Manner</strong>: Affiliates should promote Credible's
                        services in a manner that aligns with the platform's values and complies with all applicable
                        laws and regulations. All marketing and promotional activities must be accurate, truthful, and
                        not misleading.</li>
                    <li><strong>Use Provided Marketing Materials</strong>: Affiliates may use the marketing materials
                        and Affiliate links provided by Credible. Any promotional content used must accurately represent
                        Credible's services and not include false claims or misrepresentations.</li>
                    <li><strong>Compliance with Regulations</strong>: Affiliates are responsible for ensuring their
                        promotional activities comply with any applicable advertising, data protection, and consumer
                        protection laws, as well as with Credible's rules and guidelines.</li>
                    <li><strong>Transparency</strong>: Affiliates must disclose to their audience that they are
                        participating in the Credible Affiliate Program and may earn a commission from referrals.</li>
                </ul>

                <h2>4. Affiliate Commissions</h2>
                <h3>4.1 Earning Commissions</h3>
                <p>Affiliates can earn commissions based on the actions of the users they refer. These commissions may
                    include:</p>
                <ul>
                    <li><strong>Signup Commission</strong>: A commission earned when a referred user signs up and
                        creates an account on Credible.</li>
                    <li><strong>Transaction Commission</strong>: A commission earned when a referred user completes a
                        qualifying transaction or purchase (e.g., transferring assets, using custodial services, etc.).
                    </li>
                    <li><strong>Recurring Commission</strong>: A recurring commission earned based on the continued use
                        of the platform by referred users (if applicable).</li>
                </ul>
                <p>The exact commission structure, rates, and thresholds will be provided to the Affiliate upon
                    registration and may vary depending on the program terms at any given time.</p>

                <h3>4.2 Commission Payment</h3>
                <ul>
                    <li><strong>Payment Method</strong>: Commissions are paid to Affiliates via the payment method
                        specified in the Affiliate's account settings (e.g., bank transfer, digital wallet, etc.).</li>
                    <li><strong>Payment Schedule</strong>: Commissions are paid on a monthly or bi-weekly basis, as
                        defined by the terms of the Affiliate Program. Payments will be processed within a reasonable
                        time after the close of the payment period.</li>
                    <li><strong>Minimum Payout</strong>: Affiliates must meet a minimum payout threshold (e.g., $50)
                        before being eligible to receive a commission payment. If the threshold is not met, the
                        commission will roll over to the next payment period.</li>
                </ul>

                <h3>4.3 Commission Adjustments</h3>
                <p>Credible reserves the right to adjust or cancel commissions in the event of the following:</p>
                <ul>
                    <li>Fraudulent or invalid signups.</li>
                    <li>Chargebacks, disputes, or reversals of transactions by referred users.</li>
                    <li>Non-compliance with the terms and conditions of the Affiliate Program.</li>
                </ul>

                <h2>5. Prohibited Practices</h2>
                <p>Affiliates are prohibited from engaging in any of the following practices:</p>
                <ul>
                    <li><strong>Spamming</strong>: Sending unsolicited or bulk emails, messages, or content to promote
                        Credible or the Affiliate link.</li>
                    <li><strong>False or Misleading Advertising</strong>: Making unsubstantiated claims or
                        misrepresenting Credible's services in marketing materials.</li>
                    <li><strong>Incentivized Referrals</strong>: Offering financial or other incentives (e.g., "paying"
                        users to sign up) to generate referrals, unless explicitly approved by Credible.</li>
                    <li><strong>Manipulation of Traffic</strong>: Using fraudulent or deceptive methods to generate
                        traffic or referrals, such as bot traffic or click farms.</li>
                    <li><strong>Competing Services</strong>: Affiliates cannot promote competing platforms or services
                        alongside Credible in a manner that creates a conflict of interest.</li>
                </ul>
                <p>Violation of these practices may result in the suspension or termination of the Affiliate's account
                    and forfeiture of earned commissions.</p>

                <h2>6. Affiliate Program Modifications</h2>
                <p>Credible reserves the right to:</p>
                <ul>
                    <li><strong>Modify the Affiliate Program</strong>: Credible may, at its sole discretion, change the
                        commission structure, eligibility requirements, and terms of the program. Affiliates will be
                        notified of significant changes, and continued participation in the program constitutes
                        acceptance of the new terms.</li>
                    <li><strong>Suspend or Terminate Affiliate Accounts</strong>: Credible has the right to suspend or
                        terminate an Affiliate's account for any reason, including but not limited to violations of this
                        policy, unethical behavior, or fraudulent activities.</li>
                </ul>

                <h2>7. Confidentiality and Data Protection</h2>
                <p>Affiliates are required to maintain the confidentiality of all proprietary or confidential
                    information shared by Credible. This includes, but is not limited to, data about commissions,
                    referral performance, or other sensitive information related to the program. Affiliates must comply
                    with all applicable data protection laws and ensure that any personal data collected through their
                    promotional activities is handled securely and in accordance with Credible's Privacy Policy.</p>

                <h2>8. Limitation of Liability</h2>
                <p>Credible will not be liable for any indirect, incidental, or consequential damages arising from the
                    Affiliate Program or the use of the platform by referred users. Affiliates participate in the
                    program at their own risk and acknowledge that the success of their referrals depends on various
                    factors, including market conditions, user behavior, and platform performance.</p>

                <h2>9. Term and Termination</h2>
                <ul>
                    <li><strong>Term</strong>: The Affiliate Program will continue until terminated by either party.
                    </li>
                    <li><strong>Termination by Affiliate</strong>: Affiliates may terminate their participation in the
                        program at any time by notifying Credible in writing.</li>
                    <li><strong>Termination by Credible</strong>: Credible may terminate or suspend an Affiliate's
                        participation in the program at any time for any reason, including non-compliance with this
                        policy or other program terms.</li>
                </ul>

                <h2>10. Governing Law and Dispute Resolution</h2>
                <p>This Affiliate Program Policy is governed by the laws of Wilmington, DE. Any disputes arising under
                    or in connection with this policy will be resolved through arbitration/mediation or in the courts
                    located in Wilmington, DE, as specified by Credible.</p>

                <h2>11. Contact Information</h2>
                <p>If you have any questions or require further information about the Credible Affiliate Program, please
                    contact us at:</p>
                <p><strong>Email:</strong> <a
                        href="mailto:support@credibleplatform.com">support&#64;credibleplatform.com</a></p>
                <p><strong>Address:</strong> [Credible's Physical Address]</p>
                <p><strong>Phone:</strong> [Credible's Phone Number]</p>

            </div>
        </div>
    </div>

</div>