/* eslint-disable array-bracket-spacing */
import {
    Component, NgZone, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { OnboardingService } from '../onboarding.service';
import { nullChecker } from '../../helpers/nullChecker';
import { getWindow } from 'ssr-window';
import { LocalStorageService } from '../../services/localstorage.service';

@Component({
    selector: 'app-register-steps',
    templateUrl: './register-steps.component.html',
    styleUrl: './register-steps.component.scss'
})

export class RegisterStepsComponent implements OnInit {
    steps = [{
        step: 'email-verification',
        title: 'Email Verification',
        completed: false
    }, {
        step: 'invite-code',
        title: 'Invitation',
        completed: false
    }];

    window = getWindow();
    register_link = `${this.window.location.origin}/register`;
    email: string = '';

    connectedWalletAddres: string = '';
    provider: string = '';

    currentStep = -1;
    currentCollapseStatus: boolean = false;
    step_skipping_allowed: boolean = false;
    returnUrl: string = '/airdrop';
    invite_code: string = '';
    invite_code_readonly: boolean = false;

    prefill_invite_code: {
        email: string,
        invite_code: string,
        invite_code_readonly: boolean
    } = {
            email: '',
            invite_code: '',
            invite_code_readonly: false
        };

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private route: ActivatedRoute,
        private onboardingService: OnboardingService,
        private _ngZone: NgZone,
        private router: Router,
        private localStorageService: LocalStorageService
    ) {
    }

    ngOnInit(): void {
        if (!nullChecker(this.httpService.user.email))
            this.steps[0].completed = true;

        if (this.httpService.user.referred_by > 0)
            this.steps[1].completed = true;

        this.subscribeQueryParams();

        this.checkNextStep();
    }

    subscribeQueryParams() {
        this.route.queryParams.subscribe(params => {
            if (params['returnUrl'] && params['returnUrl'] !== '') {
                this.returnUrl = params['returnUrl'];

                console.log('returnUrl => ', this.returnUrl);

                this.register_link = `${this.window.location.origin}/login?returnUrl=${this.returnUrl}`;
            }

            if (params['invite_code'])
                this.validateInviteCode(params['invite_code']);
        });
    }

    validateInviteCode(invite_code: string) {
        const indexOf = invite_code.indexOf('?');

        if (indexOf > -1)
            invite_code = invite_code.substring(0, indexOf);

        this.onboardingService.validateInviteCode(invite_code, 'register').subscribe((res: any) => {
            this.invite_code = invite_code;

            const title = `${res.data.display_name} is inviting you to join Credible with code ${invite_code}`;
            const description = `${res.data.display_name} is inviting you to join Credible with code ${invite_code}`;

            this.httpService.setMetaData(title, description);
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    checkNextStep() {
        this._ngZone.run(() => {
            let currentStep = -1;

            for (let i = 0; i < this.steps.length; i++) {
                if (!this.steps[i].completed) {
                    currentStep = i;
                    break;
                }
            }

            console.log('checkNextStep => ', currentStep);

            if (currentStep === -1) {
                console.log('All steps completed');

                this.httpService.getCurrentUser().then(() => {
                    this.httpService.redirectUrl(this.constructor.name, this.returnUrl, {
                        replaceUrl: true
                    });
                }, (err: HttpErrorResponse) => {
                    console.error('get current user error');
                    console.error(err);

                    this.cfAlertService.showError(err);
                });
            }

            else {
                console.log('currentStep => ', this.steps[currentStep]?.step);

                this.currentStep = currentStep;
            }
        });
    }

    changeStep(index: number) {
        if (this.step_skipping_allowed)
            this.currentStep = index;
    }

    previousStep() {
        if (this.currentStep !== 0)
            this.currentStep -= 1;
    }

    notifyInviteCode() {
        this.steps[1].completed = true;
        this.checkNextStep();
    }

    notifyEmailVerification($event: any) {
        console.log('register-steps : notifyEmailVerification => ', {
            invite_code: this.invite_code,
            ...$event
        });

        this.email = $event.email;
        this.steps[0].completed = true;

        if ($event.referred_by > 0)
            this.steps[1].completed = true;

        // this would happen when the invite code has been set with the user's account
        // in the email-verification step, so we can skip this step
        if (!nullChecker(this.invite_code))
            this.steps[1].completed = true;

        this.checkNextStep();
    }

    login() {
        this.localStorageService.removeItem('token');
        this.router.navigate(['/login']);
    }
}
