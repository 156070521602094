/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';

@Component({
    selector: 'app-loans-credit-terms-conditions',
    templateUrl: './loans-credit-terms-conditions.component.html',
    styleUrl: './loans-credit-terms-conditions.component.scss'
})
export class LoansCreditTermsConditionsComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}

