import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit, Input,
    OnChanges,
    SimpleChanges,
    Inject,
    NgZone,
    PLATFORM_ID
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

import { Transaction} from '@solana/web3.js';
import { Router } from '@angular/router';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import { MetamaskService } from '../metamask.service';
import PrivateCreditPool from '../../models/PrivateCreditPool';
import IPrivateCreditPool from '../../interfaces/IPrivateCreditPool';

import { nullChecker } from '../../helpers/nullChecker';
import { isPlatformBrowser } from '@angular/common';
import {
    MatDialog, MatDialogRef 
} from '@angular/material/dialog';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';
import { ChangeBlockchainDialogComponent } from '../change-blockchain-dialog/change-blockchain-dialog.component';
import { ChangeChainDialogComponent } from '../change-chain-dialog/change-chain-dialog.component';
import getSolanaRawTransaction from '../../helpers/getSolanaRawTransaction';
import { SolflareService } from '../../services/solflare.service';

@Component({
    selector: 'app-new-drawdown',
    templateUrl: './new-drawdown.component.html',
    styleUrl: './new-drawdown.component.scss'
})

export class NewDrawdownComponent implements OnInit, OnChanges {
    amount_usd: number = 0;
    currentMintAddressMap: any = {};
    currencyDropdownOptions: Array<IDropdown> = [];
    currency: string = 'usdc';
    isBrowser: boolean = false;

    amount: number = 0;
    fee: number = 0;
    received_amount: number = 0;
    borrower_fee_percentage: number = 0;
    min_borrow_amount: number = 0;

    conversion_rate: number = 0;

    loading: boolean = false;
    new_drawdown_loading: boolean = false;
    transaction: any;

    drawdown_id: string = '';

    pools: Array<IPrivateCreditPool> = [];
    pool: IPrivateCreditPool = new PrivateCreditPool();
    poolDropdownOptions: Array<IDropdown> = [];

    @Input() pool_id: string = '';

    differentNetwork: boolean = false;
    differentBlockchain: boolean = false;
    showConfirmButton: boolean = false;
    gas_fees_balance: number = 0;
    balance: number = 0;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        public walletService: WalletService,
        private metamaskService: MetamaskService,
        private router: Router,
        public blockchainDialogRef: MatDialogRef<ChangeBlockchainDialogComponent>,
        public networkDialogRef: MatDialogRef<ChangeChainDialogComponent>,
        public dialog: MatDialog,
        private connectWalletDialogRef: MatDialogRef<ConnectWalletComponent>,
        @Inject(PLATFORM_ID) platformId: object,
        private ngZone: NgZone
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        console.log('new-drawdown.component.ts ngOnInit()');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pool_id'] && changes['pool_id'].currentValue) {
            this.pool_id = changes['pool_id'].currentValue;

            this.getPoolDetails();

            console.log('pool_id', this.pool_id);
        }
    }

    getCurrencyDropdownOptions() {
        this.showLoader();

        this.httpService.getLendingCurrencies(this.pool.blockchain, this.pool.chain).subscribe(res => {
            this.currentMintAddressMap = res.data;

            const currencyDropdownItems: Array<IDropdown> = [];

            res.data.forEach((element: any) => {
                this.currentMintAddressMap[element.currency_key] = element.mint_address;

                currencyDropdownItems.push({
                    label: element.currency_name,
                    value: element.currency_key,
                    logo: element.logo,
                    optionEnabled: true
                });
            });

            this.currencyDropdownOptions = currencyDropdownItems;

            if (this.currencyDropdownOptions.length > 0)
                this.currency = this.currencyDropdownOptions[0].value;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    showLoader() {
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    currencyChanged(option: IDropdown) {
        this.currency = option.value;
        this.conversion_rate = this.httpService.currencyConversionMap[this.currency].usd;

        this.amount_usd = this.amount * this.conversion_rate;
    }

    amountChanged(amount: number) {
        if (!amount)
            amount = 0;

        this.amount = parseFloat(amount.toString());
        this.fee = amount * (this.borrower_fee_percentage / 100);
        this.received_amount = this.amount - this.fee;

        this.amount_usd = this.amount * this.conversion_rate;
    }

    createDrawdown() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.amount < this.min_borrow_amount) {
            this.cfAlertService.showMessage(`Minimum borrow amount is ${this.min_borrow_amount.toFixed(2)} ${this.currency.toUpperCase()}`, true);

            return;
        }

        this.new_drawdown_loading = true;

        const body = {
            amount: this.amount,
            currency: this.currency,
            pool_id: this.pool_id,
            wallet_address: this.walletService.wallet_address
        };
  
        this.httpService.createDrawdown(body).subscribe((res: any) => {
            this.drawdown_id = res.data.drawdown_id;

            if ('transaction' in res.data) {
                const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));

                this.sendSolanaTransaction(transaction);
            }
            else if (res.data.abi) {
                const abi = res.data.abi;
                const gas = res.data.gas;
                const contract_address = res.data.contract_address;

                console.log('withdrawal', abi, contract_address, gas);
                
                this.sendEthereumTransaction(abi, contract_address, gas);
            }
        }, (err: HttpErrorResponse) => {
            console.error('createDrawdown error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_drawdown_loading = false;
        });
    }

    getPoolDetails() {
        this.loading = true;

        this.httpService.getPoolDetails(this.pool_id).subscribe(res => {
            this.pool = res.data;

            this.borrower_fee_percentage = this.pool.borrower_fee_percentage;
            this.min_borrow_amount = this.pool.min_borrow_amount;
    
            this.fee = this.amount * (this.pool.borrower_fee_percentage / 100);

            this.getCurrencyDropdownOptions();
            this.loading = false;
        }, (err: HttpErrorResponse) => {
            this.loading = false;

            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        else if (this.walletService.current_provider === 'solflare') {
            const response = await this.solflareService.signTransaction(transaction);
        
            return response;
        }

        return undefined;
    }

    updateSignature(signature: string, error_code: number) {
        const body = {
            drawdown_id: this.drawdown_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updateDrawdown(body).subscribe((res: any) => {
            this.new_drawdown_loading = false;

            this.cfAlertService.showMessage(res.message);

            this.router.navigateByUrl( '/transactions?tab=my-drawdowns');
        }, (err: HttpErrorResponse) => {
            console.error('updateSignature error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_drawdown_loading = false;
        });
    }

    async sendSolanaTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res getSignature', res);

            if (!res || !res.signature)
                return;

            transaction = res;

            console.log('transaction', transaction);

            const txnSignature = await getSolanaRawTransaction(transaction);

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature, -1);
        }

        catch (err) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            this.cfAlertService.showError(new HttpErrorResponse({
                error: err
            }));

            this.new_drawdown_loading = false;
        }
    }

    async sendEthereumTransaction(abi: string, contract_address: string, gas: number) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('sendEthereumTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    async getEthereumSignature(abi: string, contract_address: string, gas: number) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(abi, contract_address, gas);

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    openChangeBlockchainDialog() {
        console.log('openChangeBlockchainDialog', this.pool.blockchain, this.walletService.blockchain);

        this.differentBlockchain = true;
        
        this.blockchainDialogRef = this.dialog.open(ChangeBlockchainDialogComponent, {
            width: '500px',
            data: {
                expected_blockchain: this.pool.blockchain,
                current_blockchain: this.walletService.blockchain
            }
        });

        this.blockchainDialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log('result', result);
        });
    }

    async openChangeChainDialog() {
        console.log('openChangeChainDialog', this.walletService.blockchain, this.pool.chain);
        
        if (this.walletService.blockchain === 'solana') {
            if (this.pool.chain === 'solana')
                return true;
        }
        
        const chainId = await this.metamaskService.getChainId();
        const chainIdDecimal = parseInt(chainId, 16);

        const currentChainInfo = ETHEREUM_CHAINS[chainIdDecimal];

        if (this.pool.chain_id === currentChainInfo.chainId)
            return true;

        this.differentNetwork = true;

        this.networkDialogRef = this.dialog.open(ChangeChainDialogComponent, {
            width: '500px',
            data: {
                expected_chain: this.pool.chain_id,
                current_chain: currentChainInfo.chainId
            }
        });

        this.networkDialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log('result', result);
        });

        return false;
    }

    getCurrencyBalance(blockchain: string, chain: string, mint_address: string) {
        console.log('getCurrencyBalance', blockchain, chain, mint_address);
        console.log('isWalletConnected', this.walletService.isWalletConnected());
        console.log('differentBlockchain', this.differentBlockchain);
        console.log('differentNetwork', this.differentNetwork);
        console.log('isBrowser', this.isBrowser);
        console.log('wallet_address', this.walletService.wallet_address);

        if (nullChecker(this.walletService.wallet_address) || !this.walletService.isWalletConnected())
            return;

        if (!this.isBrowser || this.differentBlockchain || this.differentNetwork)
            return;

        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        if (nullChecker(this.walletService.wallet_address) || !this.walletService.isWalletConnected())
            return;
    
        if (this.differentBlockchain || this.differentNetwork)
            return;
    
        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address).subscribe((res: any) => {
            this.gas_fees_balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
    
            this.cfAlertService.showError(err);
        });
    }

    connectWallet() {
        this.connectWalletDialogRef = this.dialog.open(ConnectWalletComponent, {
            width: '550px',
            height: 'auto',
            data: {}
        });
    
        this.connectWalletDialogRef.afterClosed().subscribe((status: string) => {
            console.log('connectWallet afterClosed', status);
    
            this.ngZone.run(async () => {
                if (this.walletService.isWalletConnected()) {
                    console.log('wallet connected');
    
                    console.log('walletService.current_provider', this.walletService.current_provider);
    
                    if (this.pool.blockchain !== this.walletService.blockchain) 
                        this.openChangeBlockchainDialog();
            
                    else {
                        const networkMatched = await this.openChangeChainDialog();
    
                        console.log('networkMatched', networkMatched);
    
                        if (!networkMatched)
                            return;
                    }

                    if (this.walletService.wallet_address !== this.pool.borrower) {
                        this.cfAlertService.showMessage('Not authorized to borrow from this pool', true);

                        return;
                    }
            
                    if (this.pool.blockchain === 'solana') 
                        this.showConfirmButton = true;
            
                    else 
                        this.showConfirmButton = false;
    
                    this.getCurrencyBalance(this.pool.blockchain, this.pool.chain, this.pool.lending_currency_mint_address);
                    this.getGasFeeBalance(this.pool.blockchain, this.pool.chain);
                }
                else
                    console.log('wallet not connected');
            });
        });
    }
}
