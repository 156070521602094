import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { getWindow } from 'ssr-window';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { GenesisService } from '../../genesis.service';

@Component({
    selector: 'app-staking-rewards-claim-history',
    templateUrl: './staking-rewards-claim-history.component.html',
    styleUrl: './staking-rewards-claim-history.component.scss'
})
export class StakingRewardsClaimHistoryComponent implements OnInit {
    loading: boolean = false;

    claimHistory: Array<any> = [];
    totalCount: number = 0;
    page: number = 0;
    limit: number = 15;
    level: number = 1;
    window: Window;

    statusOptions: Array<IDropdown> = [
        {
            label: 'Oracle 1',
            value: '1',
            optionEnabled: true
        },
        {
            label: 'Oracle 2',
            value: '2',
            optionEnabled: true
        },
        {
            label: 'Oracle 3',
            value: '3',
            optionEnabled: true
        },
        {
            label: 'Oracle 4',
            value: '4',
            optionEnabled: true
        },
        {
            label: 'Oracle 5',
            value: '5',
            optionEnabled: true
        },
        {
            label: 'Oracle 6',
            value: '6',
            optionEnabled: true
        },
        {
            label: 'Oracle 7',
            value: '7',
            optionEnabled: true
        },
        {
            label: 'Oracle 8',
            value: '8',
            optionEnabled: true
        },
        {
            label: 'Oracle 9',
            value: '9',
            optionEnabled: true
        }
    ];
    selected_status: string = '';

    constructor(
        public httpService: HttpService,
        public genesisService: GenesisService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        console.log('lend.component.ts ngOnInit()');

        this.getStakingRewardWithdrawals();
    }

    getStakingRewardWithdrawals() {
        this.loading = true;
        const data = {
            page: this.page,
            limit: this.limit
        };

        this.genesisService.getStakingRewardWithdrawals(data).subscribe(
            (res) => {
                this.claimHistory = res.data;
                this.totalCount = res.total_count;
                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    pageChanged(event: any) {
        this.page = event;
        this.getStakingRewardWithdrawals();
    }

    // changeStatus(event: any) {
    //     this.selected_status = event.value;
    //     this.level = event.value;
    //     this.page = 0;
    //     this.getMembers();
    // }
}
