<div class="app-new-staking-claim" [ngClass]="httpService.theme">
    <div *ngIf="
            !loading &&
            httpService.user.user_id > 0 &&
            !show_no_stakings
        ">

        <div class="row mt-2">
            <cf-input-currency [theme]="httpService.theme" [value]="amount" [readonly]="true"
                label="Available Rewards to Claim" [currency]="currency"
                [currencyLogo]="httpService.getCurrencyUrl(currency)" type="number" [precision]="6">
            </cf-input-currency>
        </div>

        <div class="actions">
            <div *ngIf="!btn_loading">
                <div *ngIf="differentBlockchain">
                    <cf-button label="Switch Blockchain" type="solid"
                        (clicked)="openChangeBlockchainDialog()"></cf-button>
                </div>

                <div class="action" *ngIf="!differentBlockchain && walletService.wallet_address !== ''">
                    <cf-button label="Confirm" type="solid" (clicked)="claimStakingReward()" *ngIf="showConfirmButton"
                        [loading]="btn_loading"></cf-button>

                    <cf-button label="Switch Network" type="solid" (clicked)="openChangeChainDialog()"
                        *ngIf="differentNetwork"></cf-button>

                </div>
                <div class="action" *ngIf="walletService.wallet_address === ''">
                    <cf-button label="Connect Wallet" type="solid" (clicked)="connectWallet()"></cf-button>
                </div>
            </div>
            <div *ngIf="btn_loading">
                <cf-loader [theme]="httpService.theme"></cf-loader>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader [theme]="httpService.theme"></cf-loader>
    </div>
    <div *ngIf="show_no_stakings" class="no-data">
        <div>No stakings</div>
    </div>
</div>