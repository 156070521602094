import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit 
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import IUser from '../../interfaces/IUser';
import User from '../../models/User';
import { HttpService } from '../../services/http.service';
import { ReferralRewardWithdrawalComponent } from '../referral-reward-withdrawal/referral-reward-withdrawal.component';
import {
    MatDialog, MatDialogRef 
} from '@angular/material/dialog';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-my-referral-rewards',
    templateUrl: './my-referral-rewards.component.html',
    styleUrls: [ './my-referral-rewards.component.scss' ]
})
export class MyReferralRewardsComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    loading_stats: boolean = false;
    currentTime: number = new Date().getTime();
    rewards: Array<any> = [];
    showModal = false;
    stats: any = {
        all: {
            amount_usd: 0,
            count: 0
        },
        days_1: {
            amount_usd: 0,
            count: 0
        },
        days_7: {
            amount_usd: 0,
            count: 0
        },
        days_30: {
            amount_usd: 0,
            count: 0
        }
    };

    total_count: number = 0;
    page: number = 0;
    limit: number = 10;
    opened_panel: string = '';

    constructor(
        public httpService: HttpService,
        private genesisService: GenesisService,
        private cfAlertService: CfAlertService,
        private router: Router,
        public dialogRef: MatDialogRef<ReferralRewardWithdrawalComponent>,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.subscribeObservables();

        this.httpService.setMetaData('My rewards', 'My rewards');
    }

    afterExpand(lending_id: string) {
        this.opened_panel = lending_id;
    }

    getReferralRewardStats(filter: string) {
        this.loading_stats = true;

        this.genesisService.getReferralRewardStats(filter).subscribe(
            (res) => {
                this.stats[filter] = res.data;

                this.loading_stats = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);

                this.loading_stats = false;
            }
        );
    }

    getReferralRewards() {
        this.loading = true;

        const body = {
            page: this.page,
            limit: this.limit
        };

        this.genesisService.getReferralRewards(body).subscribe(
            (res: any) => {
                this.rewards = res.data;
                this.total_count = res.total_count;

                if (this.rewards.length > 0)
                    this.opened_panel = this.rewards[0].reward_id;

                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    subscribeObservables() {
        if (this.httpService.user.user_id > 0) {
            this.httpService.getUserId().subscribe((user_id: number) => {
                this.user.user_id = user_id;

                this.getReferralRewards();
                this.getReferralRewardStats('all');
                this.getReferralRewardStats('days_1');
                this.getReferralRewardStats('days_7');
                this.getReferralRewardStats('days_30');
            });
        }
    }

    pageChanged(event: any) {
        this.page = event;
        this.getReferralRewards();
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    claimRewards() {
        this.dialogRef = this.dialog.open(ReferralRewardWithdrawalComponent, {
            // width: '50%',
            // height: '300px',
            data: {
                showModal: true
            },
            hasBackdrop: false,
            disableClose: false
        });

        // this.dialogRef.afterClosed().subscribe((result) => {
        //     if (result) {
        //     }
        // });
        // this.showModal = true;
    }
    closeModal() {
        this.showModal = false;
    }
}
