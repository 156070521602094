import {
    Component, OnInit
} from '@angular/core';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrl: './transactions.component.scss'
})
export class TransactionsComponent implements OnInit {
    currentOption = 'my-supply';

    options: Array<IDropdown> = [{
        label: 'Supply',
        value: 'my-supply',
        optionEnabled: true
    },
    {
        label: 'Earnings',
        value: 'my-earnings',
        optionEnabled: true
    },
    {
        label: 'Withdrawals',
        value: 'my-withdrawals',
        optionEnabled: true
    },
    {
        label: 'Drawdowns',
        value: 'my-drawdowns',
        optionEnabled: true
    },
    {
        label: 'Repayments',
        value: 'my-repayments',
        optionEnabled: true
    },
    // {
    //     label: 'Nodes',
    //     value: 'my-nodes',
    //     optionEnabled: true
    // }, 
    // {
    //     label: 'Unstakings',
    //     value: 'my-unstakings',
    //     optionEnabled: true
    // },
    // {
    //     label: 'Staking reward claiming',
    //     value: 'my-staking-reward-claiming',
    //     optionEnabled: true
    // },
    // {
    //     label: 'Referral reward claiming',
    //     value: 'my-referral-reward-claiming',
    //     optionEnabled: true
    // },
    {
        label: 'Cash Loan Drawdowns',
        value: 'my-cash-loan-drawdowns',
        optionEnabled: true
    }, {
        label: 'Cash Loan Repayments',
        value: 'my-cash-loan-repayments',
        optionEnabled: true
    }];
    // {
    //     label: 'Moon Claims',
    //     value: 'my-moon-claim-history',
    //     optionEnabled: true
    // }

    constructor(private route: ActivatedRoute, private router: Router, public httpService: HttpService) {

    }

    ngOnInit(): void {
        this.currentOption = this.options[0].value;

        this.subscribeQueryParamsObservables();
    }

    subscribeQueryParamsObservables() {
        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.options.find(t => t.value === tab))
                    this.currentOption = tab;
            }
        });
    }

    changeTab(option: IDropdown) {
        this.currentOption = option.value;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentOption
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}
