import {
    Component, OnInit 
} from '@angular/core';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { HttpService } from '../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import { TwitterService } from '../../services/twitter.service';
import { DiscordService } from '../discord.service';

@Component({
    selector: 'app-social-callback',
    templateUrl: './social-callback.component.html',
    styleUrl: './social-callback.component.scss'
})
export class SocialCallbackComponent implements OnInit{
    window: Window;
    loading: boolean = true;
    platform: string | null = null;

    constructor(private route: ActivatedRoute, private router: Router, public httpService: HttpService, 
        private cfAlertService: CfAlertService, private twitterService: TwitterService, private discordService: DiscordService) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.platform = this.route.snapshot.paramMap.get('platform');

        if (this.platform === 'twitter')
            this.subscribeTwitterParams();

        else if (this.platform === 'discord')
            this.subscribeDiscordParams();
    }

    subscribeTwitterParams() {
        this.route.queryParams.subscribe(params => {
            console.log(params);

            const oauth_token = params['oauth_token'];
            const oauth_verifier = params['oauth_verifier'];

            this.storeTwitterVerifier(oauth_token, oauth_verifier);
        });
    }

    subscribeDiscordParams() {
        this.route.queryParams.subscribe(params => {
            console.log(params);

            const code = params['code'];

            this.processDiscordCallback(code);
        });
    }

    storeTwitterVerifier(oauth_token: string, oauth_verifier: string) {
        this.twitterService.storeTwitterVerifier(oauth_token, oauth_verifier).subscribe(res => {
            this.loading = false;

            this.cfAlertService.showMessage('Twitter account connected successfully');

            this.router.navigate([ 'points' ], {
                queryParams: {
                    twitter_connected: true
                } 
            });
        }, (err: HttpErrorResponse) => {
            console.error('storeTwitterVerifier error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.loading = false;
            
            this.router.navigate([ 'points' ], {
                queryParams: {
                    twitter_connected: false
                } 
            });
        });
    }

    processDiscordCallback(code: string) {
        this.discordService.processDiscordCallback(code).subscribe(res => {
            this.loading = false;
            this.cfAlertService.showMessage('Discord account connected successfully');
            // this.router.navigate([ 'airdrop' ], {
            //     queryParams: {
            //         discord_connected: true
            //     }
            // });
            console.log(res);
            this.window.location.href = 'https://discord.com/invite/DjKy6PaJTb';
        }, (err: HttpErrorResponse) => {
            console.error('processDiscordCallback error');
            console.error(err);
            // console.error('message', err.response.message);
            console.error('message', err.error?.message || err.message);
            const message = err.error?.message || err.message;

            this.loading = false;

            if (message === 'This discord account is already connected with other user') {
                this.cfAlertService.showError(err);

                this.router.navigate([ 'airdrop' ], {
                    queryParams: {
                        discord_connected: false
                    }
                });
            }
            else this.window.location.href = 'https://discord.com/invite/DjKy6PaJTb';
        });
        // this.router.navigate([ 'airdrop' ], {
        //     queryParams: {
        //         discord_connected: false
        //     }
        // });
    }
}
