import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit, ViewChild 
} from '@angular/core';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';

import { getWindow } from 'ssr-window';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';
import Node from '../../models/Node';
import INode from '../../interfaces/INode';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import { DecimalPipe } from '@angular/common';
import ILineConfig from '../charts/line-chart/ILineConfig';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-plan-details',
    templateUrl: './plan-details.component.html',
    styleUrl: './plan-details.component.scss'
})
export class PlanDetailsComponent implements OnInit {
    plan_id: string = '';

    loading: boolean = false;

    node: INode = new Node();
    window: Window;
    
    chartLineConfig: Array<ILineConfig> = [];
    chartCredLineConfig: Array<ILineConfig> = [];

    tabs: Array<TabOption> = [{
        label: 'Vesting Schedule',
        value: 'vesting-schedule'
    }, {
        label: 'Bonus Schedule',
        value: 'bonus-schedule'
    }];
    currentTab = this.tabs[0].value;

    chartTabs: Array<TabOption> = [{
        label: 'Unlock Schedule',
        value: 'unlock-schedule'
    }, {
        label: 'Implied FDV',
        value: 'implied-fdv'
    }];
    currentChartTab = this.chartTabs[0].value;

    total_cred_points: number = 0;

    chart_loaded: boolean = false;
    stats: any;
    graphArray: any = [];
    chartTitle = '';
    chartData: any;
    chartCredData: any;
    isTooltipVisible = false;
    additionalYieldLabel: string = 'vCRED';
    nodes: Array<INode> = [];
    current_rate: number = 0;
    total_supply: number = 0;
    base_rate: number = 0;
    network_details: any = {};
    @ViewChild(LineChartComponent) child!: LineChartComponent;

    constructor(
        private route: ActivatedRoute,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private genesisService: GenesisService,
        private decimalPipe: DecimalPipe,
        private router: Router
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.getNewNodePredefinedData();

        window.scrollTo(0, 0);
        this.getNetworkDetails();
    }

    getPlanDetailsChart() {
        this.chart_loaded = false;

        const body = {
            plan_id: this.plan_id 
        };

        this.genesisService.getPlanDetailsChart(body).subscribe((res: any) => {
            this.chartData = res.data;
            this.chartLineConfig = [{
                name: '',
                data: res.data,
                lineColor: '#cf0'
            }];

            this.chart_loaded = true;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    getCredRateChart() {
        // this.chart_loaded = false;

        const body = {
            plan_id: this.plan_id 
        };

        this.genesisService.getCredRateChart(body).subscribe((res: any) => {
            this.total_supply = res.data.total_supply;

            this.chartCredData = res.data.data_points.map((item: { x: number, y: number}) => {
                return {
                    x: item.x,
                    y: this.total_supply * item.y
                };
            });
            
            this.current_rate = res.data.current_rate;
            this.base_rate = res.data.base_rate;

            this.chartCredLineConfig = [{
                name: '',
                data: this.chartCredData,
                lineColor: '#cf0'
            }];

            // this.chart_loaded = true;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    changeChartTab(event: any) {
        this.currentChartTab = event.value;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    chart_tab: this.currentChartTab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    getNewNodePredefinedData() {
        this.genesisService.getNewNodePredefinedData().subscribe((res) => {
            this.loading = false;

            this.nodes = res.data;
            this.node = this.nodes[0];

            this.httpService.setMetaData(
                `${this.decimalPipe.transform(this.node.cred_receivable, '0.0-0')} vCRED`,
                this.node.description
            );

            this.plan_id = this.node.plan_id;

            this.getPlanDetailsChart();
            this.getCredRateChart();
        },
        (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changePlanIdHandler(event: any) {
        console.log(this.constructor.name, 'changePlanIdHandler', event);

        this.plan_id = event;

        this.getPlanDetailsChart();
        this.getCredRateChart();
    }

    myNodes() {
        this.router.navigate([ '/nodes/my-nodes' ]);
    }

    myNetwork() {
        this.router.navigate([ '/nodes/members' ]);
    }

    getNetworkDetails() {
        this.genesisService.getNetworkDetails().subscribe((res) => {
            this.network_details = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }
}
