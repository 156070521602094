<div class="app-email-verification">
    <div class="input-field">
        <cf-input label="Email Address" type="text" [showLabel]="true" class="mobile" [value]="email"
            [readonly]="show_email_otp" (valueChanged)="onChangeEmail($event)" placeholder="Enter email address"
            [theme]="httpService.theme"></cf-input>
    </div>
    <div class="input-field">
        <cf-input label="Verification code" placeholder="Enter verification code" [type]="'text'" [value]="email_otp"
            *ngIf="show_email_otp" (valueChanged)="changeVerificationCode($event)"></cf-input>
    </div>

    <div class="terms">
        <cf-checkbox [checked]="termsAndConditions" (changed)="agreeTerms()" label=""
            [theme]="httpService.theme"></cf-checkbox>
        <span>
            I agree to the <a href="/terms-condition" target="_blank">terms and conditions</a>
        </span>
    </div>

    <div id="registerWithEmail-google-recaptcha"></div>
    <div id="registerWithGoogle-google-recaptcha"></div>

    <div class="actions">
        <cf-button *ngIf="!show_email_otp" (clicked)="registerWithEmail()" type="solid" label="Register"
             [loading]="register_loading"></cf-button>

        <div class="otp-content" *ngIf="show_email_otp">
            <cf-button (clicked)="verifyRegisterEmailOtp()" type="solid" label="Verify code"
                [loading]="verify_otp_loading"></cf-button>
            <div class="resend-text">
                <span (click)="resendVerificationCode()">
                    Resend code
                </span>
            </div>
        </div>
    </div>

    <div class="sign-with-google" id="google-button"></div>
</div>