<div class="app-members" [ngClass]="httpService.theme">
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div class="row mt-4" *ngIf="!loading">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="mb-3">
                <div class="font_lg bold d-flex justify-content-between w-100">
                    <div>
                        Invite Link
                    </div>
                    <div class="referral-count d-flex align-items-center gap-2">
                        <span>{{referred_user_count}}</span>
                        <i class="fa-solid fa-user fa-lg"></i>
                    </div>
                </div>
                <div>
                    <cf-input [label]="'Invite Link'" [theme]="httpService.theme" [value]="invitation_link"
                        [copy]="true" type="text" [readonly]="true"></cf-input>
                </div>
            </div>
        </cf-card>
        <div class="row mb-1 mt-2">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <cf-dropdown [options]="statusOptions" (change)="changeStatus($event)" label="Filter" [showLogo]="false"
                    [selected]="selected_status" [showDescription]="true">
                </cf-dropdown>
            </div>
        </div>
        <div class="table-container" class="mt-4">
            <cf-card type="empty" [theme]="httpService.theme">
                <div class="box">
                    <table>
                        <thead>
                            <tr>
                                <th>User name</th>
                                <!-- <th>Address</th> -->
                                <th>Node amount </th>
                                <th>Staking amount </th>
                                <th>KYC Status</th>
                                <th>Wallet Connected</th>
                                <th>Lend Status</th>
                                <th>Borrow Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="members.length > 0">
                            <tr *ngFor="let member of members">
                                <td> {{ member.display_name }} </td>
                                <!-- <td>{{ member.child_wallet_address }}</td> -->
                                <td>{{ member.total_node_purchases }} VCRED</td>
                                <td>{{ member.total_staking_amount | number:'0.0-6' }} VCRED </td>
                                <td>{{ member.kyc_status === 1 ? 'Yes' : 'No' }}</td>
                                <td>{{ member.wallet_address ? 'Yes' : 'No' }}</td>
                                <td>{{ member.lend ? 'Yes' : 'No' }}</td>
                                <td>{{ member.borrow ? 'Yes' : 'No' }}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="totalChildrenDataCount === 0 && httpService.user.user_id > 0">
                            <tr>
                                <td colspan="12" class="pd">
                                    No records found
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <cf-paginator *ngIf="totalChildrenDataCount > 0" [page]="page + 1" [count]="totalChildrenDataCount"
                    [limit]="limit" (changed)="pageChanged($event)"></cf-paginator>
            </cf-card>
        </div>
    </div>
</div>