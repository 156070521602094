<div class="risk-policy-container">

    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="risk-content">

            <h1 class="heading">Risk Disclosure Policy for Credible</h1>
            <br>
            <br>
            <h5>Last Updated: 15/01/2025
            </h5>
            <br>
            <div class="paragraph">
                <p>At Kiwimoney Inc. DBA <strong>Credible</strong>, we believe in transparency and want to ensure that
                    our
                    users are fully aware of the risks associated with the use of our platform and services. This
                    <strong>Risk Disclosure Policy</strong> outlines the potential risks of engaging in digital asset
                    transactions, using non-custodial services, and participating in other activities provided by
                    Credible. By using our platform, you acknowledge and agree to the risks mentioned in this policy.
                </p>
                <p>Please read this disclosure carefully before using our platform and engaging in any transactions.</p>

                <h2>1. General Risks of Digital Assets</h2>
                <p>Digital assets, including but not limited to cryptocurrencies, tokens, and other blockchain-based
                    assets, are inherently risky and may be subject to significant volatility. The risks associated with
                    digital assets include, but are not limited to:</p>
                <ul>
                    <li><strong>Price Volatility:</strong> The value of digital assets can fluctuate dramatically within
                        short periods of time, resulting in potential financial loss.</li>
                    <li><strong>Market Risk:</strong> The market for digital assets is still developing and can be
                        subject to changes in investor sentiment, regulation, and technology, which may cause
                        unpredictable market movements.</li>
                    <li><strong>Liquidity Risk:</strong> Some digital assets may be illiquid or difficult to sell,
                        especially in periods of market downturns. You may not be able to sell or convert assets at
                        desired prices or in a timely manner.</li>
                    <li><strong>Regulatory Risk:</strong> The regulatory environment for digital assets is rapidly
                        evolving. Changes in regulations may affect your ability to use the platform, trade assets, or
                        withdraw funds, and may introduce additional compliance requirements or restrictions.</li>
                </ul>

                <h2>2. Non-Custodial Platform Risks</h2>
                <p>Credible is a <strong>non-custodial platform</strong>, which means that we do not store or control
                    your funds. While this provides you with full control over your digital assets, it also comes with
                    specific risks:</p>
                <ul>
                    <li><strong>Self-Management Risk:</strong> As a non-custodial platform, you are solely responsible
                        for securing your private keys, wallet, and assets. If you lose access to your private keys or
                        wallet, you may lose access to your digital assets permanently.</li>
                    <li><strong>No Recourse for Loss of Assets:</strong> Since Credible does not hold or control your
                        assets, we cannot assist with recovering lost or stolen funds. We cannot reverse transactions,
                        and there is no central authority to turn to if you lose your assets due to human error,
                        technical issues, or external factors.</li>
                    <li><strong>Security Risk:</strong> The security of your assets is primarily dependent on your own
                        actions. If you do not use strong security measures (such as two-factor authentication and
                        secure storage of private keys), your assets may be at risk of theft, hacking, or unauthorized
                        access.</li>
                </ul>

                <h2>3. Custodial Services by Circle Risks</h2>
                <p>For specific features, <strong>Circle</strong> provides custodial services where your assets may be
                    held on your behalf. While Circle is a trusted partner, there are inherent risks involved with
                    custodial services:</p>
                <ul>
                    <li><strong>Custodian Risk:</strong> By using custodial services provided by Circle, you are placing
                        trust in Circle to manage your assets securely. Any issues with Circle's security, operations,
                        or financial health could impact your ability to access your assets.</li>
                    <li><strong>Regulatory and Legal Risks:</strong> Circle, as a custodian, is subject to financial
                        regulations. Changes in laws or regulatory requirements may affect the services they provide and
                        your access to your assets. Circle may be required to comply with legal requests or freezing of
                        assets, which may affect your ability to withdraw or use your funds.</li>
                </ul>

                <h2>4. Transaction Risks</h2>
                <p>Transactions on the Credible platform, including digital asset transfers, are irreversible once
                    initiated. Risks include:</p>
                <ul>
                    <li><strong>Irreversibility:</strong> Once a transaction is broadcast to the blockchain, it cannot
                        be undone. If you make an error (e.g., sending assets to the wrong address), your assets may be
                        lost permanently.</li>
                    <li><strong>Transaction Fees:</strong> Some transactions may involve network fees or platform fees,
                        which can be significant depending on network congestion and transaction size. Fees are
                        generally non-refundable.</li>
                    <li><strong>Network Risks:</strong> Transactions on the blockchain rely on the health and stability
                        of the underlying blockchain network. Issues such as network congestion, delays, or forks could
                        impact transaction speed or success.</li>
                </ul>

                <h2>5. IBAN Integration Risks</h2>
                <p>When using Credible's <strong>IBAN services</strong> for transferring funds between traditional bank
                    accounts and digital assets, the following risks apply:</p>
                <ul>
                    <li><strong>Banking and Payment System Risks:</strong> Transfers involving IBAN are subject to
                        traditional banking systems and intermediaries. Delays or issues within the banking system may
                        affect the processing of your transfers. Additionally, payment processors and third-party
                        partners may introduce additional risks or fees.</li>
                    <li><strong>Compliance Risks:</strong> When using IBAN for transactions, you may be required to
                        comply with anti-money laundering (AML) and know-your-customer (KYC) regulations imposed by
                        banks or payment providers. Non-compliance may result in delays, suspension of services, or
                        legal action.</li>
                </ul>

                <h2>6. Cybersecurity and Hacking Risks</h2>
                <p>Digital assets and financial platforms, including Credible, are common targets for hackers and
                    cybercriminals. Risks include:</p>
                <ul>
                    <li><strong>Security Breaches:</strong> While we take significant measures to secure the platform,
                        there is always a risk of hacking, phishing attacks, malware, or other cyber threats targeting
                        users and platforms. These attacks may result in financial loss, theft of assets, or
                        unauthorized access to personal data.</li>
                    <li><strong>External Risks:</strong> The security of third-party services (e.g., custodial services
                        by Circle, IBAN providers) also impacts your digital assets. If these services experience a data
                        breach, it could expose your information or assets to risk.</li>
                </ul>

                <h2>7. Technology and System Risks</h2>
                <p>As with any digital service, Credible is dependent on technology and software systems that may
                    encounter issues:</p>
                <ul>
                    <li><strong>Platform Downtime or Technical Issues:</strong> Although we strive to maintain a stable
                        platform, there may be times when the platform experiences downtime, maintenance, or technical
                        issues. These issues may delay or prevent access to your account or transactions.</li>
                    <li><strong>Software Bugs or Errors:</strong> We cannot guarantee that the platform is free from
                        bugs, errors, or vulnerabilities that could affect its functionality, performance, or security.
                        Users should be aware that software issues may result in unintended outcomes or errors in
                        transaction processing.</li>
                </ul>

                <h2>8. Legal and Regulatory Risks</h2>
                <p>The legal and regulatory environment surrounding digital assets is evolving, and various
                    jurisdictions may have different approaches to regulating the use of digital currencies. The risks
                    include:</p>
                <ul>
                    <li><strong>Compliance Risk:</strong> Credible may be subject to new regulations, including AML
                        (Anti-Money Laundering) and KYC (Know Your Customer) regulations. Changes in laws or regulatory
                        requirements may impact how you can use the platform or access your funds.</li>
                    <li><strong>Jurisdictional Risk:</strong> Digital asset regulations vary by country. Depending on
                        where you are located, you may face restrictions or limitations on using the platform, or your
                        transactions may be subject to specific taxes or reporting requirements.</li>
                </ul>

                <h2>9. Conclusion</h2>
                <p>By using the Credible platform, you acknowledge that you understand the risks involved in digital
                    asset transactions, platform use, and other related services. You agree to bear all the risks
                    associated with using Credible's services and understand that Credible is not responsible for any
                    financial loss, damages, or other consequences that arise from these risks.</p>
                <p>You should carefully assess whether using the platform aligns with your financial goals, risk
                    tolerance, and ability to manage potential losses.</p>

                <p>If you have any questions or concerns about the risks mentioned in this policy, please contact us at:
                </p>
                <p><strong>Email:</strong> <a
                        href="mailto:support@credibleplatform.com">support&#64;credibleplatform.com</a></p>
                <p><strong>Address:</strong> [Credible's Physical Address]</p>
                <p><strong>Phone:</strong> [Credible's Phone Number]</p>

            </div>
        </div>
    </div>

</div>