<div class="section-3" [ngClass]="theme === 'dark' ? 'dark': 'light'">
    <div class="container-wrap" *ngIf="faqs.length > 0">
        <div class="heading-container">
            <h1 class="title">Frequently Asked Questions</h1>
            <p class="sub-title">Do you have any questions? Here are some frequently asked questions that we
                have
                answered</p>
        </div>
        <div class="sub-container">
            <div class="faq-cards">
                <div class="faq-card" *ngFor="let faq of faqs; let i = index" (click)="openFaq(i)">
                    <div class="faq-question">
                        <span>{{ faq.question }}</span>
                        <i class="fa-solid fa-minus" *ngIf="i === openedFaqIndex"></i>
                        <i class="fa-solid fa-plus" *ngIf="i !== openedFaqIndex"></i>
                    </div>
                    <span class="faq-answer" *ngIf="i === openedFaqIndex">{{ faq.answer }}</span>
                </div>
            </div>
        </div>
    </div>
</div>