/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';

@Component({
    selector: 'app-risk-disclosure-policy',
    templateUrl: './risk-disclosure-policy.component.html',
    styleUrl: './risk-disclosure-policy.component.scss'
})
export class RiskDisclosurePolicyComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}

