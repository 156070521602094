<div class="cookies-policy-container">

    <div class="content">
        <div class="left-section">
            <div class="logo">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" />
            </div>
        </div>

        <div class="cookies-content">

            <h1 class="heading">Cookies Policy</h1>
            <br>
            <br>
            <h5>Last Updated: 20/01/2025
            </h5>
            <br>
            <div class="paragraph">
                <h2>1. Introduction</h2>
                <p>The Policy governs the use of cookies on the Credible Platform, providing detailed information on
                    their usage. By using the Credible Services, you agree to the terms outlined in this Policy. This
                    Cookies Policy (the "Policy") governs the relations between you ("Client" or "you") and any holding
                    company, subsidiary or entity belonging to the Credible group of companies ("Credible", "we" or
                    "us"), while you and Credible are hereinafter separately referred to as "Party" and jointly - as
                    "Parties", in regard to your use of the Credible Services and the use and application of cookies and
                    similar technologies while using the Credible Platform.</p>

                <h2>2. Use of Cookies</h2>
                <p>Credible and its partners use cookies to enhance user experience. By accepting all cookies via the
                    banner on the platform, you consent to this use.</p>

                <h2>3. What is a Cookie?</h2>
                <p>Cookies are small text files stored on your device by the website. They help personalize your
                    experience and improve website functionality based on your usage patterns. Cookies enable the
                    website and your browser to interact in such a way that the website is able to present you with the
                    most appropriate options and to customize services to your interests, based on your previous use of
                    the website. Some of the cookies will only be used if you use certain features of our services or
                    select certain preferences, and some cookies will always be used.</p>

                <h2>4. Types of Cookies</h2>
                <ul>
                    <li><strong>Strictly Necessary Cookies</strong>: Essential for the basic functioning of the platform
                        (e.g., logging in or setting privacy preferences).</li>
                    <li><strong>Functional Cookies</strong>: Enhance the platform's functionality and personalization.
                    </li>
                    <li><strong>Performance Cookies</strong>: Used to measure and improve platform performance.</li>
                    <li><strong>Targeting Cookies</strong>: Used by advertising partners to display relevant ads based
                        on your interests.</li>
                    <li><strong>Social Media Cookies</strong>: Enable sharing of content on social networks and tracking
                        across websites.</li>
                    <li><strong>Unknown Cookies</strong>: Collect data to improve the customer journey without
                        identifying you personally.</li>
                </ul>

                <h2>5. Cookie Control</h2>
                <ul>
                    <li><strong>First-party Cookies</strong>: Set by Credible itself.</li>
                    <li><strong>Third-party Cookies</strong>: Set by external partners, often for advertising purposes.
                    </li>
                    <li><strong>Session Cookies</strong>: Temporary cookies that expire after closing the browser.</li>
                    <li><strong>Persistent Cookies</strong>: Stay on your device for a set period or until deleted.</li>
                </ul>

                <h2>6. Why Do We Use Cookies?</h2>
                <p>We use cookies for several important purposes, including:</p>
                <ol>
                    <li><strong>User Recognition</strong>: To recognize you when you sign in, providing personalized
                        recommendations, content, and customized features and services.</li>
                    <li><strong>Tracking Preferences</strong>: To remember your preferences, such as language settings
                        and configurations.</li>
                    <li><strong>Platform Improvement</strong>: To develop, maintain, and enhance the Credible Platform
                        and Services, ensuring a better experience.</li>
                    <li><strong>Analytics</strong>: To analyze the use and performance of the Credible Services and any
                        advertisements displayed through them.</li>
                    <li><strong>Fraud Prevention</strong>: To detect and prevent fraudulent activities and other
                        unlawful actions.</li>
                    <li><strong>Aggregate Data Creation</strong>: To collect data on user categories (including
                        advertising audiences) for better targeting, marketing, and personalization of ads and new
                        features.</li>
                    <li><strong>Reporting and Promotion</strong>: To measure the performance of the Credible Services,
                        report promotions, and introduce new features.</li>
                </ol>

                <h2>7. How Can You Manage Cookies?</h2>
                <ol>
                    <li>
                        <p><strong>Cookie Control Options</strong>: You can manage cookie preferences when you first
                            visit the Credible Platform by using the options on the Cookie Banner. You can also modify
                            your preferences anytime by clicking the "Manage Cookies" button, or adjust your browser
                            settings.</p>
                        <p>If you choose not to activate certain cookie categories or later disable cookies, you can
                            still use the platform, but some features may be limited.</p>
                    </li>
                    <li>
                        <p><strong>Browser-Based Control</strong>: Most web browsers allow you to control cookies
                            through their settings. You can:</p>
                        <ul>
                            <li>Block or receive notifications about new cookies.</li>
                            <li>Manually adjust preferences each time you visit.</li>
                            <li>Prevent all cookies from being transferred, though this may affect platform
                                functionality.</li>
                        </ul>
                    </li>
                    <li>
                        <p>For help, visit these links for browser-specific cookie management:</p>
                        <ul>
                            <li><a
                                    href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Firefox
                                    (Private Browsing)</a></li>
                            <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Chrome (Incognito)</a>
                            </li>
                            <li><a
                                    href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet
                                    Explorer (InPrivate)</a></li>
                            <li><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">Safari (Mac)</a>
                            </li>
                        </ul>
                    </li>
                </ol>

                <h2>8. Miscellaneous</h2>
                <ol>
                    <li>
                        <p><strong>General Information</strong>: This Policy is provided for informational purposes
                            only. While we strive for accuracy, we do not take responsibility for any inaccuracies or
                            errors.</p>
                    </li>
                    <li>
                        <p><strong>Policy Amendments</strong>: Credible reserves the right to update or amend this
                            Policy, including the cookie list, at any time. Changes will take effect immediately upon
                            posting on the Credible Platform. By continuing to use the platform, you agree to be bound
                            by the updated Policy.</p>
                    </li>
                    <li>
                        <p><strong>Headings</strong>: The section headings in this Policy are for convenience and do not
                            affect its interpretation.</p>
                    </li>
                    <li>
                        <p><strong>Waiver</strong>: The failure to enforce any part of this Policy does not waive our
                            right to enforce it in the future.</p>
                    </li>
                    <li>
                        <p><strong>Transfer of Rights</strong>: You may not transfer your rights or obligations under
                            this Policy without Credible's written consent. We may assign or transfer our rights and
                            obligations at any time without notice or consent.</p>
                    </li>
                    <li>
                        <p><strong>Language</strong>: This Policy is written in English. If translated into another
                            language, the English version shall prevail in case of discrepancies.</p>
                    </li>
                    <li>
                        <p><strong>Contact</strong>: For any questions or concerns regarding this Policy or our privacy
                            practices, please contact us at <a
                                href="mailto:support@Credible.com">support&#64;Credible.com</a>.</p>
                    </li>
                </ol>
            </div>
        </div>
    </div>

</div>