<div class="app-members" [ngClass]="httpService.theme">
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div class="row" *ngIf="!loading">
        <!-- <div class="row mb-1">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <cf-dropdown [options]="statusOptions" (change)="changeStatus($event)" label="Filter" [showLogo]="false"
                    [selected]="selected_status" [showDescription]="true">
                </cf-dropdown>
            </div>
        </div> -->
        <cf-card type="empty" [theme]="httpService.theme" class="mt-4">
            <div class="box">
                <table>
                    <thead>
                        <tr>
                            <th>AMOUNT</th>
                            <th>TX LINK</th>
                            <th>DATE</th>
                            <th>STATUS</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="claimHistory.length > 0">
                        <tr *ngFor="let item of claimHistory">
                            <td> {{ item.amount | number:'0.0-6' }} {{ item.currency | uppercase }} </td>
                            <!-- <td>{{ item.reward_withdrawal_id }}</td> -->
                            <td>
                                <div class="redirection">
                                    <span class="ellipses">{{ item.blockchain_txid ? item.blockchain_txid : '-' }}
                                    </span>
                                    <!-- <i *ngIf="item.blockchain_txid" class="fa-solid fa-up-right-from-square ml icon"
                                    (click)="httpService.redirectionTx(item.blockchain_txid)"></i> -->
                                </div>
                            </td>
                            <td>{{ item.created | date : "mediumDate" }}</td>
                            <td>{{ item.status | titlecase}} </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="totalCount === 0 && httpService.user.user_id > 0">
                        <tr>
                            <td colspan="12" class="pd">
                                No records found
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <cf-paginator *ngIf="totalCount > 0" [page]="page + 1" [count]="totalCount" [limit]="limit"
                (changed)="pageChanged($event)"></cf-paginator>
        </cf-card>
    </div>
</div>